@charset "UTF-8";
/* Scss Document */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.6.3/webfonts/fa-brands-400.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.6.3/webfonts/fa-regular-400.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.6.3/webfonts/fa-solid-900.woff2") format("woff2");
  font-display: swap;
}
/*--------------------
共通系
--------------------*/
.alert_tx {
  color: #ff2c2c;
}

.center_tx {
  text-align: center;
}

.base_style a {
  color: #12c4c4;
}
.base_style a.black-color2 {
  color: #000;
}
.base_style a.black-color2:hover {
  text-decoration: none;
}
.base_style .red {
  color: #ff6c6c;
}
.base_style input {
  background: #f1f3f8;
  border: none !important;
}
.base_style footer {
  margin-top: 100px;
}
.base_style .btns_wrap .button-custom + .button-custom {
  margin-top: 1em;
}
.base_style a.button-custom,
.base_style a.button-custom:hover {
  text-decoration: none;
  color: #fff !important;
}

.base_table {
  display: table;
  width: 100%;
}
.base_table th, .base_table td {
  padding: 1rem;
  border: 1px solid #ddd;
  vertical-align: middle;
  text-align: left;
}
.base_table th {
  background: #727488;
  color: #fff;
  font-weight: normal;
}

p.indent {
  padding-left: 1em;
}

.distance-line-to-content.distance-middle {
  font-size: 12.07729vw;
}

@media (min-width: 768px) {
  .distance-line-to-content.distance-middle {
    margin-top: 4.16667vw;
  }
}
@media (min-width: 1200px) {
  .distance-line-to-content.distance-middle {
    margin-top: 5rem;
  }
}
/*---- スマホ ----*/
.pcb {
  display: none;
}

.spb {
  display: block;
}

.base_style {
  color: #111947;
}
.base_style h2, .base_style h3, .base_style h4, .base_style h5, .base_style table, .base_style p, .base_style .input input {
  line-height: 1.75;
}
.base_style h2 {
  font-size: 4.83092vw;
  margin-bottom: 2.41546vw;
}
.base_style h3 {
  font-size: 4.34783vw;
}
.base_style h4, .base_style h5, .base_style p, .base_style table {
  font-size: 3.86473vw;
}
.base_style h3, .base_style h4, .base_style h5 {
  margin-bottom: 2.41546vw;
}
.base_style .input input {
  font-size: 3.86473vw;
  color: #111947;
  font-weight: 400;
  height: 9.66184vw;
}
.base_style .label {
  padding: 0.4em 0.5em;
  border-radius: 0.4em;
  background-color: #12c4c4;
  color: #fff;
  font-size: 2.89855vw;
  display: inline-block;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  line-height: 1.4;
}
.base_style .label.mobile {
  background-color: #ff6c6c;
}
.base_style .toc {
  margin-top: 4.83092vw;
  margin-bottom: 4.83092vw;
  font-size: 3.38164vw;
  font-weight: 600;
  border: 1px solid #ccc;
}
.base_style .toc li {
  border-bottom: 1px dashed #ABB2BC;
}
.base_style .toc li:last-child {
  border-bottom: 0;
}
.base_style .toc li a {
  color: #111947;
  display: block;
  padding: 0.8em;
  background-color: #fff;
  line-height: 1.5;
}
.base_style .toc li a::before {
  color: #12c4c4;
  content: "●";
  margin-right: 0.3em;
}
.base_style .toc li a:hover {
  transition: 0.3s;
  filter: brightness(95%);
  text-decoration: none;
}
.base_style .toc li li {
  font-weight: normal;
  font-size: 3.1401vw;
}
.base_style .toc li li a {
  padding-left: 1.5em;
}
.base_style .toc li li a::before {
  content: "・";
  margin-right: 0.3em;
}
.base_style .toc li li:first-child {
  border-top: 1px dashed #ABB2BC;
}
.base_style .toc li li li a {
  padding-left: 2em;
}
.base_style ul.p {
  margin-top: 1em;
  margin-bottom: 1em;
}
.base_style ul.p li {
  font-size: 3.86473vw;
  line-height: 1.75;
  margin-bottom: 1em;
}

/*---- タブレット 縮小 ----*/
@media (min-width: 768px) {
  .pcb {
    display: block;
  }

  .spb {
    display: none;
  }

  .base_style h2 {
    font-size: 1.66667vw;
    margin-bottom: 0.41667vw;
  }
  .base_style h3 {
    font-size: 1.5vw;
  }
  .base_style h3, .base_style h4, .base_style h5, .base_style h6 {
    margin-bottom: 0.41667vw;
  }
  .base_style h4, .base_style h5, .base_style h6, .base_style p, .base_style table {
    font-size: 1.33333vw;
  }
  .base_style .input input {
    font-size: 1.33333vw;
    height: 3.33333vw;
  }
  .base_style .label {
    font-size: 1vw;
  }
  .base_style .toc {
    margin-top: 1.66667vw;
    margin-bottom: 1.66667vw;
    font-size: 1.33333vw;
  }
  .base_style .toc li li {
    font-size: 1.16667vw;
  }
  .base_style ul.p li {
    font-size: 1.33333vw;
  }
}
/*---- PC最大 ----*/
@media (min-width: 1200px) {
  .base_style h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  .base_style h3 {
    font-size: 1.8rem;
  }
  .base_style h3, .base_style h4, .base_style h5, .base_style h6 {
    margin-bottom: 0.5rem;
  }
  .base_style h4, .base_style h5, .base_style h6, .base_style p, .base_style table {
    font-size: 1.6rem;
  }
  .base_style .input input {
    font-size: 1.6rem;
    height: 4rem;
  }
  .base_style .label {
    font-size: 1.2rem;
  }
  .base_style .toc {
    margin: 2rem auto;
    font-size: 1.6rem;
  }
  .base_style .toc li li {
    font-size: 1.4rem;
  }
  .base_style ul.p li {
    font-size: 1.6rem;
  }
}
/*--------------------
// フッター
--------------------*/
@media (min-width: 768px) {
  .footerC-wrap__content--link {
    max-width: 71rem;
  }
}
/*--------------------
// ログイン後ヘッダー
--------------------*/
.header-wrap .account .avatar-top:hover {
  cursor: pointer;
}

.header-wrap .account .avatar-top svg {
  width: 7.72947vw;
  height: 7.72947vw;
  margin-left: 4.83092vw;
  object-fit: cover;
  fill: #fff;
  position: relative;
  top: 2px;
}

@media (min-width: 768px) {
  .header-wrap .account .avatar-top svg {
    width: 2.28571vw;
    height: 2.28571vw;
    margin-left: 1.82857vw;
  }
}
@media (min-width: 1400px) {
  .header-wrap .account .avatar-top svg {
    width: 3.2rem;
    height: 3.2rem;
    margin-left: 2rem;
  }
}
/*--------------------
// エクスポート画面
--------------------*/
.wrapper.control-export .export-wrap_box a:hover {
  cursor: pointer;
}
.wrapper.control-export .export-wrap_box .title_head {
  padding-bottom: 4.83092vw;
  margin-bottom: 7.24638vw;
  border-bottom: 1px dashed #ccc;
}
.wrapper.control-export .export-wrap_box .title_head h2 {
  font-size: 5.31401vw;
  margin-bottom: 0vw;
  padding-left: 4.83092vw;
}
.wrapper.control-export .export-wrap_box .title_head .service_name {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}
.wrapper.control-export .export-wrap_box .title_head .service_name .image_wrap {
  width: 100px;
  height: 100px;
}
.wrapper.control-export .export-wrap_box .title_head .service_name .image_wrap img {
  width: 100%;
  height: auto;
  border: 1px solid #eee;
}
.wrapper.control-export .export-wrap_box .title_head .input_wrap {
  margin-top: 1em;
}
.wrapper.control-export .export-wrap_box .title_head .input_wrap .token__form {
  display: block;
}
.wrapper.control-export .export-wrap_box .title_head .input_wrap .token__form .input {
  display: block;
}
.wrapper.control-export .export-wrap_box .title_head .input_wrap .token__form .input input {
  width: 100%;
  height: 12.07729vw;
}
.wrapper.control-export .export-wrap_box .title_head .btn_wrap {
  width: 60.38647vw;
  margin: 0 auto;
  margin-top: 1em;
}
.wrapper.control-export .export-wrap_box .section {
  padding: 5rem 0;
  border-bottom: 1px dashed #ccc;
  margin: 0;
  margin-bottom: 0 !important;
}
.wrapper.control-export .export-wrap_box .section .section {
  margin: 3rem 0;
  border-bottom: 0;
  padding: 0;
}
.wrapper.control-export .export-wrap_box .section .section .section {
  margin: 3rem 0;
}
.wrapper.control-export .export-wrap_box #export_able .base_table {
  font-size: 1.4rem;
  line-height: 1.4;
}
.wrapper.control-export .export-wrap_box #export_howto {
  border-bottom: 0;
}
.wrapper.control-export .export-wrap_box #export_input {
  margin-bottom: 0;
}
.wrapper.control-export .export-wrap_box h3::before {
  content: "";
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  background: url(./assets/images/image/ico_symbol.svg) no-repeat;
  background-size: contain;
  margin-right: 0.5em;
}
.wrapper.control-export .export-wrap_box #export_detail .base_table {
  line-height: 1.4;
  font-size: 3.38164vw;
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 {
  margin-top: 1em;
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 1.5em;
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li:last-child {
  margin-bottom: 0;
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .image_wrap {
  background-color: #f1f3f8;
  border-radius: 10px;
  -webkit-flex-basis: 80px;
  flex-basis: 80px;
  height: 140px;
  position: relative;
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .image_wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap {
  padding-left: 1em;
  -webkit-flex-basis: calc(100% - 80px);
  flex-basis: calc(100% - 80px);
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap .title {
  margin-bottom: 0.5em;
  display: block;
  font-size: 3.86473vw;
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap .label_wrap {
  margin-bottom: 0.5em;
  display: block;
  vertical-align: middle;
  margin-left: 0.2em;
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap .label_wrap .label + .label {
  margin-left: 0.3em;
}
.wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap p {
  font-size: 3.38164vw;
}
.wrapper.control-export .export-wrap_box #export_howto .service_title {
  margin-top: 4em;
  padding: 0.5em 0;
  border-bottom: 1px dashed #12c4c4;
  border-top: 1px dashed #12c4c4;
}
.wrapper.control-export .export-wrap_box #export_howto .service_title::before {
  content: "●";
  color: #12c4c4;
  margin-right: 0.2em;
}
.wrapper.control-export .export-wrap_box #export_howto .image_wrap {
  text-align: center;
  margin: 3em auto;
}
.wrapper.control-export .export-wrap_box #export_howto .image_wrap img {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 0 auto;
  border: 1px solid #ccc;
}

@media (min-width: 768px) {
  .wrapper.control-export .export-wrap_box .title_head {
    padding-bottom: 1.66667vw;
    margin-bottom: 2.5vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .wrapper.control-export .export-wrap_box .title_head h2 {
    font-size: 1.83333vw;
    margin-bottom: 0vw;
    padding-left: 1.66667vw;
    padding-right: 1.66667vw;
  }
  .wrapper.control-export .export-wrap_box .title_head .service_name {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .wrapper.control-export .export-wrap_box .title_head .service_name .image_wrap {
    width: 100px;
    height: 100px;
  }
  .wrapper.control-export .export-wrap_box .title_head .service_name .image_wrap img {
    width: 100%;
    height: auto;
  }
  .wrapper.control-export .export-wrap_box .title_head .input_wrap {
    margin-top: 0;
  }
  .wrapper.control-export .export-wrap_box .title_head .input_wrap .token__form {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .wrapper.control-export .export-wrap_box .title_head .input_wrap .token__form .input input {
    width: 50vw;
    height: 4.16667vw;
  }
  .wrapper.control-export .export-wrap_box .title_head .btn_wrap {
    margin-left: 1.66667vw;
    width: 20.83333vw;
    margin-top: 0;
  }
  .wrapper.control-export .export-wrap_box #export_detail .base_table {
    font-size: 1.16667vw;
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap .image_wrap {
    height: 10vw;
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap .title {
    display: inline-block;
    font-size: 1.33333vw;
    margin-bottom: 0em;
    margin-right: 0.3em;
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap .label_wrap {
    display: inline-block;
    margin-bottom: 0em;
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap p {
    margin-top: 1em;
    font-size: 1.33333vw;
  }
}
@media (min-width: 1200px) {
  .wrapper.control-export .export-wrap_box .title_head {
    padding-bottom: 2.5em;
    margin-bottom: 3rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .wrapper.control-export .export-wrap_box .title_head h2 {
    font-size: 2.2rem;
    margin-bottom: 0;
    padding: 0 2rem;
  }
  .wrapper.control-export .export-wrap_box .title_head .service_name {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: calc(100% - 60rem - 240px );
  }
  .wrapper.control-export .export-wrap_box .title_head .service_name .image_wrap {
    width: 100px;
    height: 100px;
  }
  .wrapper.control-export .export-wrap_box .title_head .service_name .image_wrap img {
    width: 100%;
    height: auto;
  }
  .wrapper.control-export .export-wrap_box .title_head .input_wrap {
    margin-top: 0;
  }
  .wrapper.control-export .export-wrap_box .title_head .input_wrap .token__form {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .wrapper.control-export .export-wrap_box .title_head .input_wrap .token__form .input input {
    width: 60rem;
    height: 5rem;
  }
  .wrapper.control-export .export-wrap_box .title_head .btn_wrap {
    margin-left: 2rem;
    margin-top: 0;
    width: 240px;
  }
  .wrapper.control-export .export-wrap_box #export_detail .base_table {
    font-size: 1.4rem;
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .image_wrap {
    -webkit-flex-basis: 100px;
    flex-basis: 100px;
    height: 120px;
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap {
    -webkit-flex-basis: calc(100% - 100px);
    flex-basis: calc(100% - 100px);
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap .title {
    display: inline-block;
    font-size: 1.6rem;
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap .label_wrap {
    display: inline-block;
  }
  .wrapper.control-export .export-wrap_box .avatar_wrap2 ul li .tx_wrap p {
    font-size: 1.6rem;
  }
}
.base_style.column2_wrap .column1,
.base_style.column2_wrap .column2 {
  display: block;
  width: 100%;
}
.base_style.column2_wrap .column1 .toc,
.base_style.column2_wrap .column2 .toc {
  font-size: 3.38164vw;
}
.base_style.column2_wrap .export-wrap_box .title_head .input_wrap .token__form .token__field-wrapper {
  flex-basis: calc(100% - 18.14286vw);
}

@media (min-width: 768px) {
  .base_style.column2_wrap {
    position: relative;
  }
  .base_style.column2_wrap .column1 {
    overflow: hidden;
    width: 16.25vw;
  }
  .base_style.column2_wrap .column1.toc {
    overflow: hidden;
    position: fixed;
    z-index: 1;
    margin-top: 0;
    font-size: 1.16667vw;
  }
  .base_style.column2_wrap .column2 {
    overflow: hidden;
    margin-left: 20px;
    width: calc(100% - 19.5vw);
    z-index: 1;
    float: right;
  }
  .base_style.column2_wrap .export-wrap_box .title_head {
    display: block;
  }
  .base_style.column2_wrap .export-wrap_box .title_head .service_name {
    width: 100%;
    margin-bottom: 20px;
  }
  .base_style.column2_wrap .export-wrap_box .title_head .input_wrap .token__form {
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  .base_style.column2_wrap .export-wrap_box .title_head .input_wrap .token__form .token__field-wrapper {
    flex-basis: calc(100% - 18.14286vw);
  }
  .base_style.column2_wrap .export-wrap_box .title_head .input_wrap .token__form .input input {
    display: block;
    width: 100%;
  }
  .base_style.column2_wrap .export-wrap_box .title_head .btn_wrap {
    width: auto;
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .base_style.column2_wrap .column1.toc {
    font-size: 1.4rem;
    width: 190px;
  }
  .base_style.column2_wrap .column2 {
    width: calc(100% - 230px);
  }
  .base_style.column2_wrap .export-wrap_box .title_head .input_wrap .token__form .token__field-wrapper {
    flex-basis: calc(100% - 26rem);
  }
  .base_style.column2_wrap .export-wrap_box .title_head .input_wrap .token__form .input input {
    display: block;
    width: 100%;
  }
}
.avatar_wrap {
  background-color: #f1f3f8;
  border-radius: 1rem;
}
.avatar_wrap ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 4.83092vw;
  margin-bottom: 4.83092vw;
}
.avatar_wrap ul li {
  text-align: center;
  border-bottom: 2px dotted #ABB2BC;
  width: 100%;
  padding: 6.03865vw;
}
.avatar_wrap ul li .title {
  margin-bottom: 3.86473vw;
  font-size: 3.86473vw;
}
.avatar_wrap ul li .image_wrap {
  background-color: #fff;
  padding: 4.83092vw;
  border-radius: .5rem;
}
.avatar_wrap ul li .label_wrap {
  margin-top: 2.41546vw;
  margin-bottom: 2.41546vw;
}
.avatar_wrap ul li:last-child {
  border: none;
}

@media (min-width: 768px) {
  .avatar_wrap ul {
    margin: 2rem auto;
  }
  .avatar_wrap ul li {
    padding: 2.08333vw;
    border-bottom: 0;
    width: 300px;
  }
  .avatar_wrap ul li .title {
    margin-bottom: 1.33333vw;
    font-size: 1.33333vw;
  }
  .avatar_wrap ul li .image_wrap {
    padding: 1.66667vw;
  }
  .avatar_wrap ul li .label_wrap {
    margin-top: 0.83333vw;
    margin-bottom: 0.83333vw;
  }
}
@media (min-width: 1200px) {
  .avatar_wrap ul {
    margin: 2rem auto;
  }
  .avatar_wrap ul li {
    padding: 2.5rem;
    border-bottom: 0;
    width: 300px;
  }
  .avatar_wrap ul li .title {
    margin-bottom: 1.6rem;
    font-size: 16px;
  }
  .avatar_wrap ul li .image_wrap {
    padding: 2rem;
  }
  .avatar_wrap ul li .label_wrap {
    margin: 1rem auto;
  }
}
/*----------------------------
// センター寄せ メッセージ表示系ページ
----------------------------*/
.base_style.center_wrap {
  text-align: center;
}
.base_style.center_wrap .input {
  margin: 3rem auto;
}
.base_style.center_wrap .input input {
  margin: auto;
}
.base_style.center_wrap .btn_wrap {
  margin: 2rem auto;
  text-align: center;
}
.base_style.center_wrap .btn_wrap .button-custom {
  margin: auto;
}

.wrapper__header + .wrapper__content .center_wrap h2 {
  margin-top: 1rem;
}

.comp_wrap h2 {
  margin-top: 1rem;
  margin-bottom: 3rem;
}
.comp_wrap .btn_wrap {
  margin: 8rem auto 3rem auto;
}

/*----------------------------
// 退会
----------------------------*/
.wrap-withdraw .bottom_tx {
  margin-top: 8rem;
}
.wrap-withdraw p {
  color: #111947;
}
.wrap-withdraw a {
  color: #12c4c4;
}
.wrap-withdraw .wrap-withdraw_content_button {
  margin: 3rem auto;
}
.wrap-withdraw h4 {
  font-size: 1.6rem;
  margin-bottom: 5px;
}
.wrap-withdraw .center {
  text-align: center;
}
.wrap-withdraw a.btn:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  h4 {
    font-size: 1.33333vw;
  }
}
/*----------------------------
// 会員ステータス
----------------------------*/
.table_1 {
  border: 1px solid #ccc;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.75;
}
.table_1 .th, .table_1 .td {
  padding: 1rem;
}
.table_1 .th {
  background: #111947;
  color: #fff;
  -webkit-flex-basis: 200px;
  flex-basis: 200px;
  text-align: center;
}
.table_1 .td {
  color: #111947;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-basis: calc(100% - 200px);
  flex-basis: calc(100% - 200px);
}
.table_1 .price {
  margin-left: 0.5em;
}
.table_1 .date.end {
  color: #ff6c6c;
}
.table_1 .date + .date {
  margin-left: 0.5em;
}

.status-wrap a {
  color: #12c4c4;
}
.status-wrap .small {
  font-size: 1.3rem;
  font-weight: normal;
}
.status-wrap .section {
  margin-bottom: 4rem;
}
.status-wrap .center {
  text-align: center;
}
.status-wrap .status-wrap_box {
  max-width: inherit;
}
.status-wrap .status-wrap_content {
  width: 100%;
}
.status-wrap .status-wrap_content-main p {
  font-size: 1.4rem;
}
.status-wrap .plan_wrap {
  margin-top: 2rem;
}
.status-wrap .plan_wrap .card-member {
  border: none;
  border-radius: 10px;
}
.status-wrap .plan_wrap .card-member_content {
  padding-top: 0rem;
}
.status-wrap .plan_wrap .card-member_header {
  padding: 1.6rem;
  padding-top: 1.2rem;
  border: 1px solid #ccc;
  border-bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.status-wrap .plan_wrap .card-member_header .head {
  line-height: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
}
.status-wrap .plan_wrap .card-member_header .head .plan {
  text-align: left;
  font-size: 2rem;
  font-weight: 600;
}
.status-wrap .plan_wrap .card-member_header .head .price {
  text-align: right;
  margin: 1rem 0;
  margin-left: 1rem;
  font-weight: 600;
}
.status-wrap .plan_wrap .card-member_header .head .price .title1 {
  text-align: center;
  font-size: 2.6rem;
}
.status-wrap .plan_wrap .card-member_header .head .price .title2 {
  font-size: 1.6rem;
  font-weight: 600;
}
.status-wrap .plan_wrap .card-member_header .header_content p {
  font-size: 1.4rem;
}
.status-wrap .plan_wrap .card-member_wrapper-content {
  padding: 0;
  padding-bottom: 0;
  border: 1px solid #ccc;
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.status-wrap .plan_wrap .card-member_wrapper-content p {
  text-align: center;
  margin-top: 1rem;
}
.status-wrap .plan_wrap .card-member_content {
  width: 100%;
}
.status-wrap .plan_wrap .card-member_content span {
  margin-left: inherit;
  font-size: 1.4rem;
}
.status-wrap .plan_wrap ul {
  width: 100%;
}
.status-wrap .plan_wrap ul li {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.5;
  position: relative;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ccc;
}
.status-wrap .plan_wrap ul li .title {
  font-size: 1.4rem;
  margin-bottom: 0.3rem;
  color: #888;
}
.status-wrap .plan_wrap ul li:last-child {
  border-bottom: none;
}
.status-wrap .plan_wrap ul li.btn .small {
  margin-top: 2rem;
  font-size: 1.2rem;
  text-align: center;
  padding: 0 1rem;
  line-height: 1.5;
}
.status-wrap .plan_wrap ul li .small {
  font-size: 1.2rem;
}
.status-wrap .plan_wrap ul li.btn .card-member_button {
  margin-top: 0rem;
}
.status-wrap .plan_wrap ul li.btn p {
  font-size: 1.4rem;
}
.status-wrap .plan_wrap .card-member {
  position: relative;
  margin-bottom: 0;
}
.status-wrap .plan_wrap .card-member.plan1 .card-member_header {
  background-color: #12c4c4;
}
.status-wrap .plan_wrap .card-member.plan2 .card-member_header {
  background-color: #EFB711;
}
.status-wrap .plan_wrap .card-member.plan3 .card-member_header {
  background-color: #8BC145;
}
.status-wrap .plan_wrap .card-member .card-member_wrapper-content {
  position: relative;
}
.status-wrap .plan_wrap .card-member .coming_disable {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #646464;
}
.status-wrap .plan_wrap .card-member .coming_disable .tx {
  width: 100%;
  text-align: center;
  font-size: 3rem;
  line-height: 1.4;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
}
.status-wrap .plan_wrap .card-member .coming_disable.spb .tx {
  font-size: 2rem;
}

@media (min-width: 1200px) {
  .status-wrap .box-content .card-member {
    width: 32%;
  }
}
.status-wrap .small {
  font-size: 2.89855vw;
}

.status-wrap .plan_wrap ul li .title,
.status-wrap .plan_wrap ul li,
.status-wrap .plan_wrap .card-member_header .header_content p,
.status-wrap .plan_wrap ul li.btn p,
.status-wrap .plan_wrap .card-member_content span,
.status-wrap .plan_wrap ul li .title {
  font-size: 3.38164vw;
}

.status-wrap .plan_wrap .card-member_header .head .plan {
  font-size: 3.86473vw;
}

.status-wrap .plan_wrap .card-member_header .head .price .title1 {
  font-size: 7.24638vw;
}

.status-wrap .plan_wrap .card-member_header .head .price .title2,
.table_1 {
  font-size: 3.86473vw;
}

@media (min-width: 768px) {
  .status-wrap .small {
    font-size: 1vw;
  }

  .status-wrap .plan_wrap ul li .title,
  .status-wrap .plan_wrap ul li,
  .status-wrap .plan_wrap .card-member_header .header_content p,
  .status-wrap .plan_wrap ul li.btn p,
  .status-wrap .plan_wrap .card-member_content span,
  .status-wrap .plan_wrap ul li .title {
    font-size: 1.16667vw;
  }

  .status-wrap .plan_wrap .card-member_header .head .price .title1 {
    font-size: 2.5vw;
  }

  .status-wrap .plan_wrap .card-member_header .head .plan,
  .status-wrap .plan_wrap .card-member_header .head .price .title2,
  .table_1 {
    font-size: 1.33333vw;
  }

  .status-wrap .plan_wrap .card-member_header .head .price {
    margin: 0.83333vw 0;
  }

  .status-wrap .plan_wrap .card-member {
    border: 1px solid #ccc;
  }

  .status-wrap .plan_wrap .card-member_header,
  .status-wrap .plan_wrap .card-member_wrapper-content {
    border: none;
  }

  .status-wrap .plan_wrap .card-member_header .head {
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .status-wrap .small {
    font-size: 1.2rem;
  }

  .status-wrap .plan_wrap ul li .title,
  .status-wrap .plan_wrap ul li,
  .status-wrap .plan_wrap .card-member_header .header_content p,
  .status-wrap .plan_wrap ul li.btn p,
  .status-wrap .plan_wrap .card-member_content span,
  .status-wrap .plan_wrap ul li .title {
    font-size: 1.4rem;
  }

  .status-wrap .plan_wrap .card-member_header .head .plan {
    font-size: 1.6rem;
  }

  .status-wrap .plan_wrap .card-member_header .head .price .title1 {
    font-size: 3rem;
  }

  .status-wrap .plan_wrap .card-member_header .head .price .title2,
  .table_1 {
    font-size: 1.6rem;
  }
}
@media (max-width: 768px) {
  #current_status .table_1 {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #current_status .table_1 .th {
    display: none;
  }
  #current_status .table_1 .td {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #current_status .table_1 .td,
  #current_status .table_1 .td .left,
  #current_status .table_1 .td .right {
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }
  #current_status .table_1 .td {
    padding: 0;
  }
  #current_status .table_1 .td .left, #current_status .table_1 .td .right {
    padding: 2.41546vw;
  }
  #current_status .table_1 .td .left {
    background-color: #111947;
    color: #fff;
    display: flex;
    justify-content: space-between;
  }
  #current_status .table_1 .date {
    display: block;
    margin: 0;
  }

  .card-member .card-member_wrapper-content {
    transition: .35s;
    height: auto;
    opacity: 1;
    visibility: visible;
  }
  .card-member .card-member__dropdown {
    transition: 0s;
  }

  .card-member.is-close .card-member_header {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid #ccc;
  }
  .card-member.is-close .card-member_wrapper-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  .card-member.is-close .card-member__dropdown {
    transform: rotate(-180deg);
    transform-origin: 50% 35%;
  }
}
/*----------------------------
// 会員ステータスについて
----------------------------*/
.about_user-status-wrap .section {
  padding-top: 7.24638vw;
  padding-bottom: 7.24638vw;
  border-bottom: 1px solid #ccc;
}
.about_user-status-wrap .section:first-of-type {
  padding-top: 2.41546vw;
}
.about_user-status-wrap .section .section {
  border: none;
  padding: 0;
  margin-top: 2.41546vw;
  margin-bottom: 7.24638vw;
}
.about_user-status-wrap .section .section .section {
  margin-top: 3.86473vw;
  margin-bottom: 3.86473vw;
}
.about_user-status-wrap .section .section:last-of-type {
  margin-bottom: 0;
}
.about_user-status-wrap .about_user-status-wrap_box > .section:last-of-type {
  border-bottom: none;
}

@media (min-width: 768px) {
  .about_user-status-wrap .section {
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
  }
  .about_user-status-wrap .section:first-of-type {
    padding-top: 1rem;
    padding-top: 0.83333vw;
  }
  .about_user-status-wrap .section .section {
    margin-top: 0.83333vw;
    margin-bottom: 1.66667vw;
  }
  .about_user-status-wrap .section .section .section {
    margin-top: 2.5vw;
    margin-bottom: 2.5vw;
  }
}
@media (min-width: 1200px) {
  .about_user-status-wrap .section {
    padding: 3rem 0;
  }
  .about_user-status-wrap .section:first-of-type {
    padding-top: 1rem;
  }
  .about_user-status-wrap .section .section {
    border: none;
    padding: 0;
    margin: 1rem 0 2rem 0;
  }
  .about_user-status-wrap .section .section .section {
    margin: 3rem 0;
  }
}
/*----------------------------
// ヘルプ
----------------------------*/
/*-- ヘルプページTOP --*/
.help_menu {
  margin-top: 12.07729vw;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: wrap;
}
.help_menu li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  margin-bottom: 0.8em;
}
.help_menu li a {
  font-size: 3.62319vw;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-align: center;
  border: 2px solid #12c4c4;
  border-radius: 0.5em;
  display: block;
  width: 100%;
  min-height: 14.49275vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
}
.help_menu li a:hover {
  transition: 0.3s;
  filter: brightness(95%);
  text-decoration: none;
}
.help_menu li a::after {

}

@media (min-width: 768px) {
  .help_menu {
    margin-top: 8.33333vw;
  }
  .help_menu li {
    flex-basis: 49.5%;
  }
  .help_menu li:first-child {
    flex-basis: 100%;
  }
  .help_menu li a {
    font-size: 1.33333vw;
    min-height: 8.33333vw;
  }
}
@media (min-width: 1200px) {
  .help_menu {
    margin-top: 100px;
  }
  .help_menu li a {
    font-size: 1.6rem;
    min-height: 100px;
  }
}
/*-- ヘルプページタブ --*/
.tab1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  margin-top: 7.24638vw;
  margin-bottom: 7.24638vw;
  border: 2px solid #12c4c4;
  letter-spacing: 0.05em;
}
.tab1 li {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab1 li a {
  display: block;
  font-size: 3.86473vw;
  padding: 0.8em;
  width: 100%;
  text-align: center;
  background-color: #fff;
}
.tab1 li a:hover {
  transition: 0.3s;
  filter: brightness(95%);
  text-decoration: none;
  cursor: pointer;
}
.tab1 li.current a {
  background-color: #12c4c4;
  color: #fff;
  font-weight: 700;
}
.tab1 li.current:hover a {
  filter: inherit;
}
.tab1 li.current::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: #12c4c4 transparent transparent transparent;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

@media (min-width: 768px) {
  .tab1 {
    margin-top: 2.5vw;
    margin-bottom: 2.5vw;
  }
  .tab1 li a {
    font-size: 1.33333vw;
  }
}
@media (min-width: 1200px) {
  .tab1 {
    margin: 30px auto;
  }
  .tab1 li a {
    font-size: 1.6rem;
  }
}
/*-- ヘルプページ中身 --*/
.help-wrap .section {
  padding: 5em 0;
  border-bottom: 1px solid #ccc;
}
.help-wrap .section .section {
  border: none;
  padding: 0;
  margin: 3em 0;
  border-bottom: 0;
  padding: 0;
}
.help-wrap .section .section .section {
  margin: 3em 0;
}
.help-wrap .section .section .section .section {
  margin: 2em 0;
}
.help-wrap .section .section:last-of-type {
  margin-bottom: 0;
}
.help-wrap .section p + p {
  margin-top: 1em;
}
.help-wrap .section a:hover {
  cursor: pointer;
}
.help-wrap h2 {
  font-size: 4.83092vw;
  letter-spacing: 0.05em;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 1em;
  padding-bottom: 0.2em;
}
.help-wrap h2::before {
  content: "";
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  background: url("./assets/images/image/ico_symbol.svg") no-repeat;
  background-size: contain;
  margin-right: 0.5em;
}
.help-wrap .title_area {
  text-align: center;
  margin-top: 12.07729vw;
  margin-bottom: 12.07729vw;
}
.help-wrap h3 {
  font-size: 4.10628vw;
  letter-spacing: 0.05em;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 1em;
  padding-bottom: 0.2em;
}
.help-wrap h3::before {
}
.help-wrap h4 {
  border-left: 5px solid #12c4c4;
  padding-left: 0.5em;
  margin-bottom: 1em;
}
.help-wrap h4 i {
  margin-right: 0.2em;
}
.help-wrap p + h5 {
  margin-top: 2em;
}
.help-wrap .margin_btn {
  margin: 20px auto;
  margin-top: 4.83092vw;
  margin-bottom: 4.83092vw;
}
.help-wrap .base_table {
  font-size: 3.38164vw;
}
.help-wrap .base_table td {
  background-color: #fff;
}
.help-wrap p + .base_table {
  margin-top: 1em;
}
.help-wrap .base_table + p {
  margin-top: 1em;
}
.help-wrap.help_howto .base_table .th {
  width: 14em;
}
.help-wrap.help_howto .base_table i {
  margin-left: 0.2em;
}
.help-wrap .dl_btns {
  margin: 1em 0;
}
.help-wrap .dl_btns a:hover {
  text-decoration: none;
  opacity: 0.6;
  transition: 0.3s;
}
.help-wrap .image_wrap {
  text-align: center;
  margin: 3em auto;
}
.help-wrap .image_wrap img {
  width: 100%;
  height: auto;
  max-width: 800px;
}
.help-wrap .image_wrap_m img {
  max-width: 500px;
}
.help-wrap .image_wrap_s img {
  max-width: 320px;
}
.help-wrap .image_wrap_sp img {
  max-width: 240px;
}
.help-wrap .image_wrap.bg_color2 {
  padding: 2em;
  border-radius: 0.5em;
  background-color: #f1f3f8;
}
.help-wrap .image_wrap.image_wrap_arrow2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.help-wrap .image_wrap.image_wrap_arrow2 .wrap {
  width: 46%;
}
.help-wrap .image_wrap.image_wrap_arrow2 .arrow_wrap {
  width: 8%;
  color: #12c4c4;
  font-size: 30px;
}
.help-wrap #service .image_wrap {
  margin-bottom: 0;
}
.help-wrap .avatar_wrap {
  background-color: #f1f3f8;
  border-radius: 0.5em;
  padding: 2em;
  padding-top: 0;
  padding-bottom: 0;
  margin: 2em auto;
}
.help-wrap .avatar_wrap ul {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.help-wrap .avatar_wrap ul li {
  text-align: center;
  border-bottom: 2px dotted #ABB2BC;
  width: 100%;
  padding: 2em 0;
}
.help-wrap .avatar_wrap ul li .title {
  margin-bottom: 1.6rem;
}
.help-wrap .avatar_wrap ul li .image_wrap {
  background-color: #fff;
  padding: 2em;
  border-radius: 0.5em;
  margin: 0 auto;
}
.help-wrap .avatar_wrap ul li .image_wrap img {
  max-height: 100px;
}
.help-wrap .avatar_wrap ul li .label_wrap {
  margin: 1em auto;
}
.help-wrap .avatar_wrap ul li .label_wrap .label + .label {
  margin-left: 0.3em;
}
.help-wrap .avatar_wrap ul li:last-child {
  border: none;
}
.help-wrap .avatar_wrap ul li p {
  text-align: left;
  font-size: 3.38164vw;
}
.help-wrap .attention_wrap {
  margin-top: 2em;
}
.help-wrap .attention_wrap h4 {
  margin: 0;
  padding: 0.5em;
  text-align: center;
  color: #fff;
  font-weight: bold;
  border: 0;
  background-color: #111947;
}
.help-wrap .attention_wrap .cont_wrap {
  background-color: #f1f3f8;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 2em;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.help-wrap .attention_wrap .wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}
.help-wrap .attention_wrap .wrap .title-h5 {
  background: #111947;
  padding: 0.3em 0.7em;
  text-align: center;
  margin: 1em auto;
  display: inline-block;
  color: #fff;
}
.help-wrap .attention_wrap .wrap .image_wrap {
  margin: 1em auto;
  margin-top: 0;
}
.help-wrap .attention_wrap .wrap p {
  text-align: center;
}
.help-wrap .attention_wrap .wrap + .wrap {
  margin-top: 1em;
}
.help-wrap .flex_wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  background-color: #f1f3f8;
  border-radius: 0.5em;
  padding: 1em;
  padding-bottom: 0;
  margin: 2em auto;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.help-wrap .flex_wrap .flex_box {
  -webkit-flex-basis: 48%;
  flex-basis: 48%;
  margin: 1%;
  margin-bottom: 1em;
  text-align: center;
}
.help-wrap .flex_wrap img {
  width: 60%;
  height: auto;
}
.help-wrap .flex_wrap p {
  margin-top: 0.5em;
  text-align: center;
  font-weight: 600;
  font-size: 3.38164vw;
}
.help-wrap .flex_wrap.preparation_wrap .flex_box {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .help-wrap h2 {
    font-size: 1.83333vw;
  }
  .help-wrap .title_area {
    margin-top: 4.16667vw;
    margin-bottom: 4.16667vw;
  }
  .help-wrap h3 {
    font-size: 1.5vw;
  }
  .help-wrap .base_table {
    font-size: 1.16667vw;
  }
  .help-wrap .dl_btns {
    margin-top: 0.83333vw;
    margin-bottom: 0.83333vw;
  }
  .help-wrap .margin_btn {
    margin-top: 1.66667vw;
    margin-bottom: 1.66667vw;
  }
  .help-wrap .avatar_wrap {
    padding: 2em;
  }
  .help-wrap .avatar_wrap ul li {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    padding: 0;
    border-bottom: 0;
  }
  .help-wrap .avatar_wrap ul li .title {
    font-size: 1.33333vw;
  }
  .help-wrap .avatar_wrap ul li:last-child {
    border: none;
  }
  .help-wrap .avatar_wrap ul li p {
    font-size: 1.16667vw;
  }
  .help-wrap .attention_wrap .wrap {
    -webkit-flex-basis: 48%;
    flex-basis: 48%;
  }
  .help-wrap .attention_wrap .wrap .title-h5 {
    margin-top: 0;
  }
  .help-wrap .attention_wrap .wrap + .wrap {
    margin-top: 0em;
  }
  .help-wrap .flex_wrap {
    -webkit-flex-wrap: no-wrap;
    flex-wrap: no-wrap;
    padding-bottom: 1em;
  }
  .help-wrap .flex_wrap .flex_box {
    -webkit-flex-basis: inherit;
    flex-basis: inherit;
  }
  .help-wrap .flex_wrap .flex_box p {
    font-size: 1.16667vw;
  }
  .help-wrap .flex_wrap .flex_box img {
    width: 100%;
  }
  .help-wrap .flex_wrap.preparation_wrap {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .help-wrap .flex_wrap.preparation_wrap .flex_box {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
  }
}
@media (min-width: 1200px) {
  .help-wrap h2 {
    font-size: 2.2rem;
  }
  .help-wrap .title_area {
    margin: 50px auto;
  }
  .help-wrap h3 {
    font-size: 1.8rem;
  }
  .help-wrap .base_table {
    font-size: 1.4rem;
  }
  .help-wrap .dl_btns {
    margin: 1em 0;
  }
  .help-wrap .avatar_wrap ul li .title {
    font-size: 16px;
  }
  .help-wrap .avatar_wrap ul li p {
    font-size: 14px;
  }
  .help-wrap .flex_wrap .flex_box p {
    font-size: 1.4rem;
  }
}
.help-wrap.help_booking-attention h4 {
  padding-left: 0;
  border: 0;
}
.help-wrap .booking__form--footer__clothes-wrapContent {
  margin-bottom: 2em;
}
.help-wrap .booking-attention__other-cautions-list li::before {
  content: "・";
}
.help-wrap .booking-attention__other-cautions-list li,
.help-wrap .booking-attention__removing-items-notes li,
.help-wrap .booking-attention__removing-items-list li span,
.help-wrap .booking-attention__check-notification--content-content li {
  margin: 1em 0;
}
.help-wrap .booking-attention__removing-items-list {
  background-color: #f1f3f8;
}
.help-wrap .booking-attention__clothes-list {
  border-radius: 0.5em;
  background-color: #f1f3f8;
  padding: 2em;
}
.help-wrap .booking-attention__removing-items-lead {
  margin-left: 0;
}
.help-wrap .booking-attention__removing-items-notes {
  padding-left: 0;
}
.help-wrap .booking__form--footer__clothes-content--box1__header h4 {
  margin-bottom: 0;
}
.help-wrap .booking-attention__other-cautions {
  padding-left: 0;
}
.help-wrap .booking__form--footer__clothes-warning2--content__header {
  margin-bottom: 0;
}
.help-wrap .booking-attention__title_warning h4 {
  text-align: center;
}
.help-wrap .booking-attention__check-warning--content {
  padding: 0;
}
.help-wrap .booking-attention__check-notification {
  border-bottom: 0;
}
.help-wrap .booking__form--footer__clothes-content--box1__content-wrap1 .text-group,
.help-wrap .booking__form--footer__clothes-content--box1__content-wrap2 .text-group,
.help-wrap .booking__form--footer__clothes-content--box2__content-wrap1 .text-group__padding p,
.help-wrap .booking-attention__clothe-label,
.help-wrap .booking-attention__check-warning--content_box p,
.help-wrap .booking__form--footer__clothes-warning2--content__container-box .box1-text__padding,
.help-wrap p.booking-attention__clothe-label {
  font-size: 3.1401vw;
  line-height: 1.5;
  font-weight: 600;
}

@media (min-width: 768px) {
  .help-wrap .booking-attention__check-warning--content_box {
    width: 13.5%;
  }
  .help-wrap .booking-attention__removing-items-list li span {
    margin-top: 0;
    margin-bottom: 0;
  }
  .help-wrap .booking__form--footer__clothes-content--box1__content-wrap1 .text-group,
  .help-wrap .booking__form--footer__clothes-content--box1__content-wrap2 .text-group,
  .help-wrap .booking__form--footer__clothes-content--box2__content-wrap1 .text-group__padding p,
  .help-wrap .booking-attention__clothe-label,
  .help-wrap .booking-attention__check-warning--content_box p,
  .help-wrap .booking__form--footer__clothes-warning2--content__container-box .box1-text__padding,
  .help-wrap p.booking-attention__clothe-label {
    font-size: 1vw;
  }
}
@media (min-width: 1200px) {
  .help-wrap .booking-attention__other-cautions-list li,
  .help-wrap .booking-attention__removing-items-notes li,
  .help-wrap .booking-attention__check-notification--content-content li {
    margin: 0.5em 0;
  }
  .help-wrap .booking__form--footer__clothes-content--box1__content-wrap1 .text-group,
  .help-wrap .booking__form--footer__clothes-content--box1__content-wrap2 .text-group,
  .help-wrap .booking__form--footer__clothes-content--box2__content-wrap1 .text-group__padding p,
  .help-wrap .booking-attention__clothe-label,
  .help-wrap .booking-attention__check-warning--content_box p,
  .help-wrap .booking__form--footer__clothes-warning2--content__container-box .box1-text__padding,
  .help-wrap p.booking-attention__clothe-label {
    font-size: 1.2rem;
  }
}
/*----------------------------
// ヘルプ
----------------------------*/
.scan-booking-wrap .alert_tx.center_tx {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 3.86473vw;
}
.scan-booking-wrap a.button-custom,
.scan-booking-wrap a.button-custom:hover {
  text-decoration: none;
  color: #fff !important;
}

@media (min-width: 768px) {
  .scan-booking-wrap .alert_tx.center_tx {
    font-size: 1.14286vw;
  }
}
@media (min-width: 1400px) {
  .scan-booking-wrap .alert_tx.center_tx {
    font-size: 16px;
  }
}
/*----------------------------
// 仮登録ページ
----------------------------*/
.wrapper.password .red {
  color: #ff6c6c;
}
.wrapper.password .title_h4 {
  font-size: 3.38164vw;
  font-weight: 700;
  margin-bottom: 0.7em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5em;
}
.wrapper.password .title_h5 {
  font-size: 3.38164vw;
  font-weight: 700;
  margin-bottom: 0.7em;
}
.wrapper.password .title_h6 {
  font-weight: 700;
}
.wrapper.password .password__warning-content .section {
  margin-top: 1em;
}

@media (min-width: 768px) {
  .wrapper.password .title_h4 {
    font-size: 1.16667vw;
  }
  .wrapper.password .title_h5 {
    font-size: 1.16667vw;
  }
}
@media (min-width: 1200px) {
  .wrapper.password .title_h4 {
    font-size: 14px;
  }
  .wrapper.password .title_h5 {
    font-size: 14px;
  }
}
/*----- ページ内リンク ずれ対策 -----*/
.help-wrap .section::before {
  content: " ";
  margin-top: -14.49275vw;
  height: 14.49275vw;
  display: block;
  visibility: hidden;
}

@media (min-width: 768px) {
  .help-wrap .section::before {
    margin-top: -4.28571vw;
    height: 4.28571vw;
  }
}
@media (min-width: 1200px) {
  .help-wrap .section::before {
    margin-top: -6rem;
    height: 6rem;
  }
}

/*# sourceMappingURL=style_v2.css.map */
