.forgot-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  .text {
    font-size: 1.6rem;
    color: #111947;
  }
  h3 {
    font-weight: bold;
    color: #111947;
    font-size: 2.4rem;
  }
  &_header {
    margin-top: 2.2rem;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-bottom: 2.8rem;
  }
  &_header2 {
    margin-top: 2.2rem;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-bottom: 3.4rem;
  }
  &_header-text {
    p {
      color: $color-text-normal;
    }
    margin-top: 2.4rem;
  }
  &_content {
    width: 100%;
  }
  &_content-input {
    span {
      font-weight: bold;
    }
    .label {
      color: #ec0606;
    }
    .label_input {
      margin-top: 1.2rem;
      margin-bottom: 2.4rem;
    }
    .email {
      input {
        outline: none;
        background: #ffffff;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 0.5rem;
        width: 50rem;
        height: 5.5rem;
        padding: 1.6rem 2.4rem;
        margin-top: 1.2rem;
      }
      ::placeholder {
        color: #d3d3d3;
        opacity: 1;
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
    .form_email {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  &_content-input2 {
    margin-bottom: 2.3rem;
  }
  &_content2 {
    width: 32.4rem;
    height: 3.8rem;
    text-align: center;
    margin-top: 2.4rem;
    margin-bottom: 3.5rem;
    p {
      color: #9f9f9f;
    }
  }
  &_button {
    button {
      box-sizing: border-box;
      width: 50rem;
      height: 5rem;
      background: $blue-main;
      border-radius: 5rem;
      outline: none;
      border: none;
      color: #fff;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 2.5rem;
      width: 100%;
    }
  }
  &_button2 {
    margin-top: 3.4rem;
  }

  @media #{$mobile} {
    &_content {
      width: 100vw;
    }
    &_header-text {
      margin-top: 0;
      text-align: center;
    }
    &_content-input {
      .email {
        input {
          width: 80vw;
        }
      }
    }
    &_button {
      button {
        width: 80vw;
      }
    }
  }
}
