.page {
  &.scanner {
    height: 100vh;
    @include flex-column();
  }
}

.scanner-location {
  @include flex-column();
  @media #{$mobile} {
    overflow: initial;
  }
  .scanner__describe {
    @include flex-column();
    @media #{$mobile} {
      overflow: initial;
    }
  }
  svg {
    fill: $icon-color;
  }
  .box-scanner {
    flex-wrap: wrap;
    display: flex;
    flex: 1 1;
    overflow: auto;
    display: flex;
    @media #{$mobile} {
      width: 100%;
      display: block;
      overflow: initial;
    }
    &__col {
      width: calc(50%);

      &:first-child {
        height: 100%;
        padding-right: 15px;
        @include flex-column();
      }
      &:last-child {
        padding-left: 15px;
      }
      @media #{$mobile} {
        width: 100%;
        display: block;
        overflow: initial;
        padding: 0 !important;
        &:first-child {
          height: auto;
        }
      }
      .form-field-error-message {
        max-width: initial;
        width: initial;
        text-align: right;
      }
    }
    .scanner-map {
      @include flex-column();
      @media #{$mobile} {
        margin-top: 10px;
      }
      &__map {
        width: 100%;
        height: 380px;
        @media #{$mobile} {
          height: 200px;
        }
      }
      a {
        margin-left: auto;
        font-size: 12px;
        margin-top: 12px;
      }
    }
  }

  .select-box {
    margin-bottom: 20px;
    width: calc(50% - 15px);
    > div,
    > div > div {
      width: 100%;
    }
    @media #{$mobile} {
      width: 100%;
    }
  }

  .list-scanner {
    > div {
      margin-bottom: 15px;
      &:last-child {
        margin: 0;
      }
    }
    @media #{$mobile} {
      max-height: initial;
    }
  }

  .scanner-item {
    display: flex;
    color: #111947;
    position: relative;
    border: 1px solid $gray-divider;
    padding: calc(15vw / 4.14) calc(32vw / 4.14) calc(15vw / 4.14) calc(20vw / 4.14);
    @media #{$tablets} {
      padding: calc(15vw / 14) calc(32vw / 14) calc(15vw / 14) calc(20vw / 14);
    }
    @media #{$desktops} {
      padding: 15px 32px 15px 20px;
    }
    @include transition;
    @media #{$mobile} {
      flex-wrap: wrap;
      padding: 20px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      left: 0;
      background: $blue-main;
      visibility: hidden;
      opacity: 0;
      @include transition;
    }

    &:hover {
      background: #f1f3f8;
      border-color: transparent;
      .scanner-item__info {
        &__icon {
          svg {
            fill: #111947;
          }
        }
      }
    }

    svg {
      @include transition;
    }

    &:not(.selected) {
      cursor: pointer;
    }

    &.selected {
      background: #f9f9f9;
      border-color: transparent;
      .scanner-item__info__icon {
        svg {
          fill: #111947;
        }
      }
      &::before {
        visibility: visible;
        opacity: 1;
      }
      .scanner-item__info {
        max-width: calc(100% - 162px);
        @media #{$mobile} {
          padding-bottom: 0;
          max-width: 100%;
        }
      }
      .scanner-item__info__des {
        p:last-child {
          @media #{$mobile} {
            line-height: 24px;
          }
        }
      }
    }

    &__info {
      display: flex;
      flex: 1;
      max-width: 100%;

      @media #{$mobile} {
        max-width: 100%;
        width: 100%;
        flex: auto;
      }
      &__icon {
        margin-right: 0.8rem;
        line-height: 1.875rem;
      }
      &__des {
        padding-right: 5px;
        max-width: calc(100% - 24px);
        flex: 1;
        @media #{$mobile} {
          padding: 0;
        }
        p {
          margin: 0;
          @include text-ellipsis;
          &:last-child {
            word-break: break-word;
            line-height: 21px;
            font-size: calc(16vw / 4.14);
            @media #{$tablets} {
              font-size: calc(16vw / 14);
            }
            @media #{$large-desktops} {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    > button {
      margin: auto calc(20vw / 4.14) auto auto;
      line-height: initial;
      font-size: calc(16vw / 4.14);
      @media #{$tablets} {
        font-size: calc(16vw / 14);
        margin: auto calc(20vw / 14) auto auto;
      }
      @media #{$large-desktops} {
        font-size: 1.4rem;
        margin: auto 20px auto auto;
      }
      @media #{$mobile} {
        margin: 15px auto 0;
      }
    }
  }
}
