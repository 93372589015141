.wrap-manager_friend {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: calc( 10vw / 4.14 );
  padding: 0 calc( 15vw / 4.14 );
  .avatar-friend {
    width: calc( 100vw / 4.14 );
    height: calc( 100vw / 4.14 );
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
  }
  .info-friend {
    padding-left: calc( 15vw / 4.14 );
    width: calc(100% - calc(100vw / 4.14));
    display: flex;
    flex-direction: column;
    justify-content: center;
    .name-friend {
      font-size: calc( 20vw / 4.14 );
      line-height: 1;
      font-weight: bold;
      color: #111947;
      width: calc( 130vw / 4.14 );
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .avatar-and_friend {
      display: flex;
      width: 100%;
      padding-bottom: calc( 10vw / 4.14 );
      border-bottom: 1px solid #ccc;
      .avatar {
        margin-right: calc( 20vw / 4.14 );
      }
      .count {
        padding-left: calc( 5vw / 4.14 );
      }
      .avatar,
      .friend {
        font-size: calc( 14vw / 4.14 );
        font-weight: 400;
        line-height: 1.75;
        text-align: left;
        vertical-align: middle;
        color: $body-text;
        .count {
          color: $blue-main;
          font-weight: bold;
        }
      }
    }
    .btn-friend {
      &_button {
        display: flex;
        justify-content: center;
        .button-register {
          display: flex;
          padding: 0;
          position: relative;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: calc( 40vw / 4.14 );
          max-width: 100%;
          &--spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          button {
	          width: 100%;
	          font-size: calc( 14vw / 4.14 );
	          line-height: 1;
          }
          .request-friend-button {
            &:first-child {
              margin-right: 0.5rem;
            }
            &:last-child {
              margin-left: 0.5rem;
            }
            width: calc(50% - 0.5rem);
          }

          .label {
            background: transparent;
            cursor: auto;
            &:hover {
              background: transparent;
              cursor: auto;
            }
          }
        }
      }
    }
  }
  @media #{$tablets} {
	  border-radius: calc( 10vw / 14 );
	  padding: 0 calc( 15vw / 14 );
	  .avatar-friend {
	    width: calc( 100vw / 14 );
	    height: calc( 100vw / 14 );
	  }
	  .info-friend {
	    padding-left: calc( 15vw / 14 );
	    width: calc(100% - calc(100vw / 14));
	    .name-friend {
	      font-size: calc( 20vw / 14 );
	      width: calc( 130vw / 14 );
	    }
	    .avatar-and_friend {
	      padding-bottom: calc( 10vw / 14 );
	      .avatar {
	        margin-right: calc( 20vw / 14 );
	      }
	      .count {
	        padding-left: calc( 5vw / 14 );
	      }
	      .avatar,
	      .friend {
	        font-size: calc( 14vw / 14 );
	      }
	    }
	    .btn-friend {
	      &_button {
	        .button-register {
	          height: calc( 40vw / 14 );
	          max-width: 100%;
	          button {
		          font-size: calc( 14vw / 14 );
	          }
	          .request-friend-button {
	            &:first-child {
	              margin-right: 0.5rem;
	            }
	            &:last-child {
	              margin-left: 0.5rem;
	            }
	            width: calc(50% - 0.5rem);
	          }
	
	        }
	      }
	    }
	  }
  }
  @media #{$large-desktops} {
	  border-radius: 1rem;
	  padding: 0 1.5rem;
	  .avatar-friend {
	    width: 10rem;
	    height: 10rem;
	  }
	  .info-friend {
	    padding-left: 1.5rem;
	    width: calc(100% - 10rem);
	    .name-friend {
	      font-size: 2rem;
	      width: 13rem;
	    }
	    .avatar-and_friend {
	      padding-bottom: 1rem;
	      .avatar {
	        margin-right: 2rem;
	      }
	      .count {
	        padding-left: .5rem;
	      }
	      .avatar,
	      .friend {
	        font-size: 1.4rem;
	      }
	    }
	    .btn-friend {
	      &_button {
	        .button-register {
	          height: 4rem;
	          button {
		          font-size: 1.4rem;
	          }
	          .request-friend-button {
	            &:first-child {
	              margin-right: 0.5rem;
	            }
	            &:last-child {
	              margin-left: 0.5rem;
	            }
	            width: calc(50% - 0.5rem);
	          }
	
	        }
	      }
	    }
	  }
  }
}
