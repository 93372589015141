.detail-avatar {
  padding-top: 0;
  @media #{$tablets} {
    padding-top: calc(20vw / 14);
  }
  @media #{$large-desktops} {
    padding-top: 2rem;
  }
}

.detail-avatar_wrap--avatar {
  margin-bottom: calc(50vw / 4.14);
  @media #{$tablets} {
    margin-bottom: calc(50vw / 14);
  }
  @media #{$large-desktops} {
    margin-bottom: 5rem;
  }
}
.wrap-content1_avatar.name_avatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(30vw / 4.14);
  padding-bottom: calc(15vw / 4.14);
  border-bottom: 1px solid #ccc;
  .button-edit {
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
    width: calc(24vw / 4.14);
    height: calc(24vw / 4.14);
    svg {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  .box-content {
    width: calc(100% - calc(24vw / 4.14));
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &_icon {
      margin-right: calc(10vw / 4.14);
      svg {
        width: calc(24vw / 4.14);
        height: calc(24vw / 4.14);
        vertical-align: middle;
      }
    }
    .box-input {
      flex: 1;
      &.isDisable {
        input {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      input {
        width: 100%;
        height: auto;
        border: none;
        -webkit-appearance: none;
        padding: 0;
        font-size: calc(16vw / 4.14);
        font-weight: 600;
      }
    }
  }

  @media #{$tablets} {
    margin-top: calc(30vw / 14);
    padding-bottom: calc(15vw / 14);
    .button-edit {
      width: calc(24vw / 14);
      height: calc(24vw / 14);
    }
    .box-content {
      width: calc(100% - calc(24vw / 14));
      &_icon {
        margin-right: calc(10vw / 14);
        svg {
          width: calc(24vw / 14);
          height: calc(24vw / 14);
        }
      }
      .box-input {
        width: calc(70% - calc(34vw / 14));
        flex: none;
        input {
          font-size: calc(16vw / 14);
        }
      }
    }
  }

  @media #{$large-desktops} {
    margin-top: 3rem;
    padding-bottom: 1.5rem;
    .button-edit {
      width: 2.4rem;
      height: 2.4rem;
    }
    .box-content {
      width: calc(100% - 2.4rem);
      &_icon {
        margin-right: 1rem;
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
      .box-input {
        width: calc(65% - 3.4rem + 48px);
        flex: none;
        @media #{$large-desktops} {
          width: calc(60% - 3.4rem + 48px);
          &.isEN {
            width: calc(57% - 3.4rem + 48px);
          }
        }
        input {
          font-size: 1.6rem;
        }
      }
    }
  }
}

.wrap-content1_date {
  padding: calc(30vw / 4.14) 0 calc(15vw / 4.14);
  border-bottom: 1px solid #ccc;
  .box-content_date {
    p {
      font-weight: 700;
    }
  }
  .box-content {
    width: calc(100% - calc(24vw / 4.14));
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .box-content_date {
      display: flex;
    }
    p {
      font-size: calc(16vw / 4.14);
      color: $body-text;
    }

    &_icon {
      margin-right: calc(10vw / 4.14);
      svg {
        width: calc(24vw / 4.14);
        height: calc(24vw / 4.14);
        vertical-align: middle;
      }
    }
    .box-date_content {
      p {
        font-weight: 500;
      }
    }
  }
  @media #{$tablets} {
    padding: calc(30vw / 14) 0 calc(15vw / 14);
    .box-content {
      width: calc(100% - calc(24vw / 14));
      p {
        font-size: calc(16vw / 14);
      }
      &_icon {
        margin-right: calc(10vw / 14);
        svg {
          width: calc(24vw / 14);
          height: calc(24vw / 14);
        }
      }
    }
  }
  @media #{$large-desktops} {
    padding: 3rem 0 1.5rem;
    .box-content {
      width: calc(100% - 2.4rem);
      p {
        font-size: 1.6rem;
      }
      &_icon {
        margin-right: 1rem;
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
}
.wrap-content1_location {
  padding: calc(30vw / 4.14) 0 calc(15vw / 4.14);
  border-bottom: 1px solid #ccc;
  .box-content {
    width: calc(100% - calc(24vw / 4.14));
    display: flex;
    justify-content: flex-start;
    align-items: center;
    p {
      font-size: calc(16vw / 4.14);
      font-weight: 600;
      color: $body-text;
    }

    &_icon {
      margin-right: calc(10vw / 4.14);
      svg {
        width: calc(24vw / 4.14);
        height: calc(24vw / 4.14);
        vertical-align: middle;
      }
    }
  }
  @media #{$tablets} {
    padding: calc(30vw / 14) 0 calc(15vw / 14);
    .box-content {
      width: calc(100% - calc(24vw / 14));
      p {
        font-size: calc(16vw / 14);
      }
      &_icon {
        margin-right: calc(10vw / 14);
        svg {
          width: calc(24vw / 14);
          height: calc(24vw / 14);
        }
      }
    }
  }
  @media #{$large-desktops} {
    padding: 3rem 0 1.5rem;
    .box-content {
      width: calc(100% - 2.4rem);
      p {
        font-size: 1.6rem;
      }
      &_icon {
        margin-right: 1rem;
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
}

.box-upload {
  padding: calc(30vw / 4.14) 0 calc(15vw / 4.14);
  border-bottom: 1px solid #ccc;
  .wrap-upload {
    .box-content1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: calc(15vw / 4.14);
      .box-content_icon {
        margin-right: calc(10vw / 4.14);
        svg {
          width: calc(24vw / 4.14);
          height: calc(24vw / 4.14);
          vertical-align: middle;
        }
      }
    }
  }
  .box-upload_button {
    margin-left: calc(34vw / 4.14);
    .box-upload_content {
      margin-bottom: calc(30vw / 4.14);
      p {
        color: $body-text;
        font-size: calc(16vw / 4.14);
      }
    }
  }
  .box-upload_content_btn {
    width: calc( 270vw / 4.14 );
    margin: 0 auto;
    button{width: 100%;}
  }
  @media #{$tablets} {
    display: flex;
    flex-wrap: wrap;
    padding: calc(30vw / 14) 0 calc(15vw / 14);
    .wrap-upload {
      flex-basis: 100%;
      width: 100%;
      .box-content1 {
        margin-bottom: calc(15vw / 14);
        .box-content_icon {
          margin-right: calc(10vw / 14);
          svg {
            width: calc(24vw / 14);
            height: calc(24vw / 14);
          }
        }
      }
    }
    .box-upload_button {
      flex-basis: 65%;
      width: 65%;
      margin-left: calc(34vw / 14);
      .box-upload_content {
        margin-bottom: 0;
        p {
          color: $body-text;
          font-size: calc(16vw / 14);
        }
      }
    }
    .box-upload_content_btn {
      flex-basis: 25%;
      width: calc(150vw / 14);
      margin: 0 0 0 auto;
      button {
	      width: calc(150vw / 14);
        height: calc(36vw / 14);
        font-size: calc(14vw / 14);
      }
    }
  }
  @media #{$large-desktops} {
    padding: 3rem 0 1.5rem;
    .wrap-upload {
      .box-content1 {
        margin-bottom: 1.5rem;
        .box-content_icon {
          margin-right: 1rem;
          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }
    .box-upload_button {
      flex-basis: 65%;
      margin-left: 3.4rem;
      .box-upload_content {
        margin-bottom: 0;
        p {
          font-size: 1.6rem;
        }
      }
    }
    .box-upload_content_btn {
      flex-basis: 25%;
      width: 15rem;
      margin: 0 0 0 auto;
      button {
	      width: 15rem;
        height: 3.6rem;
        font-size: 1.4rem;
      }
    }
  }
}

.box-down.wrap-content1_avatar {
  padding: calc(30vw / 4.14) 0 calc(15vw / 4.14);
  border-bottom: 1px solid #ccc;
  .box-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(15vw / 4.14);
    &_icon {
      margin-right: calc(10vw / 4.14);
      svg {
        width: calc(24vw / 4.14);
        height: calc(24vw / 4.14);
        vertical-align: middle;
      }
    }
  }
  .box-down_button {
    display: none;
  }
  .box-down_text {
    padding-left: calc(34vw / 4.14);
    font-size: calc(16vw / 4.14);
    color: $body-text;
  }
  @media #{$tablets} {
    padding: calc(30vw / 14) 0 calc(15vw / 14);
    display: flex;
    align-items: center;
    .box-content {
      margin-bottom: 0;
      &_icon {
        margin-right: calc(10vw / 14);
        svg {
          width: calc(24vw / 14);
          height: calc(24vw / 14);
        }
      }
    }
    .box-down_button {
      display: block;
      margin-left: auto;
      width: calc(270vw / 14);
      flex-basis: 33%;
    }
    .box-down_text {
      display: none;
    }
  }
  @media #{$large-desktops} {
    padding: 3rem 0 1.5rem;
    .box-content {
      &_icon {
        margin-right: 1rem;
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
    .box-down_button {
      width: 27rem;
    }
  }
}

.wrap-content1_avatar.wrap-connect {
  padding: calc(30vw / 4.14) 0 calc(10vw / 4.14);
  border-bottom: 1px solid #ccc;
  display: block;
  .box-content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: calc(15vw / 4.14);
    &_icon {
      margin-right: calc(10vw / 4.14);
      svg {
        width: calc(24vw / 4.14);
        height: calc(24vw / 4.14);
        vertical-align: middle;
      }
    }
  }
  .box-link {
    display: none;
  }
  .box-link_mobile {
    display: flex;
    justify-content: center;
    .facebook-icon {
      fill: #1877f2;
    }
    .twitter-icon {
      fill: #1b9df0;
      margin-left: 3rem;
    }
    .icon-disabled {
      fill: $gray-boder;
    }
  }

  @media #{$tablets} {
    padding: calc(30vw / 14) 0 calc(10vw / 14);
    display: flex;
    .box-content {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0px;
      &_icon {
        margin-right: calc(10vw / 14);
        svg {
          width: calc(24vw / 14);
          height: calc(24vw / 14);
          vertical-align: middle;
        }
      }
    }
    .box-link {
      display: flex;

      button.disabled {
        cursor: default !important;
      }

      .facebook-icon {
        fill: #1877f2;
      }

      .twitter-icon {
        fill: #1b9df0;
      }

      .icon-disabled {
        fill: $gray-boder;
      }
    }
    .box-link_mobile {
      display: none;
    }
  }
  @media #{$large-desktops} {
    padding: 3rem 0 1rem;
    .box-content {
      &_icon {
        margin-right: 1rem;
        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
}

.wrap-content1_avatar.box_delete {
  margin: calc(30vw / 4.14) auto 0;
  width: calc(270vw / 4.14);
  button{width: 100%;}
  @media #{$tablets} {
    margin: calc(30vw / 14) 0 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button{width: calc( 150vw / 14 );}
  }
  @media #{$large-desktops} {
    margin: 3em 0 0;
    button{width: 15rem;}
  }
}

.detail-avatar_wrap {
  position: relative;
  .wrap-icon {
    position: absolute;
    top: calc(10vw / 4.14);
    left: calc(10vw / 4.14);
    svg {
      width: calc(24vw / 4.14);
      height: calc(24vw / 4.14);
    }
  }

  @media #{$tablets} {
    .wrap-icon {
      top: calc(10vw / 14);
      left: calc(10vw / 14);
      svg {
        width: calc(24vw / 14);
        height: calc(24vw / 14);
      }
    }
    .wrap-content1 {
      display: flex;
      .wrap-render_avatar-detail {
        width: 40%;
        margin: 0;
      }
      .wrap-content1-box {
        width: 55%;
        margin-left: auto;
      }
    }
  }
  @media #{$large-desktops} {
    .wrap-icon {
      top: 1rem;
      left: 1rem;
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    .wrap-content1 {
      .wrap-render_avatar-detail {
        width: 44.5rem;
      }
      .wrap-content1-box {
        width: 63.5rem;
      }
    }
  }
}

.avator-edited {
  &-items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    a {
      display: block;
      width: 48.5%;
      margin-bottom: calc(15vw / 4.14);
      &:nth-child(even) {
        margin-left: auto;
      }
    }
  }
  @media #{$tablets} {
    &-items {
      a {
        width: 15.7%;
        margin-bottom: calc(15vw / 14);
        margin-right: 1%;
        &:nth-child(even) {
          margin-left: 0;
        }
        &:nth-child(6n) {
          margin-right: 0;
        }
      }
    }
  }
  @media #{$large-desktops} {
    &-items {
      a {
        width: 16rem;
        margin-bottom: 3rem;
        margin-right: 3rem;
      }
    }
  }
}

.detail-avatar {
  &__register {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    @media #{$tablets} {justify-content: flex-end;}
    &--spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    button {
      width: 15rem;
      height: 3.6rem;
      @media #{$mobile} {
        width: 28rem;
        height: 5rem;
      }
    }
  }
  .btn-check {
    margin-left: 5rem;
    button {
      width: 20rem;
      height: 3.6rem;
      @media #{$mobile} {
        height: 5rem;
        width: 27rem;
        margin-bottom: 2.8rem;
      }
    }
    @media #{$mobile} {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-left: 0;
    }
  }
  &__download-modal {
    padding-top: 2.4rem;
    @media #{$tablets} {
      padding-top: 3rem;
    }
    &--button {
      cursor: pointer;
      height: 5rem;
      border-radius: 6rem;
      font-size: $default-font-size;
      color: $white;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      &:hover {
        transform: translateY(0.5rem);
      }
      &__circle {
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        background-color: $white;
        margin-right: 3rem;
      }
    }
  }
  &__delete-modal {
    color: $body-text;
    &_header {
      display: flex;
      justify-content: center;
      position: relative;
      > div {
        font-weight: 700;
        text-align: center;
        flex: 1;
        font-size: 2rem;
        line-height: 1.5;
        max-width: calc(100% - 40px);

        @media #{$tablets} {
          font-size: 2.4rem;
        }
      }
      .mb {
        display: flex;
        justify-content: center;
        width: 100%;
        .mobile {
          @media screen and(max-width:375px) {
            text-align: center;
            font-size: 2.4rem;
            line-height: 3.6rem;
            width: 241px;
          }
        }
      }
      &-icon {
        position: absolute;
        top: -3rem;
        right: 0;
        line-height: initial !important;
        display: flex;
        cursor: pointer;
        @media #{$tablets} {
          top: 0;
        }
      }
      #box-upgrage,
      #box-upgrage-content {
        display: flex;
        justify-content: center;
      }
      #upgrage-title {
        @media #{$mobile} {
          width: 24.1rem;
        }
      }
    }
    &_content {
      font-size: 1.6rem;
      line-height: 1.75;
      text-align: center;
      border-top: 1px solid #ccc;
      white-space: pre-line;
      p,
      span {
        color: $body-text;
        font-size: 1.6rem;
        line-height: 1.75;
        @media screen and(max-width:375px) {
          width: 287px;
          display: inline-block;
          text-align: center;
        }
      }
      p:last-child {
        padding-bottom: 0;
      }
    }
    &_content2 {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
      line-height: 1.75;
      padding: 3rem 0;
      text-align: center;
      border-top: 1px solid #ccc;
      p {
        color: $body-text;
        font-size: 1.6rem;
        line-height: 1.9rem;
        padding-bottom: 0.8rem;
      }
      p:last-child {
        padding-bottom: 0;
      }
    }
    &_name {
      color: #111947;
      font-weight: bold;
      font-size: 2rem;
      line-height: 3.6rem;
      word-break: break-word;
    }
    &_button {
      display: flex;
      justify-content: center;
      &--spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      button {
        width: 15rem;
        height: 5rem;
        @media #{$tablets} {
          width: 24rem;
          height: 5rem;
        }
        &:not(:first-child) {
          margin-left: 1.5rem;
        }
      }

      .avatar-delete-button {
        background: $blue-main;
        color: $white;
      }
    }
  }
  &__nodata {
    background: #eeeeee;
    height: calc(200vw / 4.14);
    border-radius: calc(10vw / 4.14);
    margin-top: calc(10vw / 4.14);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &--wrap {
      width: 100%;
      text-align: center;
      svg {
        width: calc(30vw / 4.14);
        height: calc(30vw / 4.14);
      }
      &__text {
        font-weight: bold;
        text-align: center;
        color: #111947;
        font-size: calc(16vw / 4.14);
        margin-top: calc(10vw / 4.14);
      }
    }
    @media #{$tablets} {
      height: calc(200vw / 14);
      border-radius: calc(10vw / 14);
      margin-top: calc(10vw / 14);
      &--wrap {
        svg {
          width: calc(30vw / 14);
          height: calc(30vw / 14);
        }
        &__text {
          font-size: calc(16vw / 14);
          margin-top: calc(10vw / 14);
        }
      }
    }
    @media #{$large-desktops} {
      height: 20rem;
      border-radius: 1rem;
      margin-top: 1rem;
      &--wrap {
        svg {
          width: 3rem;
          height: 3rem;
        }
        &__text {
          font-size: 1.6rem;
          margin-top: 1rem;
        }
      }
    }
  }
}

.box-infor {
  margin-top: calc(30vw / 4.14);
  &_ttle {
    font-size: calc(20vw / 4.14);
    margin-bottom: calc(15vw / 4.14);
    color: $body-text;
    &.is-pc {
      display: none;
    }
  }
  &_content {
    margin-bottom: calc(30vw / 4.14);
    .box-avatar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: calc(30vw / 4.14) 0 calc(15vw / 4.14);
      border-bottom: 1px solid #ccc;
      > div {
        &:not(.box-text) {
          margin-right: calc(10vw / 4.14);
        }
        svg {
          width: calc(24vw / 4.14);
          height: calc(24vw / 4.14);
        }
        &.box-text {
          p {
            font-size: calc(16vw / 4.14);
          }
        }
      }
    }
  }
  @media #{$tablets} {
    margin-top: calc(30vw / 14);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    &_ttle {
      font-size: calc(20vw / 14);
      margin-bottom: calc(50vw / 14);
      &.is-sp {
        display: none;
      }
      &.is-pc {
        display: block;
      }
    }
    &_img {
      width: 15.7%;
    }
    &_content {
      margin-left: calc(30vw / 14);
      margin-bottom: 0;
      width: calc(660vw / 14);
      .box-avatar {
        padding: calc(30vw / 14) 0 calc(15vw / 14);
        border-bottom: 1px solid #ccc;
        > div {
          &:not(.box-text) {
            margin-right: calc(10vw / 14);
          }
          svg {
            width: calc(24vw / 14);
            height: calc(24vw / 14);
          }
          &.box-text {
            p {
              font-size: calc(16vw / 14);
            }
          }
        }
      }
    }
    .box-button {
      margin-left: auto;
    }
  }
  @media #{$large-desktops} {
    margin-top: 3rem;
    &_ttle {
      font-size: 2rem;
      margin-bottom: 5rem;
    }
    &_content {
      margin-left: 3rem;
      width: 66rem;
      .box-avatar {
        padding: 3rem 0 1.5rem;
        > div {
          &:not(.box-text) {
            margin-right: 1rem;
          }
          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
          &.box-text {
            p {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}

.wrap-content1 + .wrap_content2 {
  margin-top: calc(30vw / 4.14);
  @media #{$tablets} {
    margin-top: calc(30vw / 14);
  }
  @media #{$large-desktops} {
    margin-top: 3rem;
  }
}

.wrap_content2 {
  .box-content2_title {
    margin-bottom: calc(10vw / 4.14);
    @media #{$tablets} {
      margin-bottom: calc(10vw / 14);
    }
    @media #{$large-desktops} {
      margin-bottom: 1rem;
    }
  }
}

.box-notification {
  font-size: 1.6rem;
  line-height: 1.75;
  border-top: 1px solid #ccc;
  padding-top: 30px;
  white-space: pre-line;
  .download-message {
    text-align: center;
    > span {
      color: $sub-color;
      font-weight: bold;
    }
  }
  .download-notification {
    margin: 30px 0;
    padding: 20px 30px 20px 30px;
    background: $bg-color-1;
    font-size: 14px;
    &__title {
      font-weight: bold;
      padding: 0 10px 8px;
      border-bottom: 1px solid $gray-divider;
      margin-bottom: 10px;
      line-height: initial;
      @include d-flex-row-center;
      svg {
        margin-right: 10px;
      }
    }
    &__content {
      text-align: left;
      padding: 0;
      p {
        line-height: 26px;
      }
    }
  }
}

#upgrage-content {
  @media #{$mobile} {
    width: 29rem;
  }
}
#box-upgrage-content {
  display: flex;
  justify-content: center;
}
