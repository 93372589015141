// H2に関する
// H2からラインまでの間隔
.distance-h2-to-line {
  margin-bottom: calc( 10vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 10vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 1rem;
  }
}
// H1からラインまでの間隔
.distance-h1-to-line {
  margin-bottom: calc( 10vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 10vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 1rem;
  }
}
// H1からアイコンまでの間隔
.distance-h1-to-icon {
  margin-bottom: calc( 10vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 10vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 1rem;
  }
}
// H2からフォームまでの間隔
.distance-h2-to-form {
  margin-bottom: calc( 20vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 2rem;
  }
}
// H2からテキストまでの間隔
.distance-h2-to-text {
  margin-bottom: calc( 35vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 55vw / 14);
  }
  @media #{$large-desktops} {
	  margin-bottom: 5.5rem;
  }
}
// ラインからH2までの間隔
.distance-line-to-h2 {
  margin-bottom: calc( 40vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 40vw / 14);
  }
  @media #{$large-desktops} {
	  margin-bottom: 4rem;
  }
}
// H2からボタンまでの間隔
.distance-h2-to-btn {
  margin-bottom: calc( 20vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14);
  }
  @media #{$large-desktops} {
	  margin-bottom: 2rem;
  }
}
// H2モダルからラインまでの間隔
.distance-modal-h2-to-line {
  margin-bottom: calc( 15vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 15vw / 14);
  }
  @media #{$large-desktops} {
	  margin-bottom: 1.5rem;
  }
}
// khoang cach tu vien popup den h2
.padding-modal-in-h2 {
  padding: calc( 50vw / 4.14 ) calc(15vw / 4.14) calc(30vw / 4.14) calc(15vw / 4.14);
  @media #{$tablets} {
	  padding: calc( 30vw / 14);
  }
  @media #{$large-desktops} {
	  padding: 3rem;
  }
}
//Khoang cach tu h2 den dropdown
.distance-h1-to-dropdown {
  margin-bottom: calc( 20vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14);
  }
  @media #{$large-desktops} {
	  margin-bottom: 2rem;
  }
}


// H3に関する
// H3からテキストまでの間隔
.distance-h3-to-text {
  margin-bottom: calc( 10vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 10vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 1rem;
  }
}
// テキストからH3までの間隔
.distance-text-to-h3 {
  margin-top: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-top: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-top: 3rem;
  }
}
// ラインからH3までの間隔
.distance-line-to-h3 {
  margin-top: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-top: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-top: 3rem;
  }
  
}
// リンクからH3までの間隔
.distance-link-to-h3 {
  margin-bottom: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
  }
}
// Khoang cach img den h3
.distance-img-to-h3 {
  margin-bottom: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
  }
  
  
}
// Khoang cach h3 den content
.distance-h3-to-content {
  margin-bottom: calc( 10vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 10vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 1rem;
  }
}
// Margin chua text va line
.margin-modal-in-text {
  margin-top: calc( 5vw / 4.14 );
  margin-bottom: calc( 10vw / 4.14);
  @media #{$tablets} {
	  margin-top: calc( 5vw / 14 );
	  margin-bottom: calc( 10vw / 14);
  }
  @media #{$desktops} {
	  margin-top: .5rem;
	  margin-bottom: 1rem;
  }
  
}


// H4に関する
// ラインからH4までの間隔
.distance-line-to-h4 {
  margin-top: calc( 30vw / 4.14 );
  @media #{$tablets} {
	  margin-top: calc( 30vw / 14 );
  }
  @media #{$desktops} {
	  margin-top: 3rem;
	}
}
// H4からラインまでの間隔
.distance-h4-to-line {
  margin-bottom: calc( 15vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 15vw / 14 );
  }
  @media #{$desktops} {
	  margin-bottom: 1.5rem;
	}
}
// H4からテキストまでの間隔
.distance-h4-to-text {
  margin-bottom: calc( 5vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 5vw / 14 );
  }
  @media #{$desktops} {
	  margin-bottom: .5rem;
	}
}
// H4からコンテンツまでの間隔
.distance-h4-to-content {
  margin-bottom: calc( 10vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 10vw / 14 );
  }
  @media #{$desktops} {
	  margin-bottom: 1rem;
	} 
}
// H4からアイコンまでの間隔
.distance-h4-to-icon {
  margin-left: calc( 10vw / 4.14 );
  @media #{$tablets} {
	  margin-left: calc( 10vw / 14 );
  }
  @media #{$desktops} {
	  margin-left: 1rem;
	} 
}
//Khoảng cách từ h4 đến table
.distance-h4-to-table {
  margin-bottom: calc( 20vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14 );
  }
  @media #{$desktops} {
	  margin-bottom: 2rem;
	} 
  
}

// 入力エリアからのH4までの間隔
.distance-input-to-h4 {
  margin-bottom: calc( 30vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14 );
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
	} 
}


// divからH4までの間隔
.distance-divider-to-h4 {
  margin-top: calc( 30vw / 4.14 );
  @media #{$tablets} {
	  margin-top: calc( 30vw / 14 );
  }
  @media #{$desktops} {
	  margin-top: 3rem;
	} 
  
}
// khoảng cách topbox tới h4.
.distance-topbox-to-h4 {
	margin-top: calc( 20vw / 4.14 ) !important;
  @media #{$tablets} {
		margin-top: calc( 20vw / 14 ) !important
  }
  @media #{$desktops} {
		margin-top: 2rem !important;
  }
}
// khoảng cách h4 tới line.
.distance-h4-to-line {
	margin-top: calc( 15vw / 4.14 );
  @media #{$tablets} {
		margin-top: calc( 15vw / 14 ) 
  }
  @media #{$desktops} {
		margin-top: 1.5rem ;
  }
  
}
// khoảng cách h4 tới radio.
.distance-h4-to-radio {
  @media only screen and  #{$mobile} {
    padding-bottom: 15px;
  }
}

// Hタグに関してない
// コンテンツからボタンまでの間隔
.distance-content-to-button {
  margin-bottom: calc( 80vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 80vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 8rem;
  }
}
// テキストからテキストまでの間隔
.distance-text-to-text {
  margin-bottom: 30px;
}
// ラインからコンテンツまでの間隔
.distance-line-to-content {
  margin-top: calc( 20vw / 4.14);
  @media #{$tablets} {
	  margin-top: calc( 20vw / 14);
  }
  @media #{$desktops} {
	  margin-top: 2rem;
  }
  
}
// タブからコンテンツまでの間隔
.distance-tab-to-content {
  margin-top: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-top: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-top: 3rem;
  }
  
}
// テキストからラインまでの間隔
.distance-text-to-line {
  margin-bottom: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
  }
  
}
// ラインからテキストまでの間隔
.distance-line-to-text {
  padding-top: calc( 30vw / 4.14);
  @media #{$tablets} {
	  padding-top: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  padding-top: 3rem;
  }
}
// テキストからボタンまでの間隔
.distance-text-to-btn {
  margin-bottom: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
  }
}
//　入力エリアから入力エリアまでの間隔
.distance-input-to-input {
  margin-bottom: calc( 10vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 10vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 1rem;
  }
  
}
// フォームからチェックボックスまでの間隔
.distance-form-to-checkbox {
  margin-bottom: calc( 20vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 2rem;
  }
  
}
// フォームからテキストまでの間隔
.distance-form-to-text {
  margin-bottom: calc( 20vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 2rem;
  }
  
  
}
// チェックボックスからボタンまでの間隔
.distance-checkbox-to-btn {
  margin-bottom: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
  }
}
// フォームからリンクまでの間隔
.distance-form-to-link {
  margin-bottom: calc( 20vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 2rem;
  }
  
}
// テキストからコンテンツまでの間隔
.distance-text-to-noti {
  margin-bottom: calc( 20vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 2rem;
  }
  
}
// ボタンからラインまでの間隔
.distance-btn-to-line {
  margin-bottom: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
  }
  
  
}
// ボタンからテキストまでの間隔
.distance-btn-to-text {
  margin-bottom: calc( 30vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
  }
}
// divからコンテンツまでの間隔
.distance-divider-to-content {
  margin-bottom: calc( 20vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 20vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: 2rem;
  }
}
// ラベルからの入力エリアまでの間隔
.distance-label-to-input {
  margin-bottom: calc( 5vw / 4.14);
  @media #{$tablets} {
	  margin-bottom: calc( 5vw / 14);
  }
  @media #{$desktops} {
	  margin-bottom: .5rem;
  }
}
// khoang cach tu input den label
.distance-input-to-label {
  margin-top: 30px;
}
// 入力エリアからテキストまでの間隔
.distance-input-to-text {
  margin-bottom: 45px;
}
// 通知からフォームまでの間隔
.distance-noti-to-form {
  margin-bottom: 20px;
}
// 入力からボタンまでの間隔
.distance-input-to-btn {
  margin-bottom: 30px;
}
// ラインからモダルコンテンツまでの間隔
.distance-modal-line-to-content {
  margin-bottom: 30px;
}
// モダルコンテンツからボタンまでの間隔
.distance-modal-content-to-btn {
  margin-bottom: 30px;
}
// ラインコンテンツからボタンまでの間隔
.distance-line-content-to-btn {
  margin-top: 30px;
}
// khoảng cách top bottom element form
.distance-padding-input {
  padding-top: 30px;
  padding-bottom: 15px;
}
// padding chứa title và icon trong notication
.padding-title-noti {
	padding-top: calc( 20vw / 4.14 );
	padding-bottom: calc( 10vw / 4.14 );
	@media #{$tablets}{
		padding-top: calc( 20vw / 14 );
		padding-bottom: calc( 10vw / 14 );
	}
	@media #{$large-desktops}{
		padding-top: 2rem;
		padding-bottom: 1rem;
	}
}
// padding chứa nội dung trong notication
.padding-content-noti {
	padding-top: calc( 20vw / 4.14 );
	padding-bottom: calc( 20vw / 4.14 );
	@media #{$tablets}{
		padding-top: calc( 20vw / 14 );
		padding-bottom: calc( 20vw / 14 );
	}
	@media #{$large-desktops}{
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}
// khoang cach tu line den notification
.distance-line-to-noti {
  padding-top: 20px !important;
}
// Khoảng cách từ form đên button
.distance-form-to-btn {
  margin-bottom: 30px;
}
//Padding content top and bottom
.distance-padding-content {
  padding: 30px 0;
}
//Khoang cach tu border den text
.distance-border-to-infor {
  margin-top: 20px;
  margin-bottom: 20px;
}
//Padding button top and bottom
.distance-padding-btn {
  padding-bottom: 20px;
}
//Margin h2 top and bottom
.distance-margin-h2 {
  margin-top: 25px;
  margin-bottom: 15px;
}
//khoảng cách từ h2 đến label form
.distance-h2-to-label {
  margin-bottom: 55px;
}
// khoang cach tu h2 den top mobile
.distance-h1-to-top-mobile {
  @media #{$mobile} {
    padding-top: 10px;
  }
}
// Khoảng cách từ button đến footer
.distance-btn-to-footer {
  margin-bottom: 70px;
}
// Padding h4 đến line và content
.distance-padding-h4 {
  padding-bottom: 5px;
}
// Khoang cach  h4 đến border
.distance-h4-to-border {
  margin-bottom: 30px;
}
// Khoảng cách từ content đến box
.distance-content-to-box {
  margin-bottom: 20px;
}
// padding chứa dropdown trong form
.padding-dropdown-form {
  padding-top: 20px;
  padding-bottom: 20px;
}
// padding chứa nội dung trong form
.padding-content-form {
  padding: 20px;
}
// Padding button
.distance-margin-btn {
  margin: 30px 0;
}
// Margin h4
.distance-margin-h4 {
  margin-top: 30px;
  margin-bottom: 8px;
}
//padding from modal h2 to line
.padding-modal-h2-to-line {
  padding-bottom: 20px;
}
// khoang cach content modal den line
.padding-modal-content-to-line {
  padding-top: 30px;
}
// khoang cach content modal den button
.padding-modal-content-to-btn {
  padding-bottom: 30px;
}
// khoảng cách top đến ảnh
.distance-top-to-image {
  margin-top: 20px;
}
// khoảng cách từ ảnh đến text
.distance-image-to-text {
  margin-bottom: 10px;
}
//Margin img
.distance-margin-img {
  margin-top: 30px;
}
// khoảng cách từ btn tiny đến text
.distance-btn-tiny-to-text {
  margin-top: 10px;
}
// khoảng cách từ pulldown tới grid image
.distance-pulldown-to-image {
  margin-bottom: 10px;
}

// Padding modal h4
.distance-padding-modal-h4 {
  padding-top: 30px;
  padding-bottom: 8px;
}
// Distance margin modal title h2
.distance-margin-modal-h2 {
  margin-bottom: 15px !important;
}
// khoang cach tu text den tab
.distance-text-to-tab {
  padding-bottom: 36px;
}
// khoang cach tu tab den text
.distance-tab-to-text {
  padding-top: calc( 30vw / 4.14 );
  @media #{$tablets} {
	  padding-top: calc( 30vw / 14 );
  }
  @media #{$desktops} {
	  padding-top: 3rem;
  }
  
}
// khoang cach tu line den content
.distance-content-to-line {
  margin-bottom: calc( 30vw / 4.14 );
  @media #{$tablets} {
	  margin-bottom: calc( 30vw / 14 );
  }
  @media #{$desktops} {
	  margin-bottom: 3rem;
  }
}
// padding tu line den content
.padding-content-to-line {
  padding-bottom: calc( 30vw / 4.14 );
  @media #{$tablets} {
	  padding-bottom: calc( 30vw / 14 );
  }
  @media #{$desktops} {
	  padding-bottom: 3rem;
  }
}
// khoảng cách từ image đến h2
.distance-image-to-h2 {
  margin-bottom: 40px;
  @media only screen and  #{$mobile} {
    padding-bottom: 20px;
  }
}
// padding tu text den line
.padding-text-to-line {
  padding-bottom: 30px;
}
// Distance wrapper to line
.distance-wrapper-to-line {
  margin-bottom: 10px;
}
// ラインからH3までの間隔
.distance-line-to-h3-form {
  margin-top: 40px;
}
// khoảng cách từ notication tới button 
.distance-line-noti-to-btn {
  margin-bottom: 30px;
}
//Khoảng cách từ button đến content news
.distance-second-content-to-btn {
  margin-bottom: 60px;
}
// Khoảng khách từ label đến input
.distance-text-to-input {
  padding-bottom: 8px;
}
//Khoang cach tu noti card den noti card
.distance-noti-card-multiple {
  margin-bottom: 10px;
}
// Khoang cach tu line den table
.distance-line-to-table{
  margin-bottom: 30px;
}
// Padding confirm email content 
.padding-content-confirm-email{
  @media only screen and  #{$mobile} {
    padding: 20px 15px 30px 15px;
  }
}