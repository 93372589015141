.credit-text-input {
  $background-credit-input: #f1f3f8;
  &__label {
  }
  &__field {
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.641rem;
    height: 4rem;
    width: 100%;
    color: $body-text;
    border-width: 0;
    padding: 0;
    background-color: $background-credit-input;
    outline: none;
    border-radius: 0.5rem;
    padding-left: 2rem;
  }
  &__field::placeholder {
    color: $color-text-normal;
    text-transform: uppercase;
  }
}
