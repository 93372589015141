.active-mail {
  &__container {
    text-align: center;
    margin-bottom: calc(30vw / 4.14);
    @media #{$tablets} {
      margin-bottom: calc(30vw / 9.92);
    }
    @media #{$small-desktops} {
      margin-bottom: 3rem;
    }

    #h1 {
      @media screen and (max-width: 767px) {
        width: 292px;
      }
    }
  }
  &__title-err {
    color: $sub-color;
    padding-bottom: 5.5rem;
    @media #{$mobile} {
      padding-bottom: 5.5rem;
    }
  }

  &__content-err {
    //padding-bottom: 3rem;
    > p.active-message {
      font-size: 1.6rem;
      line-height: 1.75;
      color: #111947;
      font-weight: 400;
      align-items: center;
      vertical-align: middle;
    }
  }

  &__content-success {
    > p.active-message {
      font-size: 1.6rem;
      line-height: 1.75;
      color: #111947;
      font-weight: 400;
      align-items: center;
      vertical-align: middle;
    }
  }
  &__content {
    //max-width: 35rem;
    margin: 0 auto;
    @media #{$tablets} {
      max-width: 80rem;
    }
  }
  &__already-sent > span,
  &__not-received,
  p {
    color: $body-text;
    &.active-message {
      font-size: calc(16vw / 4.14);
      @media #{$tablets} {
        font-size: calc(16vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.6rem;
      }
    }
    font-size: calc(14vw / 4.14);
    line-height: 2;
    @media #{$tablets} {
      font-size: calc(14vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.4rem;
    }
  }
  &__not-received {
    max-width: 70rem;
    max-height: 34rem;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    background-color: #f9f9f9;
    @media #{$mobile} {
      width: 34.5rem;
      margin-right: 1rem;
      padding-left: 0rem;
    }
    .content {
      margin-left: 5rem;
      margin-right: 4rem;
      border-bottom: 1px solid $gray-divider;
      font-size: 1.4rem;
      line-height: 1.75;
      color: #111947;
      font-weight: 700;
      padding-left: 1.8rem;
      @media #{$mobile} {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 11px;
        margin-right: 11px;
        padding-left: 0rem;
      }
      span {
        margin-right: 1rem;
      }
    }
    .note {
      padding-left: 4.8rem;
      padding-right: 2rem;
      @media #{$mobile} {
        padding: 1rem 1rem 0 1rem;
      }
      .note1,
      .note2,
      .note3 {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.6rem;
        text-align: left;
        vertical-align: bottom;
      }
      .note1 {
        margin-bottom: 0;
      }
      .note3 {
        margin-top: 2rem;
      }
    }
  }
  &__already-sent {
    a {
      color: $blue-main;
    }
  }
  &__not-received {
    @media #{$tablets} {
    }
  }
  &__group-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  &__try-again {
    color: $body-text;
    background-color: $gray-divider;
  }
  &__back-to-login {
    color: $white;
    background-color: $blue-main;
    margin-top: 1rem;
    @media #{$tablets} {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
}

#exp-container {
  @media #{$mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #exp-title {
    line-height: 2.6rem;
    @media #{$mobile} {
      width: 29.6rem;
    }
  }
  #exp-content {
    line-height: 1.75;
  }
}
@media #{$mobile} {
  #active-title-email {
    text-align: left;
  }
  #active-warning-icon {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0.8rem;
  }
}

#active-warning-icon {
  .title-h4 {
    font-size: calc(14 / 4.14);
    @media #{$tablets} {
      font-size: calc(14vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.4rem;
    }
  }
}

.distance-p-to-p {
  margin-top: 20px;
}

.wrapper__button {
  margin-bottom: calc(50vw / 4.14);
  @media #{$tablets} {
    margin-bottom: calc(100vw / 9.92);
  }
  @media #{$small-desktops} {
    margin-bottom: 10rem;
  }
}
