@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p&display=swap');


footer{
  background-color: $background-header;
  margin-top: 3.2rem;
}
.footerC-wrap {
  padding: calc(60vw / 4.14) 0 calc( 120vw / 4.14 ) calc( 20vw / 4.14 );
  margin: auto;
  font-family: 'M PLUS 1p';
  @media #{$tablets} {
    padding: calc(120vw / 14) calc( 160vw / 14 );
  }
  @media #{$large-desktops} {
    padding: 12rem 16rem;
    max-width: 140rem;
  }
	span {
    color: $white;
    font-size: calc( 14vw / 4.14);
    line-height: 1.6;
    text-align: center;
    font-weight: normal;
    
    @media #{$tablets} {
	    font-size: calc(14vw / 14);
    }
	  @media #{$large-desktops} {
	    font-size: 1.4rem;
	  }
    
	}	  
	&__content {
		&--logo{
			svg,img{
				cursor: pointer;
				width: calc(186vw / 4.14);
				height: calc(40vw / 4.14);
				@media #{$tablets} {
					width: calc(186vw / 14);
					height: calc(40vw / 14);
				}
				@media #{$large-desktops} {
					width: 23.5rem;
					height: 4rem;
				}
			}
		}
		&--wrap {
      @media #{$tablets} {
        display: flex;
        justify-content: space-between;
      }
		}
    &--linkApp {
      @media #{$tablets} {
        display: flex;
        flex-direction: column;
      }
    }
    &--detail {
	    padding: calc( 20vw / 4.14 ) calc(20vw / 4.14) calc(10vw / 4.41) 0;
      @media #{$tablets} {
        padding: calc( 20vw / 14 ) 0;
      }
    }
    &--company {
      padding-bottom: calc( 20vw / 4.41);
      @media #{$tablets} {
	      padding-bottom: 0;
      }
    }
    &--link {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media #{$tablets} {
        justify-content: flex-end;
        max-width: 70rem;
      }
      .text-link {
        color: #FFF;
        margin: 0 calc( 14vw / 4.41) calc( 15vw / 4.41 ) 0;
        &:hover {
          color: #FFF;
        }
        @media #{$tablets} {
	        margin: 0 0 calc(15vw / 14 ) calc(15vw / 14 );
        }
        @media #{$large-desktops} {
	        margin: 0 0 1.5rem 1.5rem;
        }
        
      }
    }
    &--app {
      display: flex;
      padding-top: calc(5vw / 4.14);
      padding-bottom: calc(5vw / 4.14);
      @media #{$tablets} {
        display: flex;
        justify-content: flex-end;
	      padding-top: calc(5vw / 14);
	      padding-bottom: calc(5vw / 14);
      }
      @media #{$large-desktops} {
	      padding: .5rem 0 0.5rem;
      }
      
      &_store {
        margin-right: calc( 15vw / 4.41 );
        svg{
	        width: calc( 128vw / 4.14 );
	        height: calc( 40vw / 4.14 );
        }
        @media #{$tablets} {
	        margin-right: calc( 15vw / 14 );
	        svg{
		        width: calc( 128vw / 14 );
		        height: calc( 40vw / 14 );
	        }
	        &:hover {
	          cursor: pointer;
	          svg > path:first-child {
	            fill: $badge-background-hover;
	          }
	        }
        }
        @media #{$large-desktops} {
	        margin-right: 1.5rem;
	        svg{
		        width: 12.8rem;
		        height: 4rem;
	        }
        }
      }
      &_play {
        svg{
	        width: calc( 128vw / 4.14 );
	        height: calc( 40vw / 4.14 );
        }
        @media #{$tablets} {
	        svg{
		        width: calc( 128vw / 14 );
		        height: calc( 40vw / 14 );
	        }
	        &:hover {
	          cursor: pointer;
	          svg > path:first-child {
	            fill: $badge-background-hover;
	          }
	        }
				}
        @media #{$large-desktops} {
	        svg{
		        width: 12.8rem;
		        height: 4rem;
	        }
        }
				
      }
    }
    &--app_attention {
      display: flex;
      padding-top: calc(5vw / 4.14);
      padding-bottom: calc(30vw / 4.14);
      @media #{$tablets} {
        display: flex;
        justify-content: flex-end;
	      padding-top: calc(5vw / 14);
	      padding-bottom: calc(30vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.4rem;
	      padding: .5rem 0 5rem;
      }
      a {
        margin: 0;
        @media  #{$tablets} {
          margin: 0;
        }
        @media #{$large-desktops} {
          margin: 0;
        }
      }
    }
	}
  &__content2 {
    font-family: 'Montserrat';
    span {
      color: #ffffff;
      font-size: calc( 12vw / 4.14 );
      line-height: 1.4;
      opacity: 0.6;
      @media #{$tablets} {
	      font-size: calc( 12vw / 14 );
      }
		  @media #{$large-desktops} {
		    font-size: 1.2rem;
		  }
    }
  }
}
