@import './style/global-styles';
.Toastify__toast-container--top-right {
  top: 65px !important;
  .Toastify__progress-bar--default {
    background: #12c4c4 !important;
  }
  .Toastify__toast-body {
    white-space: pre-line;
  }
  @media #{$mobile} {
    top: 1em !important;
  }
}
