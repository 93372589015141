.manage-credit {
  $credit-bar-background: #f5f5f5;
  $credit-text-input: #f1f3f8;
  $credit-bar-background-active: #f0ffff;
  &__container {
    box-sizing: border-box;
    color: $body-text;
  }
  &__selectBox{
    @media #{$small-desktops} {
		  width: 54rem;
		  margin: 0 auto;
	  }
  }
  &__select-description {
	  line-height: 1.75;
	  font-size: calc( 16vw / 4.14 );
	  margin-bottom: calc( 20vw / 4.14 );
    @media #{$tablets} {
		  font-size: calc( 16vw / 14 );
		  margin-bottom: calc( 20vw / 14 );
    }
    @media #{$small-desktops} {
		  font-size: 1.6rem;
		  margin-bottom: 2rem;
    }
	  
  }
  &__select-bars {
	  margin-bottom: calc( 20vw/ 4.14 );
    &--text {
      font-size: calc( 14vw / 4.14 );
      line-height: 1.75;
      border-radius: calc( 50vw / 4.14 );
      height: calc( 50vw / 4.14 );
      margin-top: calc( 15vw / 4.14 );
    }
    &--textbox {
      background-color: #f5f5f5;
      height: calc( 50vw / 4.14 );
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media #{$tablets} {
		  margin-bottom: calc( 20vw/ 14 );
	    &--text {
	      font-size: calc( 14vw / 14 );
	      line-height: 1.75;
	      border-radius: calc( 50vw / 14 );
	      height: calc( 50vw / 14 );
	      margin-top: calc( 15vw / 14 );
	    }
	    &--textbox {
	      height: calc( 50vw / 14 );
	    }
    }
    @media #{$small-desktops} {
		  margin-bottom: 2rem;
	    &--text {
	      font-size: 1.4rem;
	      border-radius: 5rem;
	      height: 5rem;
	      margin-top: 1.5rem;
	    }
	    &--textbox {
	      height: 5rem;
	    }
    }
  }
  &__select-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    background-color: $credit-bar-background;
    border-radius: calc( 5vw / 4.14 );
    font-size: calc( 16vw / 4.14 );
    line-height: 1.75;
    padding: calc( 15vw / 4.14) calc( 20vw / 4.14 );
    @media #{$tablets} {
	    display: flex;
	    flex-direction: row;
	    justify-content: flex-start;
	    border-radius: calc( 5vw / 14 );
	    font-size: calc( 16vw / 14 );
	    padding: calc( 15vw / 14) calc( 20vw / 14 );
    }
    @media #{$small-desktops} {
	    border-radius: .5rem;
	    font-size: 1.6rem;
	    padding: 1.5rem 2rem;
    }
    
    
  }
  &__select-bar +&__select-bar {
	  margin-top: calc( 20vw / 4.14 );
    @media #{$tablets} {
		  margin-top: calc( 20vw / 14 );
    }
    @media #{$small-desktops} {
		  margin-top: 2rem;
    }
    
	 }
  
  &__select-couple {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: calc( 40vw / 4.14 );
    .card_default-active {
	    order: 3;
      color: #12c4c4;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc( 10vw / 4.14 );
      line-height: 1;
      height: calc( 24vw / 4.14 );
      width: calc( 105vw / 4.14 );
      border: 1px solid #12c4c4;
      border-radius: 6rem;
    }
    span{
	    order: 1;
    }
    label {
	    order: 2;
      font-size: calc( 16vw / 4.14 );
      margin-right: calc( 10vw / 4.14 );
    }
    .input__radio {
      opacity: 0;
      width: calc( 24vw / 4.14 );
      height: calc( 24vw / 4.14 );
      position: absolute;
      z-index: 2;
    }
    .radio__input {
	    position: absolute;
	    left: calc( 20vw / 4.14 );
	    top: 50%;
	    transform: translateY(-50%);
      display: flex;
      align-items: center;
      .radio__control {
        display: block;
        width: calc( 24vw / 4.14 );
        height: calc( 24vw / 4.14 );
        border-radius: 50%;
        border: 1px solid $blue-main;
      }
      input:checked + span {
        background: radial-gradient($blue-main 50%, rgba(255, 0, 0, 0) 51%);
      }
    }
    @media #{$tablets} {
	    padding-left: calc( 40vw / 14 );
	    .card_default-active {
	      font-size: calc( 10vw / 14 );
	      line-height: 1;
	      height: calc( 32vw / 14 );
	      width: calc( 150vw / 14 );
	      border-radius: calc( 16vw / 14 );
	    }
	    span{
		    order: 1;
	    }
	    label {
		    order: 2;
	      font-size: calc( 16vw / 14 );
	      margin-right: calc( 10vw / 14 );
	    }
		    
	    .input__radio {
	      width: calc( 24vw / 14 );
	      height: calc( 24vw / 14 );
	    }
	    .radio__input {
		    left: calc( 20vw / 14 );
	      .radio__control {
	        width: calc( 24vw / 14 );
	        height: calc( 24vw / 14 );
	      }
	    }
	    
    }
    @media #{$small-desktops} {
	    padding-left: 4rem;
	    .card_default-active {
	      font-size: 1rem;
	      line-height: 1;
	      height: 2.4rem;
	      width: 10.5rem;
	    }
	    label {
	      font-size: 1.6rem;
	      margin-right: 1rem;
	    }
	    .input__radio {
	      width: 2.4rem;
	      height: 2.4rem;
	    }
	    .radio__input {
		    left: 2rem;
	      .radio__control {
		      width: 2.4rem;
		      height: 2.4rem;
	      }
	    }
    }
    
  }
  &__select-series {
	  margin-top: calc( 5vw / 4.14 );
    padding-left: calc( 40vw / 4.14 );
    span {
	    font-size: calc( 16vw / 4.14 );
	    letter-spacing: calc( 3vw / 4.14 );
    }
    @media #{$tablets} {
	    margin-left: auto;
		  margin-top: 0;
	    padding-left: 0;
	    span {
		    font-size: calc( 16vw / 14 );
		    letter-spacing: calc( 3vw / 14 );
	    }
    }
    @media #{$small-desktops} {
		  margin-top: .5rem;
	    padding-left: 4rem;
	    span {
		    font-size: 1.6rem;
		    letter-spacing: .03rem;
	    }
    }
    
    
  }
  &__select-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    button {
				&:nth-child(even){margin-left: calc( 20vw / 4.14 );}
    }
    @media #{$tablets} {
	    button{
		    &:nth-child(even){margin-left: calc( 20vw / 14 );}
	    }
    }
    @media #{$small-desktops} {
	    justify-content: center;
	    button {
				&:nth-child(even){margin-left: 2rem;}
	    }
    }
    
    
  }
  
  &__register {
    border-top: 1px solid $gray-divider;
	  margin-top: calc( 30vw / 4.14 );
	  padding-top: calc( 30vw/ 4.14 );
    h2 {
      margin-bottom: calc( 15vw / 4.14 );
      font-size: calc( 18vw / 4.14 );
    }
    @media #{$tablets} {
	    border-top: 1px solid $gray-divider;
		  margin-top: calc( 30vw / 14 );
		  padding-top: calc( 30vw/ 14 );
	    h2 {
	      margin-bottom: calc( 15vw / 14 );
	      font-size: calc( 18vw / 14 );
	    }
    }
    @media #{$small-desktops} {
	    width: 54rem;
	    margin: 3rem auto 0;
		  padding-top: 3rem;
	    h2 {
	      margin-bottom: 1.5rem;
	      font-size: 1.8rem;
	    }
    }
  }
  
  &__register-content {
    @media #{$tablets} {
      display: flex;
      width: 100%;
      flex-direction: row;
    }
  }
  &__register-information {
	  >div + div{
		  margin-top: calc( 15vw / 4.14 );
	  }
	  .credit-text-input__label{
		  font-size: calc( 14vw / 4.14 );
		  margin-bottom: calc( 5vw / 4.14 );
		  font-weight: 700;
		  color: $body-text;
	  }
	  .credit-text-input__field{
		  height: calc( 40vw /4.14 );
		  font-size: calc( 16vw / 4.14 );
	  }
	  .form-field-error-message{
		  font-size: calc( 14vw / 4.14 );
	  }
    @media #{$tablets} {
	    width: 100%;
	    display: flex;
	    flex-wrap: wrap;
	    >div{
		    &:first-child{
			    flex-basis: 100%;
		    }
	    }
		  >div + div{
			  flex-basis: 48.5%;
			  width: 49%;
			  margin-top: calc( 15vw / 14 );
		  }
		   >div + div + div{
			   margin-left: auto;
		   }
		  .credit-text-input__label{
			  font-size: calc( 14vw / 14 );
			  margin-bottom: calc( 5vw / 14 );
		  }
		  .credit-text-input__field{
			  height: calc( 40vw / 14 );
			  font-size: calc( 16vw / 14 );
		  }
		  .form-field-error-message{
			  font-size: calc( 14vw / 14 );
		  }
    }
    @media #{$small-desktops} {
		  >div + div{
			  margin-top: 1.5rem;
		  }
		  .credit-text-input__label{
			  font-size: 1.4rem;
			  margin-bottom: .5rem;
		  }
		  .credit-text-input__field{
			  height: 4rem;
			  font-size: 1.6rem;
		  }
		  .form-field-error-message{
			  font-size: 1.4rem;
		  }
    }
  }
  
  &__register-button-area {
	  margin-top: calc( 30vw / 4.14 );
    display: flex;
    justify-content: center;
    > button {
    }
    @media #{$tablets} {
	  	margin-top: calc( 30vw / 14 );
    }
    @media #{$small-desktops} {
	  	margin-top: 3rem;
	    > button {
	    }
    }
    
    
  }
  
  
  &__title-area {
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
    padding-top: 2rem;
    border-bottom: 1px solid $gray-divider;
    @media #{$tablets} {
      padding-top: 3.5rem;
    }
  }
  &__title {
    padding-left: 1.5rem;
  }
  &__select-active {
    background-color: $credit-bar-background-active;
  }
  &__select-erase,
  &__select-edit {
    width: 12rem;
    @media #{$mobiles} {
      width: 16.5rem;
    }
    @media #{$desktops} {
      width: 16rem;
    }
  }
  &__select-erase {
    background-color: $gray-divider;
  }
  &__select-edit {
    background-color: $blue-main;
    color: $white;
    margin-left: 1.5rem;
  }
  &__register {
    @media #{$desktops} {
      display: flex;
      justify-content: center;
    }
    > h2 {
      padding-bottom: 1.5rem;
      @media #{$desktops} {
        padding-bottom: 4rem;
      }
    }
  }
  &__registerBox {
    @media #{$small-desktops} {
      width: 54rem;
      margin: auto;
    }
  }
  &__register-content {
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
    }
  }
  &__register-brands {
    border: 1px solid #d8d8d8;
    border-radius: 0.5rem;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
    @media #{$desktops} {
      padding-top: 2.4rem;
      padding-bottom: 7.5rem;
      width: 23rem;
      margin-right: 3rem;
    }
  }
  &__register-brands-description {
    font-size: 1.6rem;
    line-height: 1.875rem;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 2.6rem;
    @media #{$desktops} {
      padding-bottom: 2.2rem;
    }
  }
  &__register-brands-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &__register-brand {
    position: relative;
    width: 5.6rem;
    height: 4rem;
    border-radius: 0.667rem;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(2) {
      margin-left: 1.8rem;
    }
  }
  &__active {
    border-color: $blue-main;
    background-color: rgba(18, 196, 196, 0.05);
  }
  &__register-check-icon {
    position: absolute;
    top: -0.8rem;
    right: -0.8rem;
  }
}

.credit-modal {
  &__register {
    padding-top: 3rem;
    @media #{$desktops} {
      padding-bottom: 1.6rem;
    }
  }
  &__register-information {
    padding-top: 0;
  }
  &__register-content {
    padding-top: 0;
  }
}
.credit-text-input__wrapper{
  display: flex;
}