.edit-profile {
  box-sizing: border-box;
  &__sidebar-area {
    @media #{$tablets} {
      width: 20%;
    }
    @media #{$large-desktops} {
      width: 22rem;
    }
  }
  &__sidebar-area + .edit-profile__content {
    @media #{$tablets} {
      width: 75%;
      margin-left: auto;
    }
    @media #{$large-desktops} {
      width: 85rem;
    }
  }
  &__choose-area {
    background-color: #f7f7f7;
  }
  &__choose {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(10vw / 4.14);
    height: calc(280vw / 4.14);
    @media #{$tablets} {
      margin-bottom: calc(10vw / 14);
      height: calc(280vw / 14);
    }
    @media #{$large-desktops} {
      margin-bottom: 1rem;
      height: 28rem;
    }
    img {
      height: calc(160vw / 4.14);
      width: calc(160vw / 4.14);
      border-radius: 50%;
      object-fit: cover;
      @media #{$tablets} {
        height: calc(160vw / 14);
        width: calc(160vw / 14);
      }
      @media #{$large-desktops} {
        height: 16rem;
        width: 16rem;
      }
    }
  }
  &__choose-button {
    width: calc(160vw / 4.14);
    min-width: auto;
    height: calc(40vw / 4.14);
    color: $white;
    background-color: $blue-main;
    cursor: pointer;
    margin-top: calc(20vw / 4.14);
    text-align: center;
    @media #{$tablets} {
      max-width: calc(160vw / 14);
      height: calc(40vw / 14);
      border-radius: calc(20vw / 14);
      margin-top: calc(20vw / 14);
    }
    @media #{$large-desktops} {
      max-width: 16rem;
      height: 4rem;
      border-radius: 2rem;
      margin-top: 2rem;
    }
  }
  &__content {
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
    &-describe {
      padding: calc(10vw / 4.14) calc(10vw / 4.14) 0;
      p {
        font-size: calc(14vw / 4.14);
        line-height: 1.5;
        color: #727488;
      }
      @media #{$tablets} {
        padding: calc(10vw / 14) calc(10vw / 14) 0;
        p {
          font-size: calc(14vw / 14);
        }
      }
      @media #{$large-desktops} {
        padding: 1rem 1rem 0;
        p {
          font-size: 1.4rem;
        }
      }
    }
  }
  &__description {
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $gray-divider;
    @media #{$tablets} {
      margin-right: 2.8rem;
    }
  }
  &__choose-area {
    background-color: #f7f7f7;
  }
  &__edit-avatar-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    background-color: $white;
    border-radius: 1.5rem;
    padding: 5rem 1.5rem 3rem 1.5rem;
    outline: none;
    @media #{$mobiles} {
      width: 92vw;
    }
    @media #{$tablets} {
      width: 60rem;
      padding: 2.4rem 3rem 3rem 3rem;
    }
    &__header {
      text-align: center;
      border-bottom: 1px solid $gray-divider;
      display: flex;
      justify-content: center;
    }
    &__close {
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      @media #{$tablets} {
        top: 2.4rem;
        right: 3rem;
      }
    }
    &__title {
      color: $body-text;
      font-size: 2rem;
      line-height: 1.5;
      font-weight: 700;
      max-width: 20rem;
      @media #{$tablets} {
        font-size: 2.4rem;
        font-weight: 700;
        max-width: 100%;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3rem;
      .error-size-image {
        font-size: 15px;
        color: red;
        min-height: 30px;
        display: flex;
        width: 410px;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }
    }
    &__image-area {
      position: relative;
      overflow: hidden;
      &__icon {
        position: absolute;
        z-index: 999;
        cursor: pointer;
        left: 10px;
        top: 10px;
      }
      &__label {
        position: absolute;
        top: 0;
        left: 20px;
        font-size: 1.25em;
        font-weight: 700;
        color: black;
        display: flex;
        font-family: sans-serif;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100% - 40px);
        height: 100%;
        text-align: center;
        line-height: initial;
        white-space: pre-line;

        .error-image {
          color: red;
          text-align: center;
          margin-top: 30px;
          width: 100%;
          line-height: initial;
          font-size: 15px;
          white-space: pre-line;
        }
      }

      #image-helper {
        height: 404px;
        width: 414px;
        border: 2px dashed rgb(151, 151, 151);
        border-radius: 8px;
        text-align: center;
        background: rgb(255, 255, 255);
        overflow: hidden;
      }
      #files {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
    }
    &__describe-area {
    }
    &__describe {
      position: relative;
      margin: auto;
      white-space: pre-line;
      span {
        position: absolute;
        right: calc(100% + 5px);
      }
      @media #{$mobile} {
        padding-left: 25px;
        span {
          right: initial;
          left: 0;
        }
      }
    }
    &__group-buttons {
      display: flex;
      flex-direction: row;
      max-width: 100%;
      > button {
        width: 15rem;
        height: 5rem;
        @media #{$tablets} {
          width: 24rem;
        }
      }
      > button:last-child {
        margin-left: 1.5rem;
      }
    }
  }
  &__change-area {
    width: 100%;
    @media #{$tablets} {
    }
  }
  &__name-field {
    padding-top: calc(30vw / 4.14);
    padding-bottom: calc(15vw / 4.14);
    border-bottom: 1px solid $gray-divider;
    @media #{$tablets} {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: calc(15vw / 14);
      padding-top: 0;
    }
    @media #{$small-desktops} {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    > h4 {
      @media #{$small-desktops} {
        min-width: 10rem;
      }
    }
    p {
      @media #{$tablets} {
        width: calc(140vw / 14);
        margin-right: calc(20vw / 14);
      }
      @media #{$large-desktops} {
        margin-right: 2rem;
        width: 14rem;
      }
    }
  }
  &__name-label {
    font-size: calc(14vw / 4.14);
    font-weight: 600;
    color: $body-text;
    @media #{$tablets} {
      margin-right: calc(10vw / 14);
      font-size: calc(14vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.4rem;
      margin-right: 1rem;
    }
  }
  &__name-input {
    margin-top: calc(8vw / 4.14);
    .input {
      input {
        height: calc(40vw / 4.14) !important;
        background: #f1f3f8;
        border: none !important;
        font-size: calc(16vw / 4.14);
        font-weight: 400;
        width: 100%;
      }
    }
    @media #{$tablets} {
      margin-top: 0;
      .input {
        input {
          width: calc(550vw / 14);
          height: calc(40vw / 14) !important;
          font-size: calc(16vw / 14);
        }
      }
    }
    @media #{$large-desktops} {
      .input {
        input {
          width: 55rem;
          height: 4rem;
          font-size: 1.6rem;
        }
      }
    }
  }
  &__privacy-setting {
    padding-top: calc(30vw / 4.14);
    font-size: calc(16vw / 4.14);
    font-weight: 600;
    color: $body-text;
    @media #{$tablets} {
      padding-top: calc(30vw / 14);
      font-size: calc(16vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 3rem;
      font-size: 1.6rem;
    }
  }
  &__radio-profile {
    border-bottom: 1px solid $gray-divider;
  }
  &__radio-registered-avatar {
    border-bottom: 1px solid $gray-divider;
  }
  &__resolved-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    > button {
      margin: 0 0.5rem;
    }
    @media #{$tablets} {
      margin-top: 3rem;
      margin-bottom: 2.8rem;
    }
  }
  &__cancel-button {
    color: $body-text;
    background-color: $gray-divider;
  }
  &__save {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.5rem;
    &--spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media #{$tablets} {
      margin-left: 1.5rem;
    }
  }
  &__save-button {
    color: $white;
    background-color: $blue-main;
  }
  .radio-group {
    padding-bottom: calc(15vw / 4.14);
    padding-top: calc(30vw / 4.14);
    border-bottom: 1px solid $gray-divider;
    &__title {
      padding-bottom: calc(10vw / 4.14);
      font-weight: 700;
      font-size: calc(14vw / 4.14);
      color: $body-text;
      @media #{$tablets} {
        padding-bottom: 0;
        width: calc(140vw / 14);
        margin-right: calc(20vw / 14);
        font-size: calc(14vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.4rem;
        margin-right: 2rem;
        width: 14rem;
      }
    }
    &__selection {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 508.8px;
      @media #{$tablets} {
        min-width: 60%;
        flex-direction: row;
      }
    }
    &__radio-couple {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 10px;
      &:last-child {
        padding-bottom: 0;
      }

      .input {
        margin-bottom: 0;
      }
      input[type='radio'] {
        width: calc(24vw / 4.14);
        height: calc(24vw / 4.14);
        margin: 0;
        @media #{$tablets} {
          width: calc(24vw / 14);
          height: calc(24vw / 14);
        }
        @media #{$large-desktops} {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
      &.disable {
        .radio-group__radio-label {
          color: $gray-boder;
        }
      }
      @media #{$tablets} {
        padding-bottom: 0;
      }
    }
    &__radio-label {
      font-size: calc(14vw / 4.14);
      line-height: 1.5;
      color: $body-text;
      padding-left: calc(4vw / 4.14);
      @media #{$tablets} {
        font-size: calc(14vw / 14);
        padding-left: calc(4vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.4rem;
        padding-left: 0.4rem;
      }
    }
    @media #{$tablets} {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: calc(30vw / 14) 0 calc(15vw / 14);
    }
    @media #{$large-desktops} {
      display: flex;
      flex-direction: row;
      padding-top: 3rem;
      padding-bottom: 1.5rem;
    }
  }
  .hide {
    display: none;
  }
  .form-field-error-message {
    width: 100%;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.resolved-button {
  @media #{$tablets} {
  }
}
