.password__modal-success {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  padding: 1rem 1.5rem 4rem 1.5rem;
  width: 90vw;
  @media #{$tablets} {
    padding: 3.1rem 5.2rem 4.5rem 5.2rem;
    width: 62.3rem;
  }
  background-color: #fff;
  border-radius: 1rem;
  transform: translate(-50%, -50%);
  outline: transparent;
  &__header {
    display: flex;
    justify-content: flex-end;
    &-modal {
      cursor: pointer;
    }
  }
  &__title {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 4.8rem;
    color: #111947;
    margin-bottom: 1.6rem;
  }
  &__content {
    text-align: center;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.344rem;
    color: $color-text-normal;
    margin-bottom: 5.3rem;
    vertical-align: center;
  }
  &__send {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    &--spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    button {
      width: 24rem;
      height: 5rem;
      @media #{$mobiles} {
        width: 34.5rem;
        height: 5rem;
      }
      @media #{$large-mobiles} {
        width: 24rem;
        height: 5rem;
      }
    }
  }
}
