.wrap-render_avatar {
  p {
    margin: 0;
  }
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  .wrap-icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  .wrap-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    font-weight: bold;
    //font-size: 1.4rem;
    //line-height: 1.6rem;
    //border-radius: 1rem;
    font-size: 14px;
    line-height: 16px;
    border-radius: 10px;
    word-break: break-word;
    margin-right: 30px;
    margin-bottom: 30px;
  }
  
  .content2 {
    display: none;
  }
  &:hover {
    .wrap-content {
      background: #06083c;
      opacity: 0.8;
      transition: all 0.25s ease-in;
    }
    .content2 {
      display: block;
      margin: 0 2rem;
      width: 100%;
      .date,
      .avatar {
        max-width: 100%;
        display: flex;
        border-bottom: 1px solid #6c6e99;
        padding-bottom: 0.6rem;
        min-height: 2.8rem;
      }
      .avatar {
        margin-top: 2.4rem;
      }
      .text-date,
      .text-avatar {
        color: #fff !important;
        margin-left: 1rem;
      }
    }
  }
  @media #{$mobile} {
    border-radius: 0.6rem;
    .wrap-content {
      border-radius: 0.6rem;
    }
    &:hover {
      .content2 {
        margin: 0 1rem;
      }
    }
  }
}
