// Manage Creadit Screen
.ManageCreadit {
  display: flex;
  flex-direction: column;
  .ManageCreadit__info--creadit {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid $gray-divider;
    .title {
      font-size: 2.4rem;
      color: #111947;
      font-weight: bold;
      line-height: 2.813rem;
      margin-left: 1.267rem;
    }
  }
  .ManageCreadit__info--cardRegistered {
    margin-top: 3rem;
    border-bottom: 1px solid $gray-divider;
    .ManageCreadit__info--cardRegistered_infoVisa {
      margin-top: 1.6rem;
      @media #{$mobile} {
        display: grid;
        grid-row-gap: 3rem;
      }
      @media #{$small-desktops} {
        display: flex;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        display: grid;
        grid-row-gap: 3rem;
      }
      @media screen and (min-width: 1445px) {
        display: flex;
      }
      .one,
      .two {
        display: flex;
        align-items: center;
        padding: 1.3rem 8.7rem 1.3rem 1.325rem;
        background-color: #f7f7f7;
        border-radius: 0.5rem;
        font-weight: 400;
        line-height: 1.829rem;
        height: 5rem;
        @media #{mobiles} {
          font-size: 1.4rem;
        }
        @media #{$large-desktops} {
          width: 100%;
          font-size: 1.6rem;
        }
        @media screen and (min-width: 320px) and (max-width: 374px) {
          padding: 1.3rem 1rem 1.3rem 1.325rem;
        }
        .icon {
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 50%;
          border: 1px solid $blue-main;
          margin-right: 2.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            width: 1rem;
            height: 1rem;
            background-color: $blue-main;
            padding: 0.4rem;
            border-radius: 50%;
            display: block;
          }
        }
        .visa {
          margin-right: 1.3rem;
        }
      }
      .one {
        @media #{$small-desktops} {
          margin-right: 3rem;
        }
      }
    }
    .ManageCreadit__info--cardRegistered_btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
      @media #{$mobiles} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @media #{$small-desktops} {
        flex-direction: row;
        justify-content: flex-end;
      }
      @media screen and (min-width: 320px) and (max-width: 374px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      @media #{$tablets} {
        flex-direction: row;
        justify-content: flex-end;
      }
      .Erase,
      .edit {
        border: none;
        outline: none;
        height: 5rem;
        border-radius: 10rem;
        @media #{$mobiles} {
          width: 70%;
          margin-bottom: 1rem;
        }
        @media #{$tablets} {
          width: 16rem;
          height: 5rem;
        }
        @media #{$small-desktops} {
          width: 16rem;
          height: 5rem;
          margin-bottom: 3rem;
        }
        @media screen and (min-width: 320px) and (max-width: 374px) {
          width: 70%;
          margin-bottom: 1rem;
        }
      }
      .Erase {
        @media #{$small-desktops} {
          margin-right: 1rem;
        }
        @media #{$tablets} {
          margin-right: 1rem;
        }
      }
    }
  }
  .ManageCreadit__info--RegisterNewCreadit {
    margin-top: 3rem;
    .RegisterNewCreadit-title {
      font-size: 2rem;
      font-weight: bold;
      color: #111947;
      line-height: 2.344rem;
      margin-bottom: 4rem;
    }
    .content {
      @media #{$mobiles} {
        display: block;
      }
      @media #{$small-desktops} {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .content-left {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px solid #d8d8d8;
      border-radius: 0.5rem;
      @media #{$mobiles} {
        width: 100%;
        height: 18rem;
      }
      @media #{$small-desktops} {
        width: 23rem;
        height: 18rem;
      }
      @media screen and (min-width: 320px) and (max-width: 374px) {
        width: 100%;
        height: 100%;
        margin-bottom: 2rem;
      }
      &__title {
        font-size: 1.6rem;
        font-weight: bold;
        color: #111947;
        line-height: 1.875rem;
        vertical-align: middle;
      }
      .cardItem {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1.8rem;
        grid-row-gap: 0.5rem;
        margin-top: 2.2rem;
        @media screen and (min-width: 320px) and (max-width: 374px) {
          margin-top: 1rem;
        }
        .active {
          position: relative;
          .iconSelect {
            position: absolute;
            top: 10;
            right: 0;
            display: block;
            content: '';
          }
        }
      }
    }
    .content-right {
      display: grid;
      grid-column-gap: 3rem;
      grid-row-gap: 3.4rem;
      text-align: left;
      vertical-align: middle;
      @media #{$mobiles} {
        grid-template-columns: 1fr;
        margin-top: 3rem;
      }
      @media #{$small-desktops} {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        margin-top: 0;
        margin-left: 3rem;
      }
      .item {
        .label {
          font-size: 1.6rem;
          color: #111947;
          font-weight: bold;
          margin-bottom: 1.2rem;
          .red {
            margin-left: 0.8rem;
            color: #ec0606;
          }
        }
        .content-item {
          padding: 0.9rem 0 0.8rem 2.4rem;
          background-color: #f1f3f8;
          height: 4rem;
          vertical-align: middle;
          line-height: 2.24rem;
          border-radius: 0.5rem;
        }
      }
    }
    .addto {
      text-align: right;
      margin-top: 2.1rem;
      .btn {
        height: 5rem;
        padding: 0 6rem;
        color: #fff;
        background-color: $blue-main;
        border: none;
        outline: none;
        border-radius: 10rem;
      }
      @media #{$mobiles} {
        text-align: center;
      }
      @media #{$small-desktops} {
        text-align: right;
      }
    }
  }
}

.active {
  background-color: #f0ffff;
}
