.wrap-friend {
  &_header {
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 3.5rem;
    @media #{$mobile} {
      padding-top: 1rem;
    }
    h1,
    h3 {
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $body-text;
    }
    h1 {
      @media #{$mobile} {
        font-size: 2.2rem;
      }
    }
    h3 {
      margin-left: 1rem;
    }
  }
  &_bar {
    display: flex;
    margin-top: calc(20vw / 4.14) 0 calc(30vw / 4.14);
    align-items: center;
    flex-direction: column;
    &-options {
      flex: 1;
      width: 100%;
      .friend-options {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: calc(20vw / 4.14);
        &--item {
          border-radius: calc(5vw / 4.14);
          font-weight: 500;
          width: 32%;
          font-size: calc(14vw / 4.14);
          color: #707591;
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(40vw / 4.14);
          cursor: pointer;
          @include transition;
          border: 1px solid $color-text-normal;
          &.active_options {
            border-bottom-color: $blue-main;
            color: $blue-main;
            white-space: nowrap;
            background-color: $blue-main;
            color: #fff;
            border: none;
          }
          &_count {
            padding-left: 0.5rem;
            @media #{$mobile} {
              display: none;
            }
          }
        }
      }
    }
    &-search {
      height: calc(40vw / 4.14);
      background: #f1f3f8;
      border-radius: calc(5vw / 4.14);
      flex: 1;
      width: 100%;
      .search-input {
        position: relative;
        &_icon {
          position: absolute;
          top: calc(10vw / 4.14);
          left: calc(10vw / 4.14);
          svg {
            width: calc(20vw / 4.14);
            height: auto;
          }
        }
        input {
          font-size: calc(16vw / 4.14);
          margin-left: calc(40vw / 4.14);
          width: calc(100% - (40vw / 4.14));
          height: calc(40vw / 4.14);
          border: none;
          outline: none;
          padding-right: calc(20vw / 4.14);
          background-color: transparent;
          &::placeholder {
            color: #aaaaaa;
          }
        }
      }
    }
  }
  &_bar + .text-center {
    padding: calc(100vw / 4.14) 0 calc(100vw / 4.14);
    font-size: calc(16vw / 4.14);
  }
  &_content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: calc(30vw / 4.14);
    grid-row-gap: calc(20vw / 4.14);
    margin-bottom: calc(30vw / 4.14);
    @media #{$small-desktop} {
      grid-template-columns: 1fr 1fr;
    }
    @media #{$mobile} {
      grid-template-columns: 1fr;
      .box-friend {
        width: 100%;
      }
    }
  }
  @media #{$tablets} {
    &_bar {
      display: flex;
      margin-top: calc(20vw / 14) 0 calc(30vw / 14);
      flex-direction: row;
      &-options {
        flex: 1;
        width: auto;
        .friend-options {
          display: flex;
          justify-content: flex-start;
          width: auto;
          margin-bottom: 0;
          &--item {
            border-radius: 0;
            background: none;
            border: none;
            padding: 0 calc(10vw / 14);
            color: $body-text;
            width: auto;
            font-size: calc(18vw / 14);
            height: calc(40vw / 14);
            cursor: pointer;
            @include transition;
            border-bottom: calc(4vw / 14) solid transparent;
            &.active_options {
              border-bottom: calc(4vw / 14) solid $blue-main;
              color: $blue-main;
              white-space: nowrap;
              background: none;
            }
            &_count {
              padding-left: 0.5rem;
            }
          }
        }
      }
      &-search {
        height: calc(40vw / 14);
        border-radius: calc(5vw / 14);
        flex: 1;
        width: 100%;
        .search-input {
          position: relative;
          &_icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(10vw / 14);
            svg {
              width: calc(20vw / 14);
              vertical-align: middle;
              height: auto;
            }
          }
          input {
            font-size: calc(16vw / 14);
            margin-left: calc(40vw / 14);
            width: calc(100% - (40vw / 14));
            height: calc(40vw / 14);
            border: none;
            outline: none;
            padding-right: calc(20vw / 14);
            background-color: transparent;
            &::placeholder {
              color: #aaaaaa;
            }
          }
        }
      }
    }
    &_bar + .text-center {
      padding: calc(200vw / 14) 0 calc(200vw / 14);
      font-size: calc(16vw / 14);
    }
    &_content {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: calc(30vw / 14);
      grid-row-gap: calc(20vw / 14);
      margin-bottom: calc(30vw / 14);
    }
  }
  @media #{$large-desktops} {
    &_bar {
      margin-top: 2rem 0 3rem;
      &-options {
        .friend-options {
          margin-bottom: 0;
          &--item {
            padding: 0 20px;
            font-size: 1.8rem;
            height: 4rem;
            &.active_options {
              border-bottom: 0.4rem solid $blue-main;
            }
            &_count {
              padding-left: 0.5rem;
            }
          }
        }
      }
      &-search {
        height: 4rem;
        border-radius: 0.5rem;
        .search-input {
          &_icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 1rem;
            svg {
              width: 2rem;
            }
          }
          input {
            font-size: 1.6rem;
            margin-left: 4rem;
            width: calc(100% - 4rem);
            height: 4rem;
            padding-right: 2rem;
          }
        }
      }
    }
    &_bar + .text-center {
      padding: 20rem 0 20rem;
      font-size: 1.6rem;
    }
    &_content {
      grid-column-gap: 3rem;
      grid-row-gap: 2rem;
      margin-bottom: 3rem;
    }
  }
}
