@charset "UTF-8";

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: 400;
	src: url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.6.3/webfonts/fa-brands-400.woff2") format("woff2");
	font-display: swap;
}
@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	src:url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.6.3/webfonts/fa-regular-400.woff2") format("woff2"),
	 url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.6.3/webfonts/fa-solid-900.woff2") format("woff2");
	font-display: swap;
}

$font-sans-serif: Arial, Helvetica, sans-serif;
$font-serif: Times, 'Times New Roman', serif;
$font-averta: 'Averta', sans-serif;
$font-averta-demo: 'Averta Demo', sans-serif;
$font-avenir: 'Avenir Next Condensed', sans-serif;
$font-sf-pro: 'SF Pro Text', sans-serif;
$font-national: 'National', sans-serif;
$font-material-icon: 'Material Icons';

$body-text: #111947;

$white: #fff;
$black: #000;
$dark-gray: #313840;
$light-gray: #939eaa;
$color-grey-dark: #777;
$color-title: #111947;
$green: #24cf5f;
$blue: #4baff0;
$red: #ff2c2c;
$pink: #ff5978;
$gray-boder: #e0e0e0;
$gray-text: #7f7f7f;
$border-new: #eaedf3;
$host-msg: #cff1dd;
$guest-msg: #eaedf3;
$border-input: #cccccc;
$border-input-focus: #2684ff;

$blue-main: #12c4c4; // テキストリンク
$sub-color: #ff6c6c; // 赤

$icon-color: #9496ab;
$bg-color-1: #f9f9f9;
$bg-color-2: #f1f3f8;
$bg-color-3: #f5f5f5;

$blue-main-hover: #0e9d9d;
$gray-divider: #cccccc;
$color-text-normal: #727488;
$gray-new: #989ca0;
$background-header: #111947;
$deactive-status: #d80027;
$copy-right-background: #010a29;
$placeholder-input: #aaaaaa;

$badge-background-hover: #4b4b4b;
$button-hover: #0fa7a7;
$button-active: #41d0d0;
$button-sub-active: #ff8989;
$button-cencel-active: #e5e5e5;

$btn_m: 57.97101vw;

// ブレイクポイント
$pc_w: 1200px;
$sp_w: 768px;
$sp_w2:414px;

// fontawesome
@mixin fas{
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}

// 縮小時 タブレット
@mixin vw($px, $width, $ob){
	#{$ob}: $px / $width * 100vw;
}
// 縮小時 スマホ
@mixin sp_vw($size, $ob){
	#{$ob}: $size / 414px * 100vw;
}


/*--------------------
共通系
--------------------*/
.base_style{
	a{
		color: $blue-main;
	}
	.red{
		color: $sub-color;
	}
	input{
		background: #f1f3f8;
		border: none!important;
	}
	footer{
		margin-top: 100px;
	}
}

.base_table{
	display: table;
	width: 100%;
	th,td{
		padding: 1rem;
		border: 1px solid #ddd;
		vertical-align: middle;
		text-align: left;
	}
	th{
		background: #727488;
		color: #fff;
		font-weight: normal;
	}
}

p.indent{
	padding-left: 1em;
}

/*---- スマホ ----*/
// スマホ表示、非表示
.pcb{display: none;}
.spb{display: block;}

.base_style{
	color: $body-text;

	h2,h3,h4,h5,table,p,.input input{
		line-height: 1.75;
	}
	h2{
		@include sp_vw(20px, font-size);
		@include sp_vw(10px, margin-bottom);
		
	}
	h3{
		@include sp_vw(18px, font-size);
	}
	h4,h5, p ,table{
		@include sp_vw(16px, font-size);
	}
	h3,h4,h5{
		@include sp_vw(10px, margin-bottom);
	}
	.input input {
		@include sp_vw(16px, font-size);
		color: $body-text;
		font-weight: 400;
		@include sp_vw(40px, height);
	}
}
/*---- タブレット 縮小 ----*/
@media (min-width: $sp_w){
	// スマホ表示、非表示
	.pcb{display: block;}
	.spb{display: none;}
	
	.base_style{
		h2{
			@include vw(20px, $pc_w, font-size);
			@include vw(5px, $pc_w, margin-bottom);
		}
		h3{
			@include vw(18px, $pc_w, font-size);
		}
		h3,h4,h5{
			@include vw(5px, $pc_w, margin-bottom);
		}
		h4,h5, p ,table{
			@include vw(16px, $pc_w, font-size);
		}

		.input input {
			@include vw(16px, $pc_w, font-size);
			@include vw(40px, $pc_w, height);
		}
	}
}
/*---- PC最大 ----*/
@media (min-width: $pc_w){
	.base_style{
		h2{
			font-size: 2rem;
			margin-bottom: 0.5rem;
		}
		h3{
			font-size: 1.8rem;
		}
		h3,h4,h5{
			margin-bottom: 0.5rem;
		}
		h4,h5, p ,table{
			font-size: 1.6rem;
		}
		.input input {
			font-size: 1.6rem;
			height: 4rem;
		}
	}
}


/*----------------------------
// センター寄せ メッセージ表示系ページ
----------------------------*/
.base_style.center_wrap{
	text-align: center;
	.input{
		margin: 3rem auto;
		input{
			margin: auto;
		}
	}
	.btn_wrap{
		margin: 2rem auto;
		text-align: center;
		.button-custom{
			margin: auto;
		}
	}
}
// タイトルありの場合
.wrapper__header + .wrapper__content .center_wrap{
	h2{
		margin-top: 1rem;
	}
}

// 完了形ページ
.comp_wrap{
	h2{
		margin-top: 1rem;
		margin-bottom: 3rem;
	}
	.btn_wrap{
		margin: 8rem auto 3rem auto;
	}
}



/*----------------------------
// 退会
----------------------------*/
.wrap-withdraw{
	.bottom_tx{
		margin-top: 8rem;
	}
	p{
		color: $body-text;
	}
	a{
		color: $blue-main;
	}
	.wrap-withdraw_content_button{
		margin: 3rem auto;
	}
	h4{
		font-size: 1.6rem;
		margin-bottom: 5px;
	}
	.center{
		text-align: center;
	}
	a.btn{
		&:hover{
			text-decoration: none;
		}
	}
}
@media (min-width: $sp_w){
	h4{
		@include vw(16px, $pc_w, font-size);
	}
}


/*----------------------------
// 会員ステータス
----------------------------*/
//テーブル風レイアウト 現在の会員ステータス
.table_1{
	border: 1px solid #ccc;
	display: flex;
	justify-content: space-between;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 1.75;
	.th, .td{
		padding: 1rem;
	}
	.th{
		background: $body-text;
		color: #fff;
		flex-basis: 200px;
		text-align: center;
	}
	.td{
		color: $body-text;
		display: flex;
		justify-content: space-between;
		flex-basis: calc(100% - 200px);
	}
	.price{
		margin-left: 0.5em;
	}
	.date{
		&.end{
			color: $sub-color;
		}
	}
	.date + .date{
		margin-left: 0.5em;
	}
}

.status-wrap{
	a{
		color: $blue-main;
	}
	.red{
	}
	.small{
		font-size: 1.3rem;
		font-weight: normal;
	}
	.section{
		margin-bottom: 4rem;
	}
	.center{
		text-align: center;
	}

	.status-wrap_box{
		max-width: inherit;
	}
	.status-wrap_content{
		width: 100%;
	}
	.status-wrap_content-main p{
		font-size: 1.4rem;
	}

	// プラン表
	.plan_wrap{
		margin-top: 2rem;
		.card-member{
			border: none;
			border-radius: 10px;
		}
		.card-member_content{
			padding-top: 0rem;
		}
		.card-member_header {
			padding: 1.6rem;
			padding-top: 1.2rem;
			border: 1px solid #ccc;
			border-bottom: 0;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			.head{
				line-height: 1;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 100%;
				.plan{
					text-align: left;
					font-size: 2rem;
					font-weight: 600;
				}
				.price{
					text-align: right;
					margin:1rem 0;
					margin-left: 1rem;
					font-weight: 600;
					.title1{
						text-align: center;
						font-size: 2.6rem;
					}
					.title2{
						font-size: 1.6rem;
						font-weight: 600;
					}
				}
			}
			.header_content{
				p{
					font-size: 1.4rem;
				}
			}
		}
		.card-member_wrapper-content{
			padding: 0;
			padding-bottom: 0;
			border: 1px solid #ccc;
			border-top: 0;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			p{
				text-align: center;
				margin-top: 1rem;
			}
		}
		.card-member_content{
			width: 100%;
			span{
				margin-left: inherit;
				font-size: 1.4rem;
			}
		}
		// 各表中身
		ul{
			width: 100%;
			li{
				font-weight: 600;
				font-size: 1.4rem;
				line-height: 1.5;
				position: relative;
				padding: 1rem 1.5rem;
				border-bottom: 1px solid #ccc;
				.title{
					font-size: 1.4rem;
					margin-bottom: 0.3rem;
					color: #888;
				}
				&:last-child{
					border-bottom: none;
				}
				&.btn .small{
					margin-top: 2rem;
					font-size: 1.2rem;
					text-align: center;
					padding: 0 1rem;
					line-height: 1.5;
				}
				.small{
					font-size: 1.2rem;
				}
				&.btn{
					.card-member_button{
						margin-top: 0rem;
					}
					p{
						font-size: 1.4rem;
					}
				}
			}
		}

		// 各プラン
		.card-member{
			position: relative;
			margin-bottom: 0;
			&.plan1{
				.card-member_header{
					background-color: $blue-main;
				}
			}
			&.plan2{
				.card-member_header{
					background-color: #EFB711;
				}
			}
			&.plan3{
				.card-member_header{
					background-color: #8BC145;
				}
			}
			// カミングスーン
			.card-member_wrapper-content{
				position: relative;
			}
			.coming_disable{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: rgba(100,100,100,1.0);
				.tx{
					width: 100%;
					text-align: center;
					font-size: 3rem;
					line-height: 1.4;
					color: #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
				}
				&.spb{
					background-color: rgba(0,0,0,0.5);
					.tx{
						font-size: 2rem;
					}
				}
			}
		}
	}
}
@media (min-width: 1200px){
	.status-wrap .box-content .card-member {
		width: 32%;
	}
}

// スマホ フォントサイズ 
.status-wrap .small{
	@include sp_vw(12px, font-size);
}
.status-wrap .plan_wrap ul li .title,
.status-wrap .plan_wrap ul li,
.status-wrap .plan_wrap .card-member_header .header_content p,
.status-wrap .plan_wrap ul li.btn p,
.status-wrap .plan_wrap .card-member_content span,
.status-wrap .plan_wrap ul li .title{
	@include sp_vw(14px, font-size);
}
.status-wrap .plan_wrap .card-member_header .head .plan{
	@include sp_vw(16px, font-size);
}
.status-wrap .plan_wrap .card-member_header .head .price .title1{
	@include sp_vw(30px, font-size);
}
.status-wrap .plan_wrap .card-member_header .head .price .title2,
.table_1{
	@include sp_vw(16px, font-size);
}

// タブレット縮小
@media (min-width: $sp_w){
	.status-wrap .small{
		@include vw(12px, $pc_w, font-size);
	}
	.status-wrap .plan_wrap ul li .title,
	.status-wrap .plan_wrap ul li,
	.status-wrap .plan_wrap .card-member_header .header_content p,
	.status-wrap .plan_wrap ul li.btn p,
	.status-wrap .plan_wrap .card-member_content span,
	.status-wrap .plan_wrap ul li .title{
		@include vw(14px, $pc_w, font-size);
	}
	.status-wrap .plan_wrap .card-member_header .head .price .title1{
		@include vw(30px, $pc_w, font-size);
	}
	.status-wrap .plan_wrap .card-member_header .head .plan,
	.status-wrap .plan_wrap .card-member_header .head .price .title2,
	.table_1{
		@include vw(16px, $pc_w, font-size);
	}
	.status-wrap .plan_wrap .card-member_header .head .price{
		margin: 10px / $pc_w * 100vw 0;
	}

	.status-wrap .plan_wrap .card-member{
		border: 1px solid #ccc;
	}
	.status-wrap .plan_wrap .card-member_header,
	.status-wrap .plan_wrap .card-member_wrapper-content{
		border: none;
	}
	.status-wrap .plan_wrap .card-member_header .head{
		justify-content: space-between;
	}
}
// PC最大サイズ
@media (min-width: $pc_w){
	.status-wrap .small{
		font-size: 1.2rem;
	}
	.status-wrap .plan_wrap ul li .title,
	.status-wrap .plan_wrap ul li,
	.status-wrap .plan_wrap .card-member_header .header_content p,
	.status-wrap .plan_wrap ul li.btn p,
	.status-wrap .plan_wrap .card-member_content span,
	.status-wrap .plan_wrap ul li .title{
		font-size: 1.4rem;
	}
	.status-wrap .plan_wrap .card-member_header .head .plan{
		font-size: 1.6rem;
	}
	.status-wrap .plan_wrap .card-member_header .head .price .title1{
		font-size: 3rem;
	}
	.status-wrap .plan_wrap .card-member_header .head .price .title2,
	.table_1{
		font-size: 1.6rem;
	}
}

// スマホ レスポンシブ
@media (max-width: $sp_w){
	#current_status{
		.table_1{
			flex-wrap: wrap;
			.th{
				display: none;
			}
			.td{
				flex-wrap: wrap;
			}
			.td,
			.td .left,
			.td .right{
				flex-basis: 100%;
			}
			.td{
				padding: 0;
			}
			.td .left,.td .right{
				@include sp_vw(10px, padding);
			}
			.td .left{
				background-color: #111947;
				color: #fff;
				display: flex;
				justify-content: space-between;
			}
			.td .right{
			}
			.date{
				display: block;
				margin: 0;
			}
		}
	}

	// アコーディオンメニュー関連
	.card-member{
		.card-member_wrapper-content{
			transition: .35s;
			height: auto;
			opacity: 1;
			visibility: visible;
		}
		.card-member__dropdown{
			transition: 0s;
		}
	}
	.card-member.is-close{
		.card-member_header{
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			border-bottom: 1px solid #ccc;
		}
		.card-member_wrapper-content{
			height: 0;
			opacity: 0;
			visibility: hidden;
		}
	}
	.card-member .is-close{
		.card-member__dropdown{
			transform:rotate(-180deg);
			transform-origin: 50% 35%;
		}
	}
}

/*----------------------------
// 会員ステータス
----------------------------*/
// スマホ
.about_user-status-wrap{
	.section{
		@include sp_vw(30px, padding-top);
		@include sp_vw(30px, padding-bottom);
		border-bottom: 1px solid #ccc;
		&:first-of-type{
			@include sp_vw(10px, padding-top);
		}
		.section{
			border:none;
			padding: 0;
			@include sp_vw(10px, margin-top);
			@include sp_vw(30px, margin-bottom);
			.section{
				@include sp_vw(16px, margin-top);
				@include sp_vw(16px, margin-bottom);
			}
			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
	.about_user-status-wrap_box >.section:last-of-type{
		border-bottom: none;
	}
}

// タブレット縮小
@media (min-width: $sp_w){
	.about_user-status-wrap{
		.section{
			@include vw(30px, $pc_w, padding-top);
			@include vw(30px, $pc_w, padding-bottom);
			&:first-of-type{
				padding-top: 1rem;
				@include vw(10px, $pc_w, padding-top);
			}
			.section{
				@include vw(10px, $pc_w, margin-top);
				@include vw(20px, $pc_w, margin-bottom);
				.section{
					@include vw(30px, $pc_w, margin-top);
					@include vw(30px, $pc_w, margin-bottom);
				}
			}
		}
	}
}
// PC最大サイズ
@media (min-width: $pc_w){
	.about_user-status-wrap{
		.section{
			padding: 3rem 0;
			&:first-of-type{
				padding-top: 1rem;
			}
			.section{
				border:none;
				padding: 0;
				margin: 1rem 0 2rem 0;
				.section{
					margin: 3rem 0;
				}
			}
		}
	}
}
