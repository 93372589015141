.responsive-top-screen {
  //responsive header top
  .header-top {
    padding: calc(28vw / 4.14) calc(23vw / 4.14) 0;
    @media #{$tablets} {
      padding: calc(50vw / 14) calc(60vw / 14) 0;
    }
    @media #{$large-desktops} {
      padding: 5rem 6rem 0;
    }
    &__logo {
      svg {
        width: calc(186vw / 4.14);
        height: calc(40vw / 4.14);
        @media #{$tablets} {
          width: calc(235vw / 14);
          height: calc(40vw / 14);
        }
        @media #{$large-desktops} {
          width: 23.5rem;
          height: 4rem;
        }
      }
    }

    &__select-language {
      @media #{$tablets} {
        padding-right: calc(20vw / 14);
      }
      @media #{$large-desktops} {
        padding-right: 2rem;
      }
    }
    &__group-auth-buttons {
      & > button {
        font-family: Montserrat;
        width: calc(132vw / 4.14);
        height: calc(43vw / 4.14);
        @media #{$tablets} {
          width: calc(132vw / 14);
          height: calc(43vw / 14);
        }
        @media #{$large-desktops} {
          width: 13.2rem;
          height: 4.3rem;
        }
        &:nth-child(2) {
          @media #{$tablets} {
            margin-left: calc(16vw / 14);
          }
          @media #{$large-desktops} {
            margin-left: 1.6rem;
          }
        }
        span {
          @media #{$tablets} {
            font-size: calc(16vw / 14);
            margin-left: calc(10vw / 14);
          }
          @media #{$large-desktops} {
            font-size: 1.6rem;
            margin-left: 1rem;
          }
        }
      }
      .outline-1 {
        svg {
          @media #{$tablets} {
            width: calc(13vw / 14);
            height: calc(13vw / 14);
          }
          @media #{$large-desktops} {
            width: 1.3rem;
            height: 1.3rem;
          }
        }
      }
      .secondary {
        svg {
          @media #{$tablets} {
            width: calc(14vw / 14);
            height: calc(12vw / 14);
          }
          @media #{$large-desktops} {
            width: 1.4rem;
            height: 1.2rem;
          }
        }
      }
    }
    &__menu {
      svg {
        width: calc(30vw / 4.14);
        height: calc(30vw / 4.14);
        @media #{$tablets} {
          width: calc(30vw / 14);
          height: calc(30vw / 14);
        }
        @media #{$large-desktops} {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }

  //responsive menuMobiles
  .menuMobiles {
    &__header--wrap-close {
      svg {
        width: calc(24vw / 4.14);
        height: calc(24vw / 4.14);
      }
    }
    &__body {
      padding-top: calc(120vw / 4.14);
      padding-left: calc(40vw / 4.14);
      @media #{$large-mobiles} {
        padding-top: calc(120vw / 4.14);
      }
      &--button {
        &-auth,
        &-redirect {
          font-size: calc(16vw / 4.14);
          width: calc(220vw / 4.14);
          height: calc(43vw / 4.14);
          border-radius: calc(24vw / 4.14);
          span {
            padding-left: calc(8vw / 4.14);
          }
        }
        &-redirect {
          margin-bottom: calc(32vw / 4.14);
        }
      }
      &--link {
        padding-top: calc(60vw / 4.14);
        &-content {
          &:not(:last-child) {
            padding-bottom: calc(44vw / 4.14);
          }
          span:first-child {
            font-size: calc(18vw / 4.14);
            letter-spacing: 0.02em;
          }
          span:nth-child(2) {
            font-family: 'M PLUS 1p';
            font-size: calc(16vw / 4.14);
            letter-spacing: 0.02em;
            margin-left: calc(2vw / 4.14);
          }
        }
      }
    }
    &-content {
      &__footer {
        width: 100%;
        bottom: calc(26vw / 4.14);
        padding-top: calc(10vw / 4.14);
      }
    }
  }
  //end responsive menuMobile

  //responsive switch language
  .switch-languages-top {
    [class$='control'] {
      min-height: 0;
      font-size: calc(16vw / 4.14);
      width: calc(140vw / 4.14);
      height: calc(43vw / 4.14);
      border-radius: calc(24vw / 4.14);
      line-height: 1.485;
      @media #{$tablets} {
        width: calc(140vw / 14);
        height: calc(43vw / 14);
        border-radius: calc(24vw / 14);
        font-size: calc(16vw / 14);
      }
      @media #{$large-desktops} {
        width: 14rem;
        height: 4.3rem;
        border-radius: 2.4rem;
        font-size: 1.6rem;
      }
    }
    [class$='menu'] {
      font-size: calc(16vw / 4.14);
      line-height: 1.485;
      width: calc(180vw / 4.14);
      @media #{$tablets} {
        font-size: calc(16vw / 14);
        width: calc(180vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.6rem;
        width: 18rem;
      }
    }
    [class$='singleValue'] {
      padding: 0;
      & > span {
        padding-left: calc(6vw / 4.14);
        padding: 0;
        height: 100%;
        @media #{$tablets} {
          padding-left: calc(6vw / 14);
        }
        @media #{$large-desktops} {
          padding-left: 6px;
        }
      }
    }
    [class$='indicatorContainer'] {
      padding: calc(8vw / 4.14);
      padding-left: 0;
      @media #{$tablets} {
        padding: calc(8vw / 14);
        padding-left: 0;
      }
      @media #{$large-desktops} {
        padding: 0.8rem;
        padding-left: 0;
      }
      svg {
        width: calc(12vw / 4.14);
        height: calc(8vw / 4.14);
        @media #{$tablets} {
          width: calc(12vw / 14);
          height: calc(8vw / 14);
        }
        @media #{$large-desktops} {
          width: 1.2rem;
          height: 0.8rem;
        }
      }
    }
    [class$='MenuList'] {
      & > div {
        padding: calc(4vw / 4.14);
        @media #{$tablets} {
          padding: calc(4vw / 14);
        }
        @media #{$large-desktops} {
          padding: 4px;
        }
      }
    }
    [class$='option'] {
      height: calc(50vw / 4.14);
      @media #{$tablets} {
        height: calc(50vw / 14);
      }
      @media #{$large-desktops} {
        height: 5rem;
      }
    }
    [class$='option'] &__name {
      margin-left: calc(11vw / 4.14);
      @media #{$tablets} {
        margin-left: calc(11vw / 14);
      }
      @media #{$large-desktops} {
        margin-left: 1.1rem;
      }
    }
    &__group {
      padding-left: calc(6vw / 4.14);
      @media #{$tablets} {
        padding-left: calc(6vw / 14);
      }
      @media #{$large-desktops} {
        padding-left: 0.6rem;
      }
      svg {
        width: calc(33vw / 4.14);
        height: calc(33vw / 4.14);
        @media #{$tablets} {
          width: calc(33vw / 14);
          height: calc(33vw / 14);
        }
        @media #{$large-desktops} {
          width: 3.3rem;
          height: 3.3rem;
        }
      }
    }
    &__name {
      margin-left: 0;
      padding-left: calc(8vw / 4.14);
      @media #{$tablets} {
        padding-left: calc(8vw / 14);
      }
      @media #{$large-desktops} {
        padding-left: 0.8rem;
      }
    }
  }
  //responsive footer
  .footerC-wrap {
    padding: calc(60vw / 4.14) 0 calc(120vw / 4.14) calc(24vw / 4.14);
    @media #{$tablets} {
      padding: calc(120vw / 14) calc(160vw / 14) calc(120vw / 14);
    }
    @media #{$large-desktops} {
      padding: 12rem 16rem;
      max-width: 140rem;
      margin: 0 auto;
    }
    span {
      font-size: calc(16vw / 4.14);
      line-height: 1.1429;
      @media #{$tablets} {
        font-size: calc(16vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.6rem;
      }
    }
    &__content--logo {
      svg {
        width: calc(186vw / 4.14);
        height: calc(40vw / 4.14);
        @media #{$tablets} {
          width: calc(235vw / 14);
          height: calc(40vw / 14);
        }
        @media #{$large-desktops} {
          width: 23.5rem;
          height: 4rem;
        }
      }
    }
    &__content--detail {
      padding: calc(20vw / 4.14) 0 calc(10vw / 4.14) 0;
      @media #{$tablets} {
        padding: calc(20vw / 14) 0;
      }
      @media #{$large-desktops} {
        padding: 2rem 0;
      }
    }
    &__content--company {
      padding-bottom: calc(20vw / 4.14);
      @media #{$tablets} {
        padding-bottom: calc(20vw / 14);
      }
      @media #{$large-desktops} {
        padding-bottom: 2rem;
      }
    }
    &__content--link {
      a {
        font-size: calc(14vw / 4.14);
        margin-bottom: calc(15vw / 4.14);
        margin-right: calc(14vw / 4.14);
        line-height: 1.21429;
        @media #{$tablets} {
          font-size: calc(14vw / 14);
          margin-bottom: calc(15vw / 14);
          margin-right: 0;
          margin-left: calc(14vw / 14);
        }
        @media #{$large-desktops} {
          font-size: 1.4rem;
          margin-bottom: 1.5rem;
          margin-left: 1.4rem;
        }
      }
    }
    &__content--app {
      padding-top: calc(5vw / 4.14);
      padding-bottom: calc(30vw / 4.14);
      @media #{$tablets} {
        padding-top: calc(5vw / 14);
        padding-bottom: calc(50vw / 14);
      }
      @media #{$large-desktops} {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
    &__content--app_store {
      margin-right: calc(15vw / 4.14);
      @media #{$tablets} {
        margin-right: calc(15vw / 14);
      }
      @media #{$large-desktops} {
        margin-right: 1.5rem;
      }
    }
    &__content--app_store,
    &__content--app_play {
      svg {
        width: calc(128vw / 4.14);
        height: calc(40vw / 4.14);
        @media #{$tablets} {
          width: calc(128vw / 14);
          height: calc(40vw / 14);
        }
        @media #{$large-desktops} {
          width: 12.8rem;
          height: 4rem;
        }
      }
      &--app_attention {
        display: flex;
        padding-top: calc(5vw / 4.14);
        padding-bottom: calc(30vw / 4.14);
        @media #{$tablets} {
          display: flex;
          justify-content: flex-end;
          padding-top: calc(5vw / 14);
          padding-bottom: calc(30vw / 14);
        }
        @media #{$large-desktops} {
          padding: .5rem 0 5rem;
        }
        a {
          margin: 0;
          @media #{$tablets} {
            margin: 0;
          }
          @media #{$large-desktops} {
            margin: 0;
          }
        }
      }
      }
    &__content2 {
      span {
        font-size: calc(12vw / 4.14);
        line-height: 1.1667;
        @media #{$tablets} {
          font-size: calc(12vw / 14);
        }
        @media #{$large-desktops} {
          font-size: 1.2rem;
        }
      }
    }
  }
}
