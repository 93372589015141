.contact {
  &__describe {
    white-space: pre-wrap;
    .input__label {
      margin-bottom: calc(5vw / 4.14);
    }
    .form-item {
      [class$='container'] {
        width: 100%;
      }
      [class$='control'] {
        width: 100%;
      }
      [class$='ValueContainer'] {
        overflow: visible;
        line-height: 1;
      }
      [class$='singleValue'] {
        line-height: 1;
      }
      [class$='IndicatorsContainer'] {
        svg {
          width: calc(20vw / 4.14);
          height: calc(20vw / 4.14);
        }
      }
      [class$='menu'] {
        width: 100%;
      }
      [class$='MenuList'] {
        & > div {
          padding: calc(15vw / 4.14) calc(10vw / 4.14);
        }
      }
      input {
        width: 100%;
      }

      textarea {
        height: calc(185vw / 4.14);
        padding: 2rem;
        max-width: 100%;
        min-width: 100%;
      }
    }
    .form-item + .form-item {
      margin-top: calc(30vw / 4.14);
    }
  }
  &__form--footer__button {
    margin-top: calc(20vw / 4.14);
    button {
      margin: 0 auto;
      width: calc(270vw / 4.14);
      height: calc(50vw / 4.14);
    }
  }
  &__message {
    text-align: center;
    font-size: calc(16vw / 4.14);
    line-height: 1.75;
    color: #111947;
    @supports not (-webkit-touch-callout: none) {
      word-break: keep-all;
    }

    margin-bottom: calc(20vw / 4.14);
    .text-link {
      font-size: calc(16vw / 4.14);
    }
  }
  &__checkbox {
    margin-bottom: calc(30vw / 4.14);
    &-label {
      font-size: calc(16vw / 4.14);
      color: $body-text;
    }
    .checkbox {
      input[type='checkbox'] {
        width: calc(14vw / 4.14);
        height: calc(14vw / 4.14);
        margin-right: calc(5vw / 4.14);
        &:before {
          width: calc(14vw / 4.14);
          height: calc(14vw / 4.14);
        }
        &:after {
          width: calc(10vw / 4.14);
          height: calc(5vw / 4.14);
          top: calc(5vw / 4.14);
          left: calc(2vw / 4.14);
        }
      }
    }
  }

  @media #{$tablets} {
    &__describe {
      .input__label {
        margin-bottom: calc(10vw / 14);
      }
      .form-item {
        [class$='IndicatorsContainer'] {
          svg {
            width: calc(20vw / 14);
            height: calc(20vw / 14);
          }
        }
        [class$='MenuList'] {
          & > div {
            padding: calc(15vw / 14) calc(10vw / 14);
          }
        }
        textarea {
          height: calc(185vw / 14);
        }
      }
      .form-item + .form-item {
        margin-top: calc(30vw / 14);
      }
    }
    &__form--footer__button {
      margin-top: calc(20vw / 14);
      button {
        width: calc(270vw / 14);
        height: calc(50vw / 14);
        font-size: calc(16vw / 14);
      }
    }
    &__message {
      font-size: calc(16vw / 14);
      margin-bottom: calc(20vw / 14);
      .text-link {
        font-size: calc(16vw / 14);
      }
    }
    &__checkbox {
      margin-bottom: calc(30vw / 14);
      &-label {
        font-size: calc(16vw / 14);
      }
      .checkbox {
        input[type='checkbox'] {
          width: calc(14vw / 14);
          height: calc(14vw / 14);
          margin-right: calc(5vw / 14);
          &:before {
            width: calc(14vw / 14);
            height: calc(14vw / 14);
          }
          &:after {
            width: calc(10vw / 14);
            height: calc(5vw / 14);
            top: calc(5vw / 14);
            left: calc(2vw / 14);
          }
        }
      }
    }
  }

  @media #{$small-desktops} {
    &__describe {
      width: 71rem;
      margin: 0 auto;
      .input__label {
        margin-bottom: 0.5rem;
      }
      .form-item {
        [class$='IndicatorsContainer'] {
          svg {
            width: 2rem;
            height: 2rem;
          }
        }
        [class$='menu'] {
          font-size: 1.6rem;
        }
        [class$='MenuList'] {
          & > div {
            padding: 1.5rem 1rem;
          }
        }

        textarea {
          height: 9rem;
        }
      }
      .form-item + .form-item {
        margin-top: 3rem;
      }
    }
    &__form--footer__button {
      width: 71rem;
      margin: 2rem auto 0;
      button {
        width: 24rem;
        height: 5rem;
        font-size: 1.4rem;
      }
    }
    &__message {
      font-size: 1.6rem;
      margin-bottom: 2rem;
      .text-link {
        font-size: 1.6rem;
      }
    }
    &__checkbox {
      margin-bottom: 3rem;
      &-label {
        font-size: 1.6rem;
      }
      .checkbox {
        input[type='checkbox'] {
          width: 1.4rem;
          height: 1.4rem;
          margin-right: 0.5rem;
          &:before {
            width: 1.4rem;
            height: 1.4rem;
          }
          &:after {
            width: 1rem;
            height: 0.5rem;
            top: 0.5rem;
            left: 0.2rem;
          }
        }
      }
    }
  }
}
.contact-form {
  .form-field-error-message {
    width: 100% !important;
    line-height: initial;
    margin-top: calc(10vw / 4.14);
    font-size: calc(16vw / 4.14);
    &.is-center {
      text-align: center;
    }
    @media #{$tablets} {
      margin-top: calc(10vw / 14);
      font-size: calc(16vw / 14);
    }
    @media #{$small-desktops} {
      margin-top: 1rem;
      font-size: 1.6rem;
    }
  }
}
