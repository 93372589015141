.page {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header {
    width: 100%;
    background-color: $background-header;
    height: 66px;
    @media #{$tablets} {
      height: 66px;
    }
  }
  .footer {
    margin-top: 37.97px;
    @media #{$tablets} {
      margin-top: 60px;
    }
    width: 100%;
    background-color: $background-header;
    height: 66px;
  }
  .links {
    position: absolute;
    top: 9rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    @media #{$mobiles} {
      width: 34.5rem;
    }
    @media #{$large-mobiles} {
      width: 50rem;
    }
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    span {
      display: inline-block;
    }
  }
}
