.button-custom {
  border: 0;
  background: $blue-main;
  color: $white;
  font-weight: 700;
  outline: none;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition;
  width: calc(240vw / 4.14);
  height: calc(50vw / 4.14);
  font-size: calc(14vw / 4.14);
  border-radius: calc(50vw / 4.14);
  @media #{$tablets} {
    width: calc(240vw / 14);
    height: calc(50vw / 14);
    font-size: calc(14vw / 14);
    border-radius: calc(50vw / 14);
  }

  @media #{$large-desktops} {
    width: 24rem;
    height: 5rem;
    font-size: 1.4rem;
    border-radius: 5rem;
  }

  &:hover {
    background: $button-hover;
  }

  &:active {
    background: $button-active;
  }

  &:disabled {
    background: $gray-boder;
    cursor: auto;
  }

  &.secondary {
    background: $sub-color;
    &:hover {
      background: darken($sub-color, 15);
    }

    &:active {
      background: $button-sub-active;
    }
  }

  &.outline {
    background: #fff;
    border: 2px solid $blue-main;
    color: $blue-main;
    &:hover {
      background: lighten($blue-main, 55);
    }

    &:active {
      background: lighten($blue-main, 95);
    }

    &:disabled {
      border-color: $gray-boder;
      color: $gray-boder;
      cursor: auto;
      background: #fff;
    }
  }
  &.outline-1 {
    @extend .outline;
    border-width: 1px;
  }

  &.outline-2 {
    background: #fff;
    border: 1px solid $sub-color;
    color: $sub-color;
    &:hover {
      background: lighten($sub-color, 55);
    }

    &:active {
      background: lighten($sub-color, 95);
    }

    &:disabled {
      border-color: $gray-boder;
      color: $gray-boder;
      cursor: auto;
      background: #fff;
    }
  }
  &.cancel {
    background: $gray-divider;
    color: $body-text;
    &:hover {
      background: #b5b5b5;
    }

    &:active {
      background: $button-cencel-active;
    }

    &:disabled {
      color: $body-text;
      cursor: auto;
      background: $gray-boder;
      color: $white;
    }
  }

  &.tiny {
	  height: calc( 32vw / 4.14 );
	  width: calc( 200vw / 4.14 );
    @media #{$tablets} {
		  height: calc( 32vw / 14 );
		  width: calc( 200vw / 14 );
    }
    @media #{$large-desktops} {
      width: 20rem;
      height: 3.2rem;
    }
  }

  &.small {
	  height: calc( 36vw / 4.14 );
	  width: calc( 150vw / 4.14 );
    @media #{$tablets} {
		  height: calc( 36vw / 14 );
		  width: calc( 150vw / 14 );
    }
    @media #{$large-desktops} {
      width: 15rem;
      height: 3.6rem;
    }
  }

  &.medium {
	  height: calc( 50vw / 4.14 );
	  width: calc( 150vw / 4.14 );
    @media #{$tablets} {
		  height: calc( 50vw / 14 );
		  width: calc( 150vw / 14 );
    }
    @media #{$large-desktops} {
      width: 15rem;
      height: 5rem;
    }
  }

  &.shortnormal {
    @extend .button-custom;
    width: calc(210vw / 4.14);
    @media #{$tablets} {
      width: calc(210vw / 14);
    }
    @media #{$large-desktops} {
      width: 21rem;
    }
  }

  &.normal {
    width: calc(240vw / 4.14);
	  height: calc( 50vw / 4.14 );
    @media #{$tablets} {
      width: calc(210vw / 14);
		  height: calc( 50vw / 14 );
    }
    @media #{$large-desktops} {
      width: 24rem;
      height: 5rem;
    }
  }
}

button.close-icon {
  cursor: pointer;
  background: transparent;
  padding: 0;
}
