@charset "UTF-8";
/* Scss Document */
/*--------------------
ヘッダー
--------------------*/
.responsive-top-screen .header-top {
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.top__header-mobile {
  margin-top: 11.66184vw;
}

@media (min-width: 768px) {
  .responsive-top-screen .header-top {
    padding-top: 1vw;
    padding-bottom: 1vw;
  }

  .top__player {
    margin-top: 4.85714vw;
  }
}

/*# sourceMappingURL=top_style_v2.css.map */
