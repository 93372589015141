.booking-attention {
  &__container {
    box-sizing: border-box;
    color: $body-text;
  }
  &__description {
    font-size: calc(16vw / 4.14);
    line-height: 1.75;
    margin-bottom: 0;
    border-bottom: calc(3vw / 4.14) dotted $gray-divider;
    margin-bottom: calc(30vw / 4.14);
    padding-bottom: calc(30vw / 4.14);
    color: #111947;
  }
  &__clothes {
    &-title {
      font-size: calc(18vw / 4.14);
      font-weight: 700;
      color: #111947;
      margin-bottom: calc(10vw / 4.14);
    }
    &-content {
      padding: calc(20vw / 4.14) calc(15vw / 4.14);
      background-color: $bg-color-2;
      border-radius: calc(10vw / 4.14);
    }
    &-caution {
      font-size: calc(16vw / 4.14);
      line-height: 1.75;
      font-weight: bold;
      margin-bottom: calc(30vw / 4.14);
    }
    &-list {
      position: relative;
      margin-bottom: calc(30vw / 4.14);
    }
    &-wrapper {
      border: calc(2vw / 4.14) solid $sub-color;
      border-radius: calc(10vw / 4.14);
      background-color: $white;
      padding: calc(15vw / 4.14) calc(30vw / 4.14);
      position: relative;
    }
  }
  &__clothe {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: calc(20vw / 4.14) 0;
    &-image {
      width: 45%;
      text-align: center;
      svg {
        height: calc(84vw / 4.14);
        width: auto;
        max-width: calc(124vw / 4.14);
      }
    }
    &-label {
      width: 55%;
      text-align: center;
      font-size: calc(12vw / 4.14);
      line-height: 1.3;
    }
  }
  &__clothe + &__clothe {
    border-top: calc(3vw / 4.14) dotted #ccc;
  }
  &__cancel-icon {
    position: absolute;
    left: calc(-10vw / 4.14);
    top: calc(-15vw / 4.14);
    svg {
      width: calc(33vw / 4.14);
      height: calc(33vw / 4.14);
    }
  }
  &__other {
    &-cautions {
      &-title {
        margin-bottom: calc(10vw / 4.14);
      }
      &-list {
        list-style-type: none;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        li {
          font-size: calc(16vw / 4.14);
          line-height: 1.75;
          position: relative;
          padding-left: 1.5em;
          br {
            display: none;
          }
          &:before {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            content: '・';
          }
          span {
            &.red-text {
              color: #ff6c6c;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  &__removing {
    &-items {
      margin-top: calc(30vw / 4.14);
      padding-top: calc(30vw / 4.14);
      border-top: calc(3vw / 4.14) dotted #ccc;
      &-lead {
        font-size: calc(16vw / 4.14);
        line-height: 1.75;
        margin-bottom: calc(20vw / 4.14);
      }
      &-list {
        list-style-type: none;
        background-color: $white;
        padding: calc(20vw / 4.14) calc(15vw / 4.14);
        border-radius: calc(10vw / 4.14);
        margin-bottom: calc(20vw / 4.14);
        li {
          font-size: calc(16vw / 4.14);
          line-height: 1.75;
          span {
            display: block;
          }
        }
      }
      &-notes {
        li {
          font-size: calc(16vw / 4.14);
          line-height: 1.75;
          color: #32395a;
        }
      }
    }
  }
  &__check {
    margin-top: calc(30vw / 4.14);
    padding-top: calc(30vw / 4.14);
    border-top: calc(3vw / 4.14) dotted #ccc;
    &-header {
      margin-bottom: calc(20vw / 4.14);
      .title-h3 {
        font-weight: bold;
        margin-bottom: calc(10vw / 4.14);
      }
      p {
        font-size: calc(16vw / 4.14);
        line-height: 1.75;
      }
    }
    &-warning {
      background: #fff5f5;
      border-radius: calc(10vw / 4.14);
      padding: calc(20vw / 4.14) calc(15vw / 4.14);
      h3 {
        font-weight: bold;
        font-size: 1.8rem;
        line-height: 2.1rem;
        padding-bottom: 2rem;
      }
      &--content {
        display: flex;
        justify-content: flex-start;
        padding: 0 calc(10vw / 4.14);
        flex-wrap: wrap;
        margin-top: calc(10vw / 4.14);
        &_box {
          width: 48.5%;
          height: calc(130vw / 4.14);
          background: #ffffff;
          border: calc(2vw / 4.14) solid $sub-color;
          border-radius: calc(10vw / 4.14);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          &:nth-child(even) {
            margin-left: auto;
          }
          &-content {
            text-align: center;
          }
          svg {
            width: calc(72vw / 4.14);
            height: auto;
          }
          p {
            font-weight: bold;
            font-size: calc(12vw / 4.14);
            line-height: 1.3;
            margin: 0;
          }
        }
        &_box + &_box + &_box {
          margin-top: calc(10vw / 4.14);
        }
      }
      &--footer {
        padding-top: calc(20vw / 4.14);
        p {
          font-size: calc(16vw / 4.14);
          line-height: 1.75;
          color: $body-text;
        }
      }
    }
    &-notification {
      margin-top: calc(20vw / 4.14);
      padding-bottom: calc(30vw / 4.14);
      border-bottom: calc(3vw / 4.14) dotted #ccc;
      margin-bottom: calc(30vw / 4.14);
      &--title {
        margin-bottom: calc(20vw / 4.14);
        p {
          font-size: calc(16vw / 4.14);
          line-height: 1.75;
        }
      }
      &--box {
      }
      &--content {
        &-header {
          background: #111947;
          color: #ffffff;
          font-size: calc(16vw / 4.14);
          line-height: 1;
          padding: calc(10vw / 4.14) 0;
          text-align: center;
        }
        &-content {
          background: #f1f3f8;
          padding: calc(20vw / 4.14) calc(15vw / 4.14);
          border-radius: 0 0 calc(10vw / 4.14) calc(10vw / 4.14);
          li {
            font-size: calc(16vw / 4.14);
            line-height: 1.75;
            text-indent: -1em;
            padding-left: 1em;
          }
        }
      }
      &--content + &--content {
        margin-top: calc(20vw / 4.14);
      }
    }
  }

  @media #{$tablets} {
    &__description {
      font-size: calc(16vw / 14);
      border-bottom: calc(3vw / 14) dotted $gray-divider;
      margin-bottom: calc(30vw / 14);
      padding-bottom: calc(30vw / 14);
    }
    &__clothes {
      &-title {
        font-size: calc(18vw / 14);
        margin-bottom: calc(10vw / 14);
      }
      &-content {
        padding: calc(20vw / 14) calc(30vw / 14);
        background-color: $bg-color-2;
        border-radius: calc(10vw / 14);
      }
      &-caution {
        font-size: calc(16vw / 14);
        margin-bottom: calc(30vw / 14);
        text-align: center;
      }
      &-list {
        position: relative;
        margin-bottom: calc(30vw / 14);
      }
      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: calc(2vw / 14) solid $sub-color;
        border-radius: calc(10vw / 14);
        padding: 0;
      }
    }
    &__clothe {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      width: 20%;
      &:last-child {
        .booking-attention__clothe-image {
          border-right: none;
        }
      }
      &-image {
        padding-top: calc(30vw / 14);
        width: 100%;
        border-right: calc(3vw / 14) dotted #ccc;
        height: calc(160vw / 14);
        svg {
          height: calc(112vw / 14);
          width: auto;
          max-width: auto;
        }
      }
      &-label {
        width: 100%;
        margin-top: calc(5vw / 4.14);
        font-size: calc(16vw / 14);
        margin-bottom: 7px !important;
      }
    }
    &__clothe + &__clothe {
      border-top: none;
    }
    &__cancel-icon {
      left: calc(-10vw / 14);
      top: calc(-15vw / 14);
      svg {
        width: calc(33vw / 14);
        height: calc(33vw / 14);
      }
    }
    &__other {
      &-cautions {
        &-title {
          margin-bottom: calc(10vw / 14);
        }
        &-list {
          li {
            font-size: calc(16vw / 14);
          }
        }
      }
    }
    &__removing {
      &-items {
        margin-top: calc(30vw / 14);
        padding-top: calc(30vw / 14);
        border-top: calc(3vw / 14) dotted #ccc;
        &-lead {
          font-size: calc(16vw / 14);
          line-height: 1.75;
          margin-bottom: calc(20vw / 14);
        }
        &-list {
          list-style-type: none;
          background-color: $white;
          padding: calc(20vw / 14) calc(15vw / 14);
          border-radius: calc(10vw / 14);
          margin-bottom: calc(20vw / 14);
          display: flex;
          li {
            font-size: calc(16vw / 14);
            line-height: 1.75;
          }
          li + li {
            margin-left: calc(50vw / 14);
          }
        }
        &-notes {
          li {
            font-size: calc(16vw / 14);
            line-height: 1.75;
            color: #32395a;
            text-indent: -1em;
            padding-left: 1em;
            br {
              display: none;
            }
          }
        }
      }
    }
    &__check {
      margin-top: calc(30vw / 14);
      padding-top: calc(30vw / 14);
      border-top: calc(3vw / 14) dotted #ccc;
      &-header {
        margin-bottom: calc(20vw / 14);
        .title-h3 {
          margin-bottom: calc(10vw / 14);
        }
        p {
          font-size: calc(16vw / 14);
        }
      }
      &-warning {
        border-radius: calc(10vw / 14);
        padding: calc(20vw / 14) calc(15vw / 14);
        &--content {
          display: flex;
          justify-content: space-between;
          padding: 0 calc(10vw / 14);
          flex-wrap: wrap;
          margin-top: calc(10vw / 14);
          &_box {
            width: percentage(140px / 1100px);
            height: calc(130vw / 14);
            border: calc(2vw / 14) solid $sub-color;
            border-radius: calc(10vw / 14);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            &:nth-child(even) {
              margin-left: 0;
            }
            &-content {
              text-align: center;
            }
            svg {
              width: calc(72vw / 14);
              height: auto;
            }
            p {
              font-size: calc(12vw / 14);
              font-weight: 700;
            }
          }
          &_box + &_box + &_box {
            margin-top: 0;
          }
        }
        &--footer {
          padding-top: calc(20vw / 14);
          p {
            font-size: calc(16vw / 14);
          }
        }
      }
      &-notification {
        margin-top: calc(20vw / 14);
        padding-bottom: calc(30vw / 14);
        border-bottom: calc(3vw / 14) dotted #ccc;
        margin-bottom: calc(30vw / 14);
        &--title {
          margin-bottom: calc(20vw / 14);
          p {
            font-size: calc(16vw / 14);
            line-height: 1.75;
          }
        }
        &--box {
          display: flex;
          align-items: stretch;
        }
        &--content {
          background: #f1f3f8;
          border-radius: 0 0 calc(10vw / 14) calc(10vw / 14);
          width: calc(50% - (calc(15vw / 14)));
          &:nth-child(even) {
            margin-left: auto;
          }
          &-header {
            color: #ffffff;
            font-size: calc(16vw / 14);
            line-height: 1;
            padding: calc(10vw / 14) 0;
            text-align: center;
          }
          &-content {
            background: #f1f3f8;
            padding: calc(20vw / 14) calc(15vw / 14);
            border-radius: 0 0 calc(10vw / 14) calc(10vw / 14);
            li {
              font-size: calc(16vw / 14);
            }
          }
        }
        &--content + &--content {
          margin-top: 0;
        }
      }
    }
  }

  @media #{$large-desktops} {
    &__description {
      font-size: 1.6rem;
      border-bottom: 0.3rem dotted $gray-divider;
      margin-bottom: 3rem;
      padding-bottom: 3rem;
    }
    &__clothes {
      &-title {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }
      &-content {
        padding: 2rem 3rem;
        border-radius: 1rem;
      }
      &-caution {
        font-size: 1.6rem;
        margin-bottom: 3rem;
      }
      &-list {
        margin-bottom: 3rem;
      }
      &-wrapper {
        border: 0.2rem solid $sub-color;
        border-radius: 1rem;
        padding: 0;
      }
    }
    &__clothe {
      padding: 0;
      &-image {
        padding-top: 3rem;
        border-right: 0.3rem dotted #ccc;
        height: 16rem;
        svg {
          height: 11.2rem;
        }
      }
      &-label {
        width: 100%;
        margin-top: 0.5rem;
        font-size: 1.6rem;
      }
    }
    &__cancel-icon {
      left: -1rem;
      top: -1.5rem;
      svg {
        width: 3.3rem;
        height: 3.3rem;
      }
    }
    &__other {
      &-cautions {
        padding: 0 3.5rem;
        &-title {
          margin-bottom: 1rem;
        }
        &-list {
          li {
            font-size: 1.6rem;
          }
        }
      }
    }
    &__removing {
      &-items {
        margin-top: 3rem;
        padding-top: 3rem;
        border-top: 0.3rem dotted #ccc;
        &-lead {
          font-size: 1.6rem;
          margin-bottom: 2rem;
          margin-left: 5rem;
        }
        &-list {
          padding: 2rem 4rem;
          border-radius: 1rem;
          margin-bottom: 2rem;
          li {
            font-size: 1.6rem;
          }
          li + li {
            margin-left: 5rem;
          }
        }
        &-notes {
          padding: 0 5rem;
          li {
            font-size: 1.6rem;
            text-indent: -1.5em;
            padding-left: 1em;
            br {
              display: block;
            }
          }
        }
      }
    }
    &__check {
      margin-top: 3rem;
      padding-top: 3rem;
      border-top: 0.3rem dotted #ccc;
      &-header {
        margin-bottom: 2rem;
        .title-h3 {
          margin-bottom: 1rem;
        }
        p {
          font-size: 1.6rem;
        }
      }
      &-warning {
        border-radius: 1rem;
        padding: 2rem 1.5rem;
        &--content {
          display: flex;
          padding: 0 1rem;
          flex-wrap: wrap;
          margin-top: 1rem;
          &_box {
            height: 13rem;
            width: 14rem;
            border: 0.2rem solid $sub-color;
            border-radius: 1rem;
            svg {
              width: 7.2rem;
            }
            p {
              font-size: 1.2rem;
            }
          }
          &_box + &_box + &_box {
            margin-top: 0;
          }
        }
        &--footer {
          padding-top: 2rem;
          p {
            font-size: 1.6rem;
          }
        }
      }
      &-notification {
        margin-top: 2rem;
        padding-bottom: calc(30vw / 14);
        border-bottom: 0.3rem dotted #ccc;
        margin-bottom: 3rem;
        &--title {
          margin-bottom: 2rem;
          p {
            font-size: 1.6rem;
          }
        }
        &--content {
          border-radius: 0 0 1rem 1rem;
          width: calc(50% - 1.5rem);
          &-header {
            font-size: 1.6rem;
            padding: 1rem 0;
          }
          &-content {
            padding: 2rem 1.5rem;
            border-radius: 0 0 1rem 1rem;
            li {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}
