.wrap-profile_friend {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 1rem;
  @media #{$mobile} {
    height: 14rem;
  }
  img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    margin-left: 1.5rem;
    object-fit: cover;
    @media #{$mobile} {
      margin-left: 1rem;
    }
  }
  .info-friend {
    margin-left: 1.5rem;
    height: 100%;
    padding-top: 2rem;
    width: 20rem;
    margin-right: 1.5rem;
    .name-friend {
      font-size: 2rem;
      line-height: 2.344rem;
      font-weight: bold;
      color: #111947;
      width: 13rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .avatar-and_friend {
      display: flex;
      width: 100%;
      padding-bottom: 1rem;
      padding-top: 0.6rem;
      border-bottom: 1px solid #ccc;
      @media screen and(max-width:320px) {
        flex-direction: column;
      }
      .avatar {
        margin-right: 2.8rem;
      }
      .count {
        padding-left: 0.6rem;
      }
      .avatar,
      .friend {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.6rem;
        text-align: left;
        vertical-align: middle;
        color: $body-text;
        .count {
          color: $blue-main;
          font-weight: bold;
        }
      }
    }
    .btn-friend {
      &_button {
        display: flex;
        justify-content: center;
        padding: 0.9rem 0;
        .button-register {
          width: 100%;
        }
      }
    }
  }
}
