.divider {
  border-bottom: 1px solid $gray-divider;
  height: 1px;
  width: 30rem;
  @media #{$mobiles} {
    width: 34.5rem;
  }
  @media #{$large-mobiles} {
    width: 50rem;
  }
  @media #{$tablets} {
    width: 63rem;
  }
}
