$gutter-textInput: 1rem;
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    position: relative;
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: $gutter-textInput;
  }
  &__label--wrapper {
    width: 100%;
  }
  &__label--field {
    width: 30rem;
    margin-bottom: 1.2rem;
    @media #{$mobiles} {
      width: 34.5rem;
    }
    @media #{$large-mobiles} {
      width: 50rem;
    }
  }
  &__label {
    font-weight: 700;
    font-size: calc(16vw / 4.14);
    line-height: 1.875rem;
    display: inline-block;
    color: $color-title;
    @media #{$tablets} {
      font-size: calc(16vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
    }
  }
  &__asterisk {
    color: red;
    font-size: 1.6rem;
    margin-left: 8px;
    display: inline-block;
  }
  &__field {
    width: 30rem;
    height: 3.8rem;
    font-size: 1.4rem;
    font-weight: 400;
    display: block;
    border: 1px solid $border-input;
    padding-left: 2rem;
    padding-right: 1.5rem;
    color: $body-text;
    background: transparent;
    border-radius: 0.5rem;
    position: relative;
    &:hover {
      border: 1px solid hsl(0, 0%, 70%);
    }
    &:focus {
      outline: none !important;
      border: 1px solid $border-input-focus;
    }
    &::placeholder {
      color: $placeholder-input;
    }
    @media #{$mobiles} {
      width: 34.5rem;
      height: 4.8rem;
    }
    @media #{$large-mobiles} {
      width: 50rem;
      height: 4.8rem;
    }
  }
  .input__radio {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: calc(24vw / 4.14);
    height: calc(24vw / 4.14);
    @media #{$tablets} {
      width: calc(24vw / 14);
      height: calc(24vw / 14);
    }
    @media #{$large-desktops} {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  .radio__input {
    display: flex;
    align-items: center;
    .radio__control {
      display: block;
      width: calc(24vw / 4.14);
      height: calc(24vw / 4.14);
      border-radius: 50%;
      border: 1px solid $blue-main;
      cursor: pointer;
      @include transition;
      @media #{$tablets} {
        width: calc(24vw / 14);
        height: calc(24vw / 14);
      }
      @media #{$large-desktops} {
        width: 2.4rem;
        height: 2.4rem;
      }

      &:disabled {
        cursor: default;
      }
      &:checked + .radio__control {
        background: radial-gradient($blue-main 50%, rgba(255, 0, 0, 0) 51%);
      }
      &:disabled + .radio__control {
        border-color: $gray-boder;
      }
    }
  }
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox {
  text-align: center;
  :root {
    --primary-color: #f44336;
  }

  input[type='checkbox'] {
    border: none;
    height: 14px;
    width: 14px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    &:focus,
    &:visited {
      border: none;
      box-shadow: none;
      outline: none;
    }
    &:disabled {
      cursor: default;
    }
  }

  input[type='checkbox']:before {
    content: '';
    display: inline-block;
    position: absolute;
    box-sizing: border-box;
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: 1px solid #767676;
    background-color: #fff;
  }

  input[type='checkbox']:hover::before {
    border: 1px solid #4f4f4f;
  }

  input[type='checkbox']:checked:hover::before {
    filter: brightness(90%);
  }

  input[type='checkbox']:checked:disabled:hover::before {
    filter: none;
  }

  input[type='checkbox']:checked:before {
    content: '';
    display: inline-block;
    position: absolute;
    box-sizing: border-box;
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: 1px solid var(--primary-color);
    background-color: $blue-main;
  }

  input[type='checkbox']:checked:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 2px;
    box-sizing: border-box;
    height: 5px;
    width: 10px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: translateY(-1.5px) rotate(-45deg);
    transform: translateY(-1.5px) rotate(-45deg);
  }

  &__label {
    margin-left: 5px;
    font-weight: 400;
    &--sub {
      font-size: 1.6rem;
      font-family: Roboto;
      color: #111947;
    }
    a {
      text-decoration: underline;
      font-size: 1.6rem;
      font-family: Roboto;
      color: $blue-main;
      font-weight: 400;
    }
  }
}

.error {
  border: 1px solid $red !important;
}

.form-field-error-message {
  width: 30rem;
  max-width: 100% !important;
  white-space: pre-line;
  @media #{$mobiles} {
    width: 34.5rem;
  }
  text-align: flex-start;
  @media #{$large-mobiles} {
    width: 50rem;
  }
  color: $red;
  font-size: $default-font-size-second;
  display: inline-block;
  margin-top: 0.6rem;
}

.input-label-gutter {
  &:not(:last-child) {
    margin-bottom: 2.3rem;
  }
}

.eye {
  position: absolute;
  right: 12px;
  top: 16px;
  display: flex;
  cursor: pointer;
  @media #{$mobile} {
    right: 3%;
  }
  @media screen and (min-width: 0px) and (max-width: 361px) {
    right: 6%;
  }
}
