$brand-pink: $sub-color;

.header-auth {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cecece;
  height: calc(60vw / 4.14 );
  padding-left: calc(15vw / 4.14 );
  padding-right: calc(15vw / 4.14 );
  background-color: #fff;
  z-index: 20;
  @media #{$tablets} {
	  height: calc(60vw / 14 );
	  padding-left: calc(30vw / 14 );
	  padding-right: calc(30vw / 14 );
  }
  @media #{$large-desktops} {
    padding: 0 3rem;
    height: 6rem
  }
  .logo{
	  width: calc( 135vw / 4.14 );
	  img,svg{
		  width: 100%;
		  height: auto;
	  }
	  @media #{$tablets} {
		  width: calc( 155vw / 14 );
	  }
		@media #{$large-desktops} {
		  width: 13.5rem;
	  }
	  
  }
  &__more-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__select-language {
    display: none;
    @media #{$tablets} {
      display: flex;
	    align-items: center;
	    padding-right: calc( 20vw / 14 );
    }
		@media #{$large-desktops} {
		   padding-right: 2rem;
	  }
    
  }
  &__group-auth-buttons {
    display: none;
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
	    a {
	      text-decoration: none;
	    }
      a+a{
	      margin-left: calc( 16vw / 14 );
      }
	    > button {
	      font-family: Montserrat;
	      width: calc( 132vw / 14 );
	      height: calc( 43vw / 14 );
		    font-size: calc( 12vw / 14 );
		    padding-left: calc( 5vw / 14 );
	      &.button-custom.auth-button {
				  border-width: 1px;
	      }
	      span {
	        font-size: calc( 16vw / 14 );
	        line-height: 1.6;
	        margin-left: calc( 10vw / 14 );
	        font-weight: 500;
	      }
	    }
	    button + button{
		    margin-left: calc( 16vw / 14 );
	    }  
      
    }
    @media #{$large-desktops} {
      a+a{
	      margin-left: 1.6rem;
      }
	    > button {
	      width: 10.4rem;
	      height: 3.2rem;
		    font-size: 1.4rem;
	      span {
	        font-size: 1.4rem;
	        margin-left: 1rem;
	      }
	    }
	    button + button{
		    margin-left: 1.6rem;
	    }  
      
      
    }
    
    .outline-1 svg,
    .secondary svg {
      path {
        transition: all 0.35s ease-in-out;
      }
    }
    .outline-1 {
      &:hover {
        background-color: $blue-main;
        color: $white;
        svg > path {
          fill: $white;
        }
      }
    }
    .secondary {
      &:hover {
        background-color: #ff5858;
      }
    }
  }
  &__register {
    font-family: Montserrat;
    font-weight: 500;
    svg > path {
      transition: all 0.35s ease-in-out;
    }
    &:hover {
      svg > path {
        fill: $white;
      }
      color: white;
      background-color: $blue-main !important;
    }
  }
  &__login {
    font-family: Montserrat;
    font-weight: 500;
    &:hover {
      background-color: #ff5858 !important;
    }
  }
  &_menu {
    cursor: pointer;
	  svg{
		  rect{
			  fill:#333;
		  }
	  }
    @media #{$tablets} {
      display: none;
    }
  }
}

.css-7rjw9w-control{ background: none !important;}
.demo-wrapper {
  @media #{$tablets} {
    padding-right: calc(20vw / 14);
    .css-1lar7m8-singleValue{
      svg{
	      width: calc( 18vw / 14 );
	      height: calc( 18vw / 14 );
      }
      .switch-languages__name{
	      padding-left: calc( 10vw / 14 );
	      font-size: calc( 14vw / 14 );
      }
      
    }
    [class$='menu'] {
	    width: calc(180vw / 14);
	    svg{
	      width: calc( 18vw / 14 );
	      height: calc( 18vw / 14 );
	    }
	    span{
				font-size: calc(16vw / 14);
				padding-left: calc( 10vw / 14 );
				
	    }
    }
    [class$='MenuList'] {
      & > div {
        padding: calc(15vw / 14);
      }
    }
  }
  @media #{$large-desktops} {
    padding-right: 2rem;
    .css-1lar7m8-singleValue{
      svg{
	      width: 1.8rem;
	      height:1.8rem;
      }
      .switch-languages__name{
	      padding-left: 1rem;
	      font-size: 1.4rem;
      }
      
    }
    [class$='menu'] {
	    width: 18rem;
	    svg{
	      width: 1.8rem;
	      height:1.8rem;
	    }
	    span{
				font-size: 1.4rem;
				padding-left: 1rem;
				
	    }
    }
    [class$='MenuList'] {
      & > div {
        padding:1.5rem;
      }
    }
    
  }
}
