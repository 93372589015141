.header-wrap {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cecece;
  height: calc(60vw / 4.14);
  padding-left: calc(15vw / 4.14);
  padding-right: calc(15vw / 4.14);
  background-color: $background-header;
  z-index: 20;
  @media #{$tablets} {
    height: calc(60vw / 14);
    padding-left: calc(30vw / 14);
    padding-right: calc(30vw / 14);
  }
  @media #{$large-desktops} {
    padding: 0 3rem;
    height: 6rem;
  }
  .logo {
    width: calc(135vw / 4.14);
    img,
    svg {
      width: 100%;
      height: auto;
    }
    @media #{$tablets} {
      width: calc(155vw / 14);
    }
    @media #{$large-desktops} {
      width: 13.5rem;
    }
  }
  .account {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img.avatar-top {
      width: calc(32vw / 4.14);
      height: calc(32vw / 4.14);
      margin-left: calc(20vw / 4.14);
      object-fit: cover;
      @media #{$tablets} {
        width: calc(32vw / 14);
        height: calc(32vw / 14);
        margin-left: calc(20vw / 14);
      }
      @media #{$large-desktops} {
        width: 3.2rem;
        height: 3.2rem;
        margin-left: 2rem;
      }
    }
    .wrap-status {
      display: none;
      @media #{$tablets} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__bell {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    svg {
      width: calc(24vw / 4.14);
      height: calc(24vw / 4.14);
      vertical-align: middle;
      &.bell {
        cursor: pointer;
      }
    }
    &--noti {
      position: absolute;
      z-index: 2;
      width: calc(20vw / 4.14);
      height: calc(16vw / 4.14);
      background-color: #fc5d3b;
      color: #fff;
      font-size: calc(10vw / 4.14);
      line-height: calc(1vw / 4.14);
      border-radius: calc(10vw / 4.14);
      display: flex;
      justify-content: center;
      align-items: center;
      top: calc(-6vw / 4.14);
      left: calc(12vw / 4.14);
      border: calc(2vw / 4.14) solid #111947;
      box-sizing: content-box;
    }
    @media #{$tablets} {
      svg {
        width: calc(24vw / 14);
        height: calc(24vw / 14);
      }
      &--noti {
        width: calc(20vw / 14);
        height: calc(16vw / 14);
        font-size: calc(10vw / 14);
        line-height: calc(1vw / 14);
        border-radius: calc(10vw / 14);
        top: calc(-6vw / 14);
        left: calc(12vw / 14);
        border: calc(2vw / 14) solid #111947;
      }
    }
    @media #{$large-desktops} {
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
      &--noti {
        width: 2rem;
        height: 1.6rem;
        font-size: 1rem;
        line-height: 1rem;
        border-radius: 1rem;
        top: -0.6rem;
        left: 1.2rem;
        border: 0.2rem solid #111947;
      }
    }
  }
  .demo-wrapper {
    @media #{$tablets} {
      padding-right: calc(20vw / 14);
      .css-1h2hba-singleValue {
        svg {
          width: calc(18vw / 14);
          height: calc(18vw / 14);
        }
        .switch-languages__name {
          padding-left: calc(10vw / 14);
          font-size: calc(14vw / 14);
        }
      }
      [class$='menu'] {
        width: calc(180vw / 14);
        svg {
          width: calc(18vw / 14);
          height: calc(18vw / 14);
        }
        span {
          font-size: calc(16vw / 14);
          padding-left: calc(10vw / 14);
        }
      }
      [class$='MenuList'] {
        & > div {
          padding: calc(15vw / 14);
        }
      }
    }
    @media #{$large-desktops} {
      padding-right: 2rem;
      .css-1h2hba-singleValue {
        svg {
          width: 1.8rem;
          height: 1.8rem;
        }
        .switch-languages__name {
          padding-left: 1rem;
          font-size: 1.4rem;
        }
      }
      [class$='menu'] {
        width: 18rem;
        svg {
          width: 1.8rem;
          height: 1.8rem;
        }
        span {
          font-size: 1.6rem;
          padding-left: 1rem;
        }
      }
      [class$='MenuList'] {
        & > div {
          padding: 1.5rem;
        }
      }
    }
  }
}
