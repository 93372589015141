.password {
  @include d-flex-column-center;
  margin-bottom: calc(50vw / 4.14);
  @media #{$tablets} {
    margin-bottom: calc(100vw / 14);
  }
  @media #{$large-desktops} {
    margin-bottom: 10rem;
  }
  &__title {
    margin: 0 auto 5.5rem;
    text-align: center;
  }
  #h1 {
    @media screen and (max-width: 767px) {
      width: 292px;
    }
  }
  &__describe {
    font-size: calc(16vw / 4.14);
    p {
      line-height: 1.75;
    }
    @media #{$tablets} {
      font-size: calc(16vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
    }
    line-height: 1.875rem;
    margin: 0 auto;
    margin-bottom: 3.4rem;
    text-align: center;
    color: $body-text;
    line-height: 1.75;
    &-content3 {
      font-weight: bold;
    }
    &-content1_link {
      color: #ff6c6c;
    }
    @media #{$mobile} {
      #forgot-title {
        text-align: left;
      }
    }
  }
  &__describe-success {
    text-align: center;
    color: $body-text;
    line-height: 1.75;
    font-size: calc(16vw / 4.14);
    @media #{$tablets} {
      font-size: calc(16vw / 14);
    }
    @media #{$small-desktops} {
      font-size: 1.6rem;
    }

    .password__describe-content1,
    .password__describe-content2 {
      line-height: 1.75;
      font-weight: 400;
    }
  }
  #forgotPw {
    @media #{$mobile} {
      display: none;
    }
  }
  #forgotPwMobile {
    display: none;
    @media #{$mobile} {
      display: flex;
      padding-left: 39px;
      padding-right: 39px;
      align-items: flex-start;
      .password__warning {
        &-icon {
          display: flex;
          align-items: center;
          > {
            div {
              padding-right: 0.8rem;
            }
            span {
              color: $body-text;
            }
          }
        }
        &-content {
          padding: 0;
          text-align: left;
        }
      }
    }
  }
  &__warning {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9f9f9;
    border-radius: 5px;
    @media #{$mobile} {
      justify-content: center;
    }
    @media #{$tablets} {
      flex-direction: row;
      width: 70rem;
      justify-content: center;
      align-items: center;
    }

    &-icon_noti {
      width: 100%;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @media #{$tablets} {
        flex-direction: row;
        justify-content: flex-start;
        svg {
          margin-left: 3.8rem;
        }
      }
    }
    &-content {
      font-weight: normal;
      line-height: 2.8rem;
      font-size: calc(14vw / 4.14);
      @media #{$tablets} {
        font-size: calc(14vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.4rem;
      }

      text-align: center;
      color: $body-text;
      padding: 0 0.8rem;
      @media #{$tablets} {
        padding: 0;
        padding-left: 0.8rem;
        padding-right: 2rem;
      }
      &_group-text {
        display: inline-flex;
        > span {
          margin: 0 2px;
        }
        &.reverse {
          flex-direction: row-reverse;
        }
      }
    }
    #fgPW {
      line-height: initial;
      @media #{$tablets} {
        padding-left: 3.8rem;
        width: 100%;
        * {
          line-height: 28px;
        }
      }
    }
    &-content_noti {
      text-align: left;
      @media #{$tablets} {
        padding-left: 5rem;
      }
      &-text {
        p {
          line-height: 1.75;
        }
      }
      &-text1 {
        padding-bottom: 2rem;
      }
    }
  }

  &__warning-noti {
    @media #{$tablets} {
      display: flex;
      flex-direction: column;
      width: 70rem;
      height: auto;
      border-radius: 0.5rem;
      margin: 0 auto 40px;
    }
  }

  &__send {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    &--spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &__field-wrapper {
    .input-password_component__field {
      padding-right: 5rem;
      @media #{$mobile} {
        padding-right: 4rem;
      }
    }
    .eye_password {
    }
    .input__label--wrapper {
      display: none;
    }
    .input-password_component__label--wrapper {
      display: none;
    }
  }
  .password__form {
    width: 100%;
    input {
      width: 100%;
    }
    @media #{$tablets} {
      width: 50rem;
      margin: auto;
    }
  }
  #forgot-title-main {
    @media #{$mobile} {
      padding: 0 33px;
    }
  }
  #forgot-title {
    text-align: left;
    @media #{$tablets} {
      text-align: center;
    }
  }
  #forgot-warning-icon {
    @media #{$mobile} {
      flex-direction: row;
      justify-content: flex-start;
      padding-left: 0.8rem;
    }
  }
  #signup-title-success {
    @media screen and (max-width: 375px) {
      max-width: 29.2rem;
    }
  }
}

.width-limit {
  width: 100%;
  @media #{$mobiles} {
    max-width: 32.4rem;
  }
}

.reset-password__form {
  .password__field-wrapper {
    .input__label--wrapper {
      display: block;
    }
    .input-password_component__label--wrapper {
      display: flex;
    }
  }
}
