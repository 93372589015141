@import './variables.scss';

// text for content (  2.本文テキスト)
.text-bold-large-black {
  font-weight: $fontWeigth;
  font-size: $fontContentLarge;
  line-height: $lineHeightContentVeryThick;
  color: $colorTextContent;
}

.text-bold-large-white {
  font-weight: $fontWeigth;
  font-size: $fontContentLarge;
  line-height: $lineHeightContentThick;
  color: $white;
}

.text-bold-medium {
  font-weight: $fontWeigth;
  font-size: $fontContentMedium;
  line-height: $lineHeightContentMedium;
  color: $colorTextContent;
}

.text-bold-small {
  font-weight: $fontWeigth;
  font-size: calc(16vw / 4.14);
  line-height: $lineHeightContentThin;
  color: $colorTextContent;
  @media #{$tablets} {
    font-size: calc(16vw / 14);
  }
  @media #{$large-desktops} {
    font-size: $fontContentFit;
  }
}

.large-body-text {
  font-weight: $fontWeigthNormal;
  font-size: calc(16vw / 4.14);
  line-height: (28/16);
  color: $colorTextContent;
  @media #{$tablets} {
    font-size: calc(16vw / 14);
  }
  @media #{$large-desktops} {
    font-size: 1.4rem;
  }
}

.large-content-text {
  font-weight: $fontWeigthNormal;
  font-size: calc(16vw / 4.14);
  line-height: (28/16);
  color: $colorTextContent;
  @media #{$tablets} {
    font-size: calc(16vw / 14);
  }
  @media #{$large-desktops} {
    font-size: 1.6rem;
  }
}

.medium-text-dark {
  font-weight: $fontWeigthNormal;
  font-size: $fontContentSmall;
  line-height: $lineHeightContentThick;
  color: $colorTextContent;
}

// text for modal ( 3.ポップアップテキスト)
.pop-text-bold-large {
  font-weight: $fontWeigth;
  font-size: $fontModalLarge;
  line-height: $lineHeightModalVeryThick;
  color: $colorText;
}

.pop-text-bold-big {
  font-weight: $fontWeigth;
  font-size: $fontModalBig;
  line-height: $lineHeightModalThick;
  color: $colorText;
  &.text-error {
    color: $colorTextError;
  }
  &.text-warning {
    color: $colorTextWarning;
  }
}

.pop-text-bold-medium {
  font-weight: $fontWeigth;
  font-size: $fontModalMedium;
  line-height: $lineHeightModalMedium;
  color: $colorText;
}

.pop-text-bold-normal {
  font-weight: $fontWeigth;
  font-size: $fontModalFit;
  line-height: $lineHeightModalThin;
  color: $colorText;
}

.pop-large-body-text {
  font-weight: $fontWeigthNormal !important;
  font-size: $fontModalFit !important;
  line-height: $lineHeightModalVeryThick;
  color: $colorText !important;
}

.pop-medium-body-text {
  font-weight: $fontWeigthNormal;
  font-size: $fontModalSmall;
  line-height: $lineHeightModalVeryThick;
  color: $colorText;
}

.pop-big-body-text {
  font-weight: $fontWeigthModalMedium !important;
  font-size: $fontModalNomal !important;
  line-height: $lineHeightModalVeryThick !important;
  color: $colorText !important;
}

.pop-big-bold-text {
  font-weight: $fontWeigth !important;
  font-size: $fontModalNomal !important;
  line-height: $lineHeightModalVeryThick !important;
  color: $colorText !important;
  &.text-error {
    color: $colorTextError !important;
  }
}

// text header notication
.text-header-noti {
  font-weight: $fontWeigth;
  font-size: 1.4rem;
  line-height: 2.8rem;
  color: $colorTextContent;
}

// text button
.text-button-medium {
  font-weight: $fontWeigth !important;
  font-size: $fontContentFit !important;
}
