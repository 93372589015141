@mixin fontSize($fontSize) {
  font-size: $fontSize;
  line-height: lh($fontSize);
}

@mixin heading($fontSize) {
  @include fontSize($fontSize);
  color: $color-title;
}

@mixin text-link() {
  color: $blue-main;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: $blue-main;
  }
}
@mixin text-link-calc($vw, $denominator) {
  @include text-link();
  font-size: calc($vw / $denominator);
}

@mixin text-link-rem($fontSize){
  @include text-link();
  @include fontSize($fontSize);
}

@mixin d-flex-column {
  display: flex;
  flex-direction: column;
}

@mixin d-flex-row-center {
  display: flex;
  align-items: center;
}

@mixin d-flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin transition {
  transition: all 0.15s linear;
}

@mixin flex-column {
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
}
@mixin transition {
  transition: all 0.15s linear;
}
@mixin text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
