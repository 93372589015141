.mobile {
  &-content {
    &__header {
      border: 1px solid;
      border-radius: 8px;
      margin: 10px calc( 15vw / 4.14) 0 calc( 15vw / 4.14);
      .switch-languages-top {
        [class$='control'] {
          border-radius: 8px;
          width: 100%;
        }
        [class$='menu'] {
          width: 100%;
        }
      } 
    }       
  }
}