$gutter-textInput: 1rem;
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.input-password_component {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__label--wrapper {
    width: 100%;
    margin-bottom: 5px;
  }
  &__asterisk {
    color: red;
    font-size: 1.6rem;
    margin-left: 8px;
    display: inline-block;
  }
  &__field {
    padding-right: 4rem;
    width: 100%;
    height: 4.5rem;
    font-size: 1.4rem;
    font-weight: 400;
    display: block;
    border: 1px solid $border-input;
    padding-left: 1.5rem;
    color: $body-text;
    background: transparent;
    border-radius: 0.5rem;
    &:hover {
      border: 1px solid hsl(0, 0%, 70%);
    }
    &:focus {
      outline: none !important;
      border: 1px solid $border-input-focus;
    }
    &::placeholder {
      color: $placeholder-input;
    }
  }
  .error {
    border: 1px solid $red;
  }
}

.group-input {
  position: relative;
  width: 50rem;
  @media only screen and #{$mobile} {
    width: 34.5rem;
    @media only screen and #{$mobile-small} {
      width: 25rem;
    }
  }
  .eye_password {
    position: absolute;
    right: 26px;
    top: 16px;
    cursor: pointer;
    @media #{$mobile} {
      right: 10px;
    }
  }
}
.group-input-modal {
  position: relative;
  width: 100%;
  .eye_password {
    position: absolute;
    right: 26px;
    top: 16px;
    cursor: pointer;
    @media #{$mobile} {
      right: 10px;
    }
  }
}
