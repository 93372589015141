.header-top {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.8rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: transparent;
  z-index: 20;
  @media #{$mobiles} {
    padding: 2.8rem 2.3rem 0;
  }
  @media #{$small-desktops} {
    padding-top: 5rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  &__logo {
    cursor: pointer;
    svg {
      width: 18.6rem;
      height: 4rem;
      @media #{$tablets} {
        width: 23.5rem;
      }
    }
  }
  &__more-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__select-language {
    display: none;
    @media #{$tablets} {
      display: flex;
    }
    align-items: center;
    padding-right: 2rem;
  }
  &__group-auth-buttons {
    display: none;
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
    }
    a {
      text-decoration: none;
    }
    a:nth-child(2) {
      margin-left: 1.6rem;
    }
    > button {
      font-family: Montserrat;
      width: 13.2rem;
      height: 4.3rem;
      span {
        font-size: 1.6rem;
        line-height: 1.68rem;
        margin-left: 1rem;
        font-weight: 500;
      }
    }
    > button:not(:first-child) {
      margin-left: 1.6rem;
    }
    .outline-1 svg,
    .secondary svg {
      path {
        transition: all 0.35s ease-in-out;
      }
    }
    .outline-1 {
      &:hover {
        background-color: $blue-main;
        color: $white;
        svg > path {
          fill: $white;
        }
      }
    }
    .secondary {
      &:hover {
        background-color: #ff5858;
      }
    }
  }
  &__menu {
	cursor: pointer;
    svg > rect {
      fill: #313232;
    }
  }
  &__register {
    color: $blue-main;
    border: 2px solid $blue-main;
    background-color: transparent;
  }
  &__login {
    color: $white;
    background-color: $brand-pink;
    outline: none;
    border: 1px solid $brand-pink;
  }
  &__menu {
    @media #{$tablets} {
      display: none;
    }
  }
}

.header-top{
	&.is-news{
	  position: sticky;
	  top: 0;
	  left: 0;
	  display: flex;
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	  border-bottom: 1px solid #cecece;
	  height: calc(60vw / 4.14 );
	  padding-left: calc(15vw / 4.14 );
	  padding-right: calc(15vw / 4.14 );
	  background-color: #fff;
	  padding-top: 0;
	  z-index: 20;
	  @media #{$tablets} {
		  padding-top: 0;
		  padding-bottom: 0;
		  height: calc(60vw / 14 );
		  padding-left: calc(30vw / 14 );
		  padding-right: calc(30vw / 14 );
	  }
	  @media #{$large-desktops} {
	    padding: 0 3rem;
	    height: 6rem
	  }
	  
	  .header-top__logo{
		  width: calc( 135vw / 4.14 );
		  img,svg{
			  width: 100%;
			  height: auto;
		  }
		  @media #{$tablets} {
			  width: calc( 155vw / 14 );
		  }
			@media #{$large-desktops} {
			  width: 13.5rem;
		  }
	  }
	  .header-top__more-selection {
	    display: flex;
	    flex-direction: row;
	    justify-content: space-between;
	    align-items: center;
	  }
	  .header-top__select-language {
	    display: none;
	    @media #{$tablets} {
	      display: flex;
		    align-items: center;
		    padding-right: 0;
	    }
	    
	  }
	  .header-top__group-auth-buttons {
	    display: none;
	    @media #{$tablets} {
	      display: flex;
	      flex-direction: row;
		    a {
		      text-decoration: none;
		    }
	      a+a{
		      margin-left: calc( 16vw / 14 );
	      }
		    > button {
		      font-family: Montserrat;
		      width: calc( 132vw / 14 );
		      height: calc( 43vw / 14 );
			    font-size: calc( 12vw / 14 );
			    padding-left: calc( 5vw / 14 );
		      &.button-custom.auth-button {
					  border-width: 1px;
		      }
		      span {
		        font-size: calc( 16vw / 14 );
		        line-height: 1.6;
		        margin-left: calc( 10vw / 14 );
		        font-weight: 500;
		      }
		    }
		    button + button{
			    margin-left: calc( 16vw / 14 );
		    }  
	      
	    }
	    @media #{$large-desktops} {
	      a+a{
		      margin-left: 1.6rem;
	      }
		    > button {
		      width: 13.2rem;
		      height: 3.2rem;
			    font-size: 1.4rem;
		      span {
		        font-size: 1.4rem;
		        margin-left: 1rem;
		      }
		    }
		    button + button{
			    margin-left: 1.6rem;
		    }  
	      
	      
	    }
	    
	    .outline-1 svg,
	    .secondary svg {
	      path {
	        transition: all 0.35s ease-in-out;
	      }
	    }
	    .outline-1 {
	      &:hover {
	        background-color: $blue-main;
	        color: $white;
	        svg > path {
	          fill: $white;
	        }
	      }
	    }
	    .secondary {
	      &:hover {
	        background-color: #ff5858;
	      }
	    }
	  }
	  .header-top__register {
	    font-family: Montserrat;
	    font-weight: 500;
	    svg > path {
	      transition: all 0.35s ease-in-out;
	    }
	    &:hover {
	      svg > path {
	        fill: $white;
	      }
	      color: white;
	      background-color: $blue-main !important;
	    }
	  }
	  .header-top__login {
	    font-family: Montserrat;
	    font-weight: 500;
	    &:hover {
	      background-color: #ff5858 !important;
	    }
	  }
	  .header-top_menu {
		  svg{
			  rect{
				  fill:#333;
			  }
		  }
	    @media #{$tablets} {
	      display: none;
	    }
	  }
	  [class$='control']{ 
		  background: none !important;
		 }
		.switch-languages-top {
		  @media #{$tablets} {
		    padding-right: calc(20vw / 14);
		    [class$='control'] {
		      min-height: 0;
		      font-size: calc(16vw / 4.14);
		      width: calc(140vw / 4.14);
		      height: calc(43vw / 4.14);
		      border-radius: calc(24vw / 4.14);
		      line-height: 1.485;
		      @media #{$tablets} {
		        width: calc(140vw / 14);
		        height: calc(43vw / 14);
		        border-radius: calc(24vw / 14);
		        font-size: calc(16vw / 14);
		      }
		      @media #{$large-desktops} {
		        width: 14rem;
		        height: 4.3rem;
		        border-radius: 2.4rem;
		        font-size: 1.6rem;
		      }
		    }
				    
		    
		    [class$='singleValue']{
		      svg{
			      width: calc( 18vw / 14 );
			      height: calc( 18vw / 14 );
		      }
		      .switch-languages-top__name{
			      padding-left: calc( 10vw / 14 );
			      font-size: calc( 16vw / 14 );
		      }
		      
		    }
		    [class$='menu'] {
			    width: calc(180vw / 14);
			    svg{
			      width: calc( 18vw / 14 );
			      height: calc( 18vw / 14 );
			    }
			    span{
						font-size: calc(16vw / 14);
						padding-left: calc( 10vw / 14 );
						
			    }
		    }
		    [class$='MenuList'] {
		      & > div {
		        padding: calc(15vw / 14);
		      }
		    }
		    [class$='indicatorContainer']{
			    span{
				    width: calc( 12vw / 14 );
				    height: calc( 8vw / 14 );
			    }
		    }
		  }
		  @media #{$large-desktops} {
		    padding-right: 2rem;
		    [class$='singleValue']{
		      svg{
			      width: 1.8rem;
			      height:1.8rem;
		      }
		      .switch-languages-top__name{
			      padding-left: 1rem;
			      font-size: 1.6rem;
		      }
		      
		    }
		    [class$='menu'] {
			    width: 18rem;
			    svg{
			      width: 1.8rem;
			      height:1.8rem;
			    }
			    span{
						font-size: 1.6rem;
						padding-left: 1rem;
						
			    }
		    }
		    [class$='MenuList'] {
		      & > div {
		        padding:1.5rem;
		      }
		    }
		    
		  }
		}
	  
	  
	}
  
  
  
}

