.clndr-calendars-segment {
  text-align: center;
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}

.clndr-calendars-segment.clndr-floating {
  position: absolute !important;
  z-index: 2000;
}

.clndr-calendars-wrapper {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(var(--n, 1), 1fr);
}

.clndr-control {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 10px;
  align-items: center;
}

.clndr-days {
  text-align: center;
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(7, minmax(2.2rem, 1fr));
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.28571429rem;
  overflow: hidden;
}

.clndr-left {
  left: 0;
}

.clndr-right {
  right: 0;
}

.clndr-top {
  bottom: 100%;
}

.clndr-bottom {
  top: 100%;
}

.clndr-calendars-segment.clndr-top {
  box-shadow: 0 -1px 2px 0 rgba(34, 36, 38, 0.15) !important;
  margin-bottom: 0.25rem !important;
}
