.wrap-detail-noti {
  @media #{$tablets} {
    padding-bottom: 1.6rem;
  }
  .noti-wrap {
    &_content {
      white-space: pre-line;
      a {
        cursor: pointer;
      }
      p {
	      font-size: calc( 16vw / 4.14 );
	      line-height: 2;
        color: #111947;
      }
    }
	  @media #{$tablets} {
	    &_content {
	      p {
		      font-size: calc( 16vw / 14 );
	      }
	    }
	  }
	  @media #{$large-desktops} {
	    &_content {
	      p {
		      font-size: 1.6rem;
	      }
	    }
	  }
  }
}
.footer-wrap {
  @media #{$mobile} {
    display: block !important;
  }
}
