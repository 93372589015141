.menuMobiles {
  box-sizing: border-box;
  $link-color: #313232;
  height: 100%;
  a:hover,
  a:active {
    text-decoration: none;
  }
  &__header--wrap-close {
    cursor: pointer;
    position: absolute;
    top: 2.8rem;
    right: 2.8rem;
    svg{
	    width: calc( 24vw / 4.14 );
	    height: calc( 24vw / 4.14 );
    }
  }
  &__body {
    padding-top: calc( 120vw / 4.14 );
    padding-left: calc( 40vw / 4.14 );
    &--button {
      a {
        display: block;
      }
      &-auth,
      &-redirect {
        font-family: Montserrat;
        font-size: 1.6rem;
        font-weight: 500;
        width: 22rem;
        height: 4.3rem;
        cursor: pointer;
        outline: none;
        border: none;
        border-radius: 2.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        span {
          padding-left: 0.8rem;
        }
      }
      &-redirect {
        background-color: #fff;
        border: 2px solid $blue-main;
        color: $blue-main;
        margin-bottom: 3.2rem;
      }
      &-auth {
        background: $sub-color;
        color: #fff;
      }
    }
    &--link {
      padding-top: calc( 60vw / 4.14 );
      * { color: $link-color; }
      &-content {
        cursor: pointer;
        &:not(:last-child) {
          padding-bottom: calc( 45vw / 4.14 );
        }
        span:first-child {
          font-family: Montserrat;
          font-size: calc( 18vw / 4.14 );
          font-weight: 500;
          letter-spacing: calc( .3vw / 4.14 );
        }
        span:nth-child(2) {
          font-family: 'M PLUS 1p';
          font-size: calc( 16vw / 4.14 );
          font-weight: 500;
          letter-spacing: calc( .3vw / 4.14 );
          margin-left: calc( 20vw / 4.14 );
        }
      }
    }
  }
  &-content {
    &__footer {
      position: fixed;
      bottom: calc( 26vw / 4.14 );
      width: 100%;
      padding-top: calc( 10vw / 4.14 );
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #3132321a;
      .css-2b097c-container{
	      position: relative;
	      box-sizing: border-box;
      }
      .switch-languages-top{
		    [class$='control'] {
		      min-height: 0;
		      font-size: calc(16vw / 4.14);
		      width: calc(140vw / 4.14);
		      height: calc(43vw / 4.14);
		      border-radius: calc(24vw / 4.14);
		      line-height: 1.485;
		      @media #{$tablets} {
		        width: calc(140vw / 14);
		        height: calc(43vw / 14);
		        border-radius: calc(24vw / 14);
		        font-size: calc(16vw / 14);
		      }
		      @media #{$large-desktops} {
		        width: 14rem;
		        height: 4.3rem;
		        border-radius: 2.4rem;
		        font-size: 1.6rem;
		      }
		    }	 
		    [class$='menu'] {
		      font-size: calc(16vw / 4.14);
		      line-height: 1.485;
		      width: calc(180vw / 4.14);
		      @media #{$tablets} {
		        font-size: calc(16vw / 14);
		        width: calc(180vw / 14);
		      }
		      @media #{$large-desktops} {
		        font-size: 1.6rem;
		        width: 18rem;
		      }
				}
		  }
    }
  }
}
