.user-profile {
  &_default-padding {
    flex-grow: 1;
    padding: 2rem 1.5rem 0 1.5rem;

    max-width: 100%;
    @media #{$small-desktops} {
      width: 111rem;
      margin: 0 auto;
    }
    @media #{$large-desktops} {
      padding: 3rem 0 0;
      width: 111rem;
      margin: 0 auto;
    }
  }
  .message {
    margin-top: 20px;
    text-align: center;
  }

  &_content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    align-items: center;
    justify-content: center;
    grid-gap: 3rem;
    margin-bottom: 2.8rem;
    @media #{$mobile} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 1.5rem;
      padding-top: 0;
    }
    & > .wrap-render_avatar {
      @media #{$mobile} {
        max-height: 22rem !important;
        min-height: auto !important;
      }
    }
    .wrap-render_avatar-detail {
      cursor: pointer;
    }
  }
  &_content__friend {
    .infinite-scroll-component {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      align-items: center;
      justify-content: center;
      grid-column-gap: 3rem;
      grid-row-gap: 2rem;
      margin-bottom: 2.8rem;
      @media #{$mobile} {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 2rem;
      }
      @media screen and(min-width:768px) and(max-width:1024px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
.wrap-sort-select {
  display: flex;
  justify-content: flex-end;
  @media screen and(max-width:375px) {
    margin-top: 0;
  }
  &_icon {
    outline: none;
    cursor: pointer;
    background: none;
  }
  .input_sort {
    width: 17.3rem;
    div[class$='ValueContainer'] {
      height: 100%;
    }

    div[class$='IndicatorsContainer'] {
      position: relative;
      height: 100%;
    }
  }
}
.tab-menu {
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  .avatar-tab,
  .friend-tab {
    @media #{$mobile} {
      width: 100%;
    }
    min-width: 15.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: $body-text;
    line-height: 24px;
    font-weight: bold;
    cursor: pointer;
    opacity: 0.6;
    border-bottom: 3px solid transparent;
    @include transition;
    &.activeTab {
      border-color: $blue-main;
      color: $body-text;
      opacity: 1 !important;
    }
  }
  .avatar-tab {
    margin-right: 2rem;
    @media #{$mobile} {
      margin-right: 0rem;
    }
  }
}

.banner-avatar.ui.fullscreen.modal {
  @media #{$large-mobiles} {
    width: 400px !important;
  }
  @media #{$large-desktops} {
    width: 570px !important;
  }
  left: 50% !important;
  transform: translate(-50%, -50%);
  top: 50%;

  background: #ccc;
  border-radius: 15px;
  .close.icon {
    top: 10px;
    right: 10px;
    background: lighten(#000000, 10%);
    border-radius: 50%;
  }
  .image {
    .wrap-render_avatar-detail {
      max-width: initial;
      width: 100%;
      flex: none;
    }
  }
}
.ui.dimmer {
  z-index: 99 !important;
}
.modal-delete-friend.ui.modal {
  padding: calc(50vw / 4.14) calc(15vw / 4.14) calc(30vw / 4.14);
  box-shadow: 0px calc(4vw / 4.14) calc(15vw / 4.14) rgba(0, 0, 0, 0.15);
  border-radius: calc(10vw / 4.14);
  width: calc(100% - 40px);
  .close-icon {
    position: absolute;
    top: calc(20vw / 4.14);
    right: calc(15vw / 4.14);
    svg {
      width: calc(18vw / 4.14);
      height: calc(18vw / 4.14);
    }
  }
  .header {
    font-family: Roboto;
    padding: 0 0 calc(16vw / 4.14) 0 !important;
    color: $body-text;
    border-bottom: 1px solid $gray-divider;
    height: initial;
    text-align: center;
    line-height: 1.75;
    font-size: calc(20vw / 4.14);
  }
  .content {
    padding: calc(30vw / 4.14) 0 !important;
    .description {
      text-align: center;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      p {
        font-size: calc(16vw / 4.14);
        line-height: 1.75;
        color: $body-text;
      }
    }
  }
  .actions {
    border: none;
    background: transparent;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    button:last-child {
      margin-left: calc(15vw / 4.14);
    }
  }
  @media #{$tablets} {
    padding: calc(25vw / 14) calc(15vw / 14) calc(30vw / 14);
    box-shadow: 0px calc(4vw / 14) calc(15vw / 14) rgba(0, 0, 0, 0.15);
    border-radius: calc(10vw / 14);
    width: 100%;
    max-width: calc(600vw / 14);
    .close-icon {
      top: calc(20vw / 14);
      right: calc(15vw / 14);
      svg {
        width: calc(18vw / 14);
        height: calc(18vw / 14);
      }
    }
    .header {
      font-family: Roboto;
      padding: 0 0 calc(16vw / 14) 0 !important;
      font-size: calc(20vw / 14);
    }
    .content {
      padding: calc(30vw / 14) 0 !important;
      .description {
        p {
          font-size: calc(16vw / 14);
        }
      }
    }
    .actions {
      button:last-child {
        margin-left: calc(15vw / 14);
      }
    }
  }
  @media #{$large-desktops} {
    padding: 2.5rem 1.5rem 3rem;
    box-shadow: 0px 0.4rem 1.5rem rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    max-width: 60rem;
    .close-icon {
      top: 2rem;
      right: 1.5rem;
      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
    .header {
      padding: 0 0 1.6rem 0 !important;
      font-size: 2rem;
    }
    .content {
      padding: 3rem 0 !important;
      .description {
        p {
          font-size: 1.6rem;
        }
      }
    }
    .actions {
      button:last-child {
        margin-left: 1.5rem;
      }
    }
  }
}
