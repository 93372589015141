.facebook-button {
  box-sizing: border-box;
  background-color: #1877F2;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: transparent;
  min-width: 20rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  line-height: 1.641rem;
  padding: 0;
  border-width: 0;
  outline: none;
  height: 4rem;
  display: flex;
  cursor: pointer;
  @media #{$mobile} {
    min-width: 27rem;
  }
  &:active {
    transform: translateY(1px);
  }
  svg {
    position: relative;
    left: 0.9rem;
  }
  .btn-link{
    flex:1;
  }
  a {
    color: $white;
    font-family: Roboto;
    margin-bottom: 0;
    margin-top: 0;
    text-decoration: none;
    @media #{$mobile} {
      left: 4.7rem;
    }
  }
}
