.wrap-modal-noti {
  &:before {
    display: none;
  }
  &__header {
    background-color: #111947;
    display: flex;
    height: calc(60vw / 4.14);
    padding-left: calc(15vw / 4.14);
    align-items: center;
    @media #{$tablets} {
      display: none;
    }
  }
  .opacity {
    display: none;
    @media #{$tablets} {
      position: fixed;
      display: block;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0.6;
      z-index: 2;
      top: calc(60vw / 14);
    }
    @media #{$large-desktops} {
      top: 6rem;
    }
  }
  .noti-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0rem;
    border-radius: 0;
    box-shadow: none;
    background: #fff;
    z-index: 2;
    &_header {
      display: flex;
      align-items: center;
      padding: calc(20vw / 4.14) calc(20vw / 4.14) calc(10vw / 4.14) calc(15vw / 4.14);
      border-bottom: 1px solid #ccc;
      &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        svg {
          margin-right: calc(10vw / 4.14);
        }
        .box-noti-ttle {
          line-height: 1.75;
          font-weight: bold;
          font-size: calc(20vw / 4.14);
          color: #111947;
          font-weight: 700;
        }
      }
    }
    &_content {
      overflow-y: auto;
      margin-bottom: auto;
      overflow-x: hidden;
      height: calc(100vh - 50vw);
      > div {
        padding-left: calc(20vw / 4.14);
        padding-right: calc(20vw / 4.14);
      }
      .wrap-content {
        .text_content {
          .noti_title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .noti_content {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
      }
    }
    &__register {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      height: calc(80vw / 4.14);
      margin: 0;
      padding: 0 calc(20vw / 4.14);
      button {
        width: 100%;
      }
      &--spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    a {
      text-decoration: none;
    }
    @media #{$tablets} {
      width: calc(332vw / 14);
      position: absolute;
      top: calc(50vw / 14);
      right: calc(-40vw / 14);
      z-index: 100;
      border-radius: calc(10vw / 14);
      height: auto;
      box-shadow: 0px calc(4vw / 14) calc(20vw / 14) rgba(0, 0, 0, 0.15);
      &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        right: calc(40vw / 14);
        height: 0;
        width: 0;
        border-style: solid;
        border-width: calc(10vw / 14);
        border-color: transparent;
        border-bottom-color: white;
        z-index: 11;
      }
      &_header {
        padding: calc(20vw / 14) calc(20vw / 14) calc(10vw / 14) calc(15vw / 14);
        &-title {
          svg {
            margin-right: calc(10vw / 14);
          }
          .box-noti-ttle {
            font-size: calc(20vw / 14);
          }
        }
      }
      &_content {
        overflow-y: auto;
        margin-bottom: auto;
        overflow-x: hidden;
        height: calc(100vh - 40vh);
        > div {
          padding: calc(15vw / 14);
          border-bottom: 1px solid #ccc;
        }
        .wrap-header {
          .box-status {
            margin: 0;
						white-space: nowrap;
          }
          .box_circle {
            margin: 0;
          }
        }
        .wrap-content {
          padding: 0;
          border-bottom: none;
        }
      }
      &__register {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        height: calc(80vw / 14);
        margin: 0;
        padding: 0 calc(20vw / 14);
      }
    }
    @media #{$large-desktops} {
      width: 33.2rem;
      top: 5rem;
      right: -4rem;
      border-radius: 1rem;
      height: auto;
      box-shadow: 0px 0.4rem 2rem rgba(0, 0, 0, 0.15);
      &::before {
        right: 4rem;
        border-width: 1rem;
      }
      &_header {
        padding: 2rem 2rem 1.5rem 2rem;
        &-title {
          svg {
            margin-right: 1rem;
          }
          .box-noti-ttle {
            font-size: 2rem;
          }
        }
      }
      &_content {
        > div {
          padding: 1.5rem;
        }
      }
      &__register {
        height: 8rem;
        margin: 0;
        padding: 0 2rem;
      }
    }
  }
}

.footer-wrap {
  @media #{$mobile} {
    display: none !important;
  }
}
