
.news {
	padding-top: calc( 35vw / 4.14 );
	&__banner-area {
		margin-bottom: calc( 30vw / 4.14 );
		position: relative;
	}
	&__banner-background{
		text-align: center;
		svg{
			width: calc( 250vw / 4.14 );
			height: auto;
		}
	}
	&__banner-content{
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50% );
		svg{
			width: calc( 26vw /4.14 );
			height: auto;
			vertical-align: middle;
			margin-right: calc( 10vw / 4.14 );
		}
	}
	&__banner-title{
		color: $body-text;
		font-size: calc( 24vw / 4.14 );
		font-weight: 700;
		font-family: "Roboto";
	}
  &__grid-area {
	  margin-bottom: calc( 50vw / 4.14 );
  }
  &__grid-hero-image {
	  margin-bottom: calc( 20vw / 4.14 );
    img {
      width: 100%;
      height: calc( 220vw / 4.14 );
      object-fit:cover;
      border-radius: calc( 10vw / 4.14 );
      vertical-align: bottom;
    }
  }
  &__grid-date-publish {
	  font-size: calc( 14vw / 4.14 );
	  color: #727488;
	  margin-bottom: calc( 5vw / 4.14 );
  }
  &__grid-title {
	  margin-bottom: calc( 10vw / 4.14 );
	  word-break: break-word;
    a {
      color: $color-title;
      text-decoration: none;
      font-size: calc( 20vw / 4.14 );
      line-height: (26/20);
      font-weight: 700;
      color: $body-text;
    }
  }
  &__grid-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    p{
	    font-size: calc( 16vw / 4.14 );
	    line-height: (32/16);
	    color: $body-text;
    }
  }
	@media #{$tablets} {
		padding-top: calc( 35vw / 14 );
		&__banner-area {
			margin-bottom: calc( 30vw / 14 );
		}
		&__banner-background{
			svg{
				width: calc( 440vw / 14 );
			}
		}
		&__banner-content{
			transform: translate(-50%, -50% );
			svg{
				width: calc( 26vw /14 );
				margin-right: calc( 10vw / 14 );
			}
		}
		&__banner-title{
			font-size: calc( 50vw / 14 );
		}
	  &__grid-area {
		  width: 32%;
		  margin: 0 2% 2% 0;
		  &:nth-child(3n){margin-right: 0;}
	  }
	  &__grid-hero-image {
		  margin-bottom: calc( 20vw / 14 );
	    img {
	      height: calc( 270vw / 14 );
	      width: 100%;
	      border-radius: calc( 10vw / 14 );
	    }
	  }
	  &__grid-date-publish {
		  font-size: calc( 14vw / 14 );
		  margin-bottom: calc( 5vw / 14 );
	  }
	  &__grid-title {
	    overflow: hidden;
	    text-overflow: ellipsis;
	    display: -webkit-box;
	    -webkit-box-orient: vertical;
	    -webkit-line-clamp: 2;
	    align-items: center;
		  margin-bottom: calc( 10vw / 14 );
	    a {
	      font-size: calc( 20vw / 14 );
	      line-height: (26/20);
	      font-weight: 700;
	    }
	  }
	  &__grid-content {
	    p{
		    font-size: calc( 16vw / 14 );
		    line-height: (32/16);
	    }
	  }
	  &__content{
		  display: flex;
		  justify-content: flex-start;
		  align-items: flex-start;
		  flex-wrap: wrap;
	  }
	}
	@media #{$large-desktops} {
		padding-top: 3.5rem;
		&__banner-area {
			margin-bottom: 3rem;
		}
		&__banner-background{
			svg{
				width: 44rem;
			}
		}
		&__banner-content{
			svg{
				width: 2.6rem;
				margin-right: 1rem;
			}
		}
		&__banner-title{
			font-size: 5rem;
		}
	  &__grid-area {
		  width: 35rem;
		  margin: 0 3rem 5rem 0;
	  }
	  &__grid-hero-image {
		  margin-bottom: 2rem;
	    img {
	      height: 24rem;
	      border-radius: 1rem;
	    }
	  }
	  &__grid-date-publish {
		  font-size: 1.4rem;
		  margin-bottom: .5rem;
	  }
	  &__grid-title {
	    overflow: hidden;
	    text-overflow: ellipsis;
	    display: -webkit-box;
	    -webkit-box-orient: vertical;
	    -webkit-line-clamp: 2;
	    align-items: center;
		  margin-bottom: 1rem;
	    a {
	      font-size: 2rem;
	    }
	  }
	  &__grid-content {
	    p{
		    font-size: 1.6rem;
	    }
	  }
	}
	
}
